import * as api from '../../../api';
import { getAuthTokens } from '../../../localStorage/authentication';

export const GET_TILE_DATA_REQUEST = 'tileData/GET_TILE_DATA_REQUEST';
export const GET_TILE_DATA_RESPONSE = 'tileData/GET_TILE_DATA_RESPONSE';

const createGetTileDataResponseAction = (tileId, deviceId, values) => ({
  type: GET_TILE_DATA_RESPONSE,
  tileId,
  values,
  deviceId,
});

export const getTileData = (tileId, deviceId, parameterIds) => async dispatch => {
  dispatch({ type: GET_TILE_DATA_REQUEST, tileId });

  // Since the componentWillUnmount method of the TileData is not always get called
  // (specially when user is redirected to the login) we have to check it here to avoid flooding of 401 errors on the jpi-cloud-api.
  if (!getAuthTokens()) return false;

  const { status, data } = await api.getDeviceParameterValue(deviceId, parameterIds);
  if (status === 200) {
    dispatch(
      createGetTileDataResponseAction(
        tileId,
        deviceId,
        data.map(d => ({ ...d, value: d.rawValue === 0 ? 0 : d.rawValue || '--' })), // TODO: Change to use already scaled 'value' instead of 'rawValue'
      ),
    );
  }

  return false;
};

const getTileParametersValues = responseData => {
  // TODO: Change to use already scaled 'value' (if any) instead of 'rawValue'
  return responseData.map(d => ({ ...d, value: d.rawValue === 0 ? 0 : d.rawValue || '--' }));
};

function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

export const updateAllTilesData = () => async (dispatch, getState) => {
  // Since the componentWillUnmount method of the TileData is not always get called
  // (specially when user is redirected to the login) we have to check it here to avoid flooding of 401 errors on the jpi-cloud-api.
  if (!getAuthTokens()) return;

  const tiles = getState().tileManager.tiles;

  if (!tiles || tiles.length === 0) return;
  const deviceId = tiles[0].deviceId;

  const tilesWithParameters = tiles.filter(
    t => t.properties.displayParameters.length > 0 || t.properties.settingParameters.length > 0,
  );

  const parametersIds = tilesWithParameters
    .map(t => [
      ...t.properties.displayParameters.filter(p => !!p).map(p => p.id),
      ...t.properties.settingParameters.filter(p => !!p).map(p => p.id),
    ])
    .reduce((acc, i) => [...acc, ...i], [])
    .filter(onlyUnique);

  const { status, data } = await api.getDeviceParameterValue(deviceId, [...parametersIds]);

  if (status === 200) {
    tilesWithParameters
      .map(tile => createGetTileDataResponseAction(tile.id, deviceId, getTileParametersValues(data)))
      .forEach(dispatch);
  }
};
