import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';

import Dropdown from '../../../../inputs/Dropdown';
import { getUserProfile, changeUserProfile } from '../ProfileTab/actions';
import * as tileApi from '../../../../../api/tiles';

class AppearanceTab extends React.Component {
  state = {
    selectedUnitSystem: this.props.userInfo.unitSystem || '',
  };
  UNIT_CONVERSION = [
    {
      text: this.props.intl.formatMessage({ id: 'unit-conversion.metric', defaultMessage: 'Metric' }),
      value: 'Metric',
    },
    {
      text: this.props.intl.formatMessage({ id: 'unit-conversion.imperial', defaultMessage: 'Imperial' }),
      value: 'Imperial',
    },
    {
      text: this.props.intl.formatMessage({ id: 'unit-conversion.default', defaultMessage: 'Default from device' }),
      value: 'DefaultFromDevice',
    },
  ];

  async componentDidMount() {
    if (this.props.userInfo.id) {
      await this.props.getUserProfile(this.props.userInfo.id);
    }

    const { unitSystem } = this.props.userInfo;

    this.setState({
      selectedUnitSystem: unitSystem,
    });
  }

  onUnitSystemSelected = async item => {
    this.setState({ selectedUnitSystem: item.value });

    const oldUserProfile = this.props.userInfo;

    const newUserProfile = {
      ...oldUserProfile,
      unitSystem: item.value,
    };

    await this.props.changeUserProfile(this.props.userInfo.id, newUserProfile);
    tileApi.resetAllTilesLastRefreshed();
  };

  render() {
    if (!this.state.selectedUnitSystem) {
      return null;
    }

    return (
      <form className="form--half">
        <p className="text">
          <FormattedMessage id="metric.select-settings" defaultMessage="Select settings you want for myUplink" />
        </p>
        <Dropdown
          className="border"
          items={this.UNIT_CONVERSION}
          selectedItem={
            this.state.selectedUnitSystem
              ? this.UNIT_CONVERSION.find(item => item.value === this.state.selectedUnitSystem)
              : ''
          }
          onSelect={this.onUnitSystemSelected}
          type="text"
          id="selectedUnitSystem"
          isReadOnly={true}
          labelGetter={e => e.text}
        />
      </form>
    );
  }
}

AppearanceTab.propTypes = {
  userInfo: PropTypes.object.isRequired,
  changeUserProfile: PropTypes.func.isRequired,
  getUserProfile: PropTypes.func.isRequired,
  intl: intlShape,
};

export default connect(
  ({ app }) => ({
    userInfo: app.userInfo,
  }),
  {
    getUserProfile,
    changeUserProfile,
  },
)(injectIntl(AppearanceTab));
