import axios from 'axios';

export const MY_UPLINK_CLIENT_ID = 'My-Uplink-Web';

export const ENV = process.env.ENV || '';
export const API_URL = process.env.API_URL || '';

export const WEB_URL = process.env.WEB_URL || '';
export const WEB_PRO_URL = process.env.WEB_PRO_URL || '';
export const WEB_DEV_URL = process.env.WEB_DEV_URL || '';

export const CONFIG_JSON = {
  headers: {
    'Content-Type': 'application/json',
  },
};

export const CONFIG_FORM = {
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
    Accept: 'application/json',
  },
};

export const API = axios.create({
  baseURL: API_URL,
  headers: {
    ...CONFIG_JSON.headers,
  },
  responseType: 'json',
});
