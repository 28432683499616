import * as tileActions from '../TileManager/actions';
import * as api from '../../../api/index';

export const TILE_MIGRATION_VERSION = 1;
export const TILE_CREATION_START = 'tileConfiguration/TILE_CREATION_START';
export const startTileCreation = () => ({ type: TILE_CREATION_START });

export const TILE_EDITING_START = 'tileConfiguration/TILE_EDITING_START';
export const startTileEditing = tile => ({ type: TILE_EDITING_START, tile });

export const TILE_CONFIGURATION_SELECT_TYPE = 'tileConfiguration/TILE_CONFIGURATION_SELECT_TYPE';
export const selectTileType = (tileType, deviceId, tileNameText, tileNameTextId) => ({
  type: TILE_CONFIGURATION_SELECT_TYPE,
  tileType,
  deviceId,
  tileNameText,
  tileNameTextId,
});

export const TILE_CONFIGURATION_SELECT_TYPE_SILENT = 'tileConfiguration/TILE_CONFIGURATION_SELECT_TYPE_SILENT';
export const selectTileTypeSilent = (tileType, deviceId, tileNameText, tileNameTextId) => ({
  type: TILE_CONFIGURATION_SELECT_TYPE_SILENT,
  tileType,
  deviceId,
  tileNameText,
  tileNameTextId,
});

export const TILE_CONFIGURATION_SET_TITLE = 'tileConfiguration/TILE_CONFIGURATION_SET_TITLE';
export const setTitle = (title, isTitleEdited) => ({ type: TILE_CONFIGURATION_SET_TITLE, title, isTitleEdited });

export const TILE_CONFIGURATION_SET_DISPLAY_PARAMETER = 'tileConfiguration/TILE_CONFIGURATION_SET_DISPLAY_PARAMETER';
export const setDisplayParameter = (index, value) => ({ type: TILE_CONFIGURATION_SET_DISPLAY_PARAMETER, index, value });

export const TILE_CONFIGURATION_SET_DISPLAY_PARAMETER_SILENT =
  'tileConfiguration/TILE_CONFIGURATION_SET_DISPLAY_PARAMETER_SILENT';
export const setDisplayParameterSilent = (index, value) => ({
  type: TILE_CONFIGURATION_SET_DISPLAY_PARAMETER_SILENT,
  index,
  value,
});

export const TILE_CONFIGURATION_REMOVE_DISPLAY_PARAMETER =
  'tileConfiguration/TILE_CONFIGURATION_REMOVE_DISPLAY_PARAMETER';
export const removeDisplayParameter = index => ({ type: TILE_CONFIGURATION_REMOVE_DISPLAY_PARAMETER, index });

export const TILE_CONFIGURATION_SET_SETTINGS_PARAMETERS =
  'tileConfiguration/TILE_CONFIGURATION_SET_SETTINGS_PARAMETERS';
export const setSettingsParameters = values => ({ type: TILE_CONFIGURATION_SET_SETTINGS_PARAMETERS, values });

export const TILE_CONFIGURATION_SET_SETTINGS_PARAMETERS_SILENT =
  'tileConfiguration/TILE_CONFIGURATION_SET_SETTINGS_PARAMETERS_SILENT';
export const setSettingsParametersSilent = values => ({
  type: TILE_CONFIGURATION_SET_SETTINGS_PARAMETERS_SILENT,
  values,
});

export const TILE_CONFIGURATION_SET_CHART = 'tileConfiguration/TILE_CONFIGURATION_SET_CHART';
export const setChartValue = chart => ({ type: TILE_CONFIGURATION_SET_CHART, chart });

export const TILE_CONFIGURATION_SET_CHART_SILENT = 'tileConfiguration/TILE_CONFIGURATION_SET_CHART_SILENT';
export const setChartValueSilent = chart => ({ type: TILE_CONFIGURATION_SET_CHART_SILENT, chart });

export const TILE_CONFIGURATION_REMOVE_CHART = 'tileConfiguration/TILE_CONFIGURATION_REMOVE_CHART';
export const removeChartValue = () => ({ type: TILE_CONFIGURATION_REMOVE_CHART });

export const TILE_CONFIGURATION_END = 'tileConfiguration/TILE_CONFIGURATION_END';
export const saveTile = () => (dispatch, getState) => {
  const configurationState = getState().tileConfigurationPopup;
  const { isNewTileCreation, editState } = configurationState;
  const getValueFromDropdown = item => item;

  const tile = {
    ...editState,
    __migration_version: TILE_MIGRATION_VERSION,
    properties: {
      ...editState.properties,
      displayParameters: editState.properties.displayParameters,
      settingParameters: editState.properties.settingParameters,
      chart: getValueFromDropdown(editState.properties.chart),
    },
  };

  isNewTileCreation ? dispatch(tileActions.addTile(tile)) : dispatch(tileActions.editTile(tile));
  dispatch({ type: TILE_CONFIGURATION_END, tileId: tile.id });
};

const replaceParams = (tileParams, serverTileParams) => {
  return tileParams.map(param => {
    const paramFromServer = serverTileParams.find(sTileParam => sTileParam.id === param.id);
    return paramFromServer ? paramFromServer : param;
  });
};

export const migrateTileParameters = (tiles, selectedSystemId) => async dispatch => {
  tiles.forEach(async tile => {
    const newTile = { ...tile };

    const response = await api.getTileParameters(selectedSystemId, tile.type);
    if (response.status !== 200) {
      return;
    }

    const tileParameters = response.data;

    newTile.__migration_version = TILE_MIGRATION_VERSION;

    if (tile.properties.displayParameters) {
      newTile.properties = {
        ...newTile.properties,
        displayParameters: replaceParams(tile.properties.displayParameters, tileParameters),
      };
    }

    if (tile.properties.settingParameters) {
      newTile.properties = {
        ...newTile.properties,
        settingParameters: replaceParams(tile.properties.settingParameters, tileParameters),
      };
    }

    if (tile.chart) {
      const chart = tileParameters.find(sTileParam => sTileParam.id === tile.chart.id);
      if (chart) {
        newTile.chart = chart;
      }
    }

    dispatch(tileActions.editTile(newTile));
  });
};

export const saveTileSilent = () => (dispatch, getState) => {
  const configurationState = getState().tileConfigurationPopup;
  const editState = configurationState.editState;
  const tile = {
    __migration_version: TILE_MIGRATION_VERSION,
    ...editState,
    properties: {
      ...editState.properties,
      displayParameters: editState.properties.displayParameters,
      settingParameters: editState.properties.settingParameters,
      chart: editState.properties.chart,
    },
  };
  dispatch(tileActions.addTile(tile));
};

export const discardEditing = () => ({ type: TILE_CONFIGURATION_END });

export const GET_TILE_TYPES_REQUEST = 'tileConfiguration/GET_TILE_TYPES_REQUEST';
export const GET_TILE_TYPES_SUCCEEDED = 'tileConfiguration/GET_TILE_TYPES_SUCCEEDED';
export const GET_TILE_TYPES_SUCCEEDED_SILENT = 'tileConfiguration/GET_TILE_TYPES_SUCCEEDED_SILENT';
export const GET_TILE_TYPES_FAILED = 'tileConfiguration/GET_TILE_TYPES_FAILED';
export const getTileTypes = systemId => async dispatch => {
  dispatch({ type: GET_TILE_TYPES_REQUEST });
  const response = await api.getGroupTileTypes(systemId);

  if (response.status === 200) {
    dispatch({ type: GET_TILE_TYPES_SUCCEEDED, tileTypes: response.data });
    return true;
  }
  dispatch({ type: GET_TILE_TYPES_FAILED, data: [] });

  return false;
};
export const getTileTypesSilent = systemId => async dispatch => {
  dispatch({ type: GET_TILE_TYPES_REQUEST });
  const response = await api.getGroupTileTypes(systemId);

  if (response.status === 200) {
    dispatch({ type: GET_TILE_TYPES_SUCCEEDED_SILENT, tileTypes: response.data });
    return true;
  }
  dispatch({ type: GET_TILE_TYPES_FAILED, data: [] });

  return false;
};

export const GET_TILE_PARAMETERS_RESPONSE = 'tileConfiguration/GET_TILE_PARAMETERS_RESPONSE';
export const getTileParameters = (groupId, tileType, tileNameTextId) => async dispatch => {
  const response = await api.getTileParameters(groupId, tileType, tileNameTextId);
  if (response.status === 200) {
    dispatch({ type: GET_TILE_PARAMETERS_RESPONSE, tileParameters: response.data });
    return true;
  }

  return false;
};
