import {
  SET_SHOP_ID,
  GET_PRODUCTS,
  GET_PRODUCTS_SUCCEEDED,
  GET_PRODUCTS_FAILED,
  GET_ECOM_SUPPORTED_COUNTRIES_SUCCEEDED,
  GET_ECOM_SUPPORTED_COUNTRIES_FAILED,
  INIT_CART,
  INIT_CART_SUCCEEDED,
  INIT_CART_FAILED,
  GET_PAYMENT_PROVIDERS,
  GET_PAYMENT_PROVIDERS_SUCCEEDED,
  GET_PAYMENT_PROVIDERS_FAILED,
  GET_ORDER_PAYMENT_PROVIDERS,
  GET_ORDER_PAYMENT_PROVIDERS_SUCCEEDED,
  GET_ORDER_PAYMENT_PROVIDERS_FAILED,
  SELECT_PAYMENT_PROVIDER,
  GET_CART,
  GET_CART_SUCCEEDED,
  GET_CART_FAILED,
  ADD_TO_CART,
  ADD_TO_CART_SUCCEEDED,
  ADD_TO_CART_FAILED,
  REMOVE_FROM_CART,
  REMOVE_FROM_CART_SUCCEEDED,
  REMOVE_FROM_CART_FAILED,
  UPDATE_PRODUCT_IN_CART,
  UPDATE_PRODUCT_IN_CART_SUCCEEDED,
  UPDATE_PRODUCT_IN_CART_FAILED,
  ADD_ADDRESS_TO_CART,
  ADD_ADDRESS_TO_CART_SUCCEEDED,
  ADD_ADDRESS_TO_CART_FAILED,
  ADD_DISCOUNT,
  ADD_DISCOUNT_SUCCEEDED,
  ADD_DISCOUNT_FAILED,
  REMOVE_DISCOUNT,
  REMOVE_DISCOUNT_SUCCEEDED,
  REMOVE_DISCOUNT_FAILED,
  CREATE_ORDER,
  CREATE_ORDER_SUCCEEDED,
  CREATE_ORDER_FAILED,
  GET_ORDER,
  GET_ORDER_SUCCEEDED,
  GET_ORDER_FAILED,
  CANCEL_ORDER,
  CANCEL_ORDER_SUCCEEDED,
  CANCEL_ORDER_FAILED,
  ABORT_CREATE_ORDER,
  CLOSE_CREATE_ORDER,
  GET_ORDERS,
  GET_ORDERS_SUCCEEDED,
  GET_ORDERS_FAILED,
  GET_SUBSCRIPTIONS,
  GET_SUBSCRIPTIONS_SUCCEEDED,
  GET_SUBSCRIPTIONS_FAILED,
  GET_USERS_ECOM_SUBSCRIPTIONS_SUCCEEDED,
  GET_USERS_ECOM_SUBSCRIPTIONS,
  GET_USERS_ECOM_SUBSCRIPTIONS_FAILED,
  GET_ORDER_STATUS_SUCCEEDED,
  GET_USER_PAYMENT_PROVIDER_SETTINGS,
  GET_USER_PAYMENT_PROVIDER_SETTINGS_SUCCEEDED,
  GET_USER_PAYMENT_PROVIDER_SETTINGS_FAILED,
  UPDATE_PAYMENT_PROVIDER_SETTINGS,
  UPDATE_PAYMENT_PROVIDER_SETTINGS_SUCCEEDED,
  UPDATE_PAYMENT_PROVIDER_SETTINGS_FAILED,
  CANCEL_SUBSCRIPTION,
  CANCEL_SUBSCRIPTION_SUCCEEDED,
  CANCEL_SUBSCRIPTION_FAILED,
  RESUME_SUBSCRIPTION,
  RESUME_SUBSCRIPTION_SUCCEEDED,
  RESUME_SUBSCRIPTION_FAILED,
  PAY_PENDING_ORDER,
  PAY_PENDING_ORDER_SUCCEEDED,
  PAY_PENDING_ORDER_FAILED,
  GET_PAID_BRANDS,
  GET_PAID_BRANDS_SUCCEEDED,
  GET_PAID_BRANDS_FAILED,
} from './actions';

import { LOGOUT_USER } from '../pages/Login/actions';
import { cloneDeep } from 'lodash';

const replaceItemWithIdInList = (list, itemWithId) => {
  if (!list) {
    list = [];
  }

  const containId = list.filter(item => item.id === itemWithId.id).length > 0;
  if (containId) {
    return list.map(obj => {
      return obj.id === itemWithId.id ? itemWithId : obj;
    });
  }
  list.push(itemWithId);
  return list;
};

const updateProductWithSubscription = (userSubs, changedSubs) => {
  return userSubs.map(sub => {
    if (sub.subscription && sub.subscription.id === changedSubs.id) {
      sub.subscription.status = changedSubs.status;
      sub.subscription.validUntil = changedSubs.validUntil;
    }
    return sub;
  });
};

const initialState = {
  shopId: null,
  products: {
    items: [],
    loaded: false,
    loading: false,
    error: null,
  },
  cart: {
    id: null,
    lineItems: [],
    address: null,
    discount: null,
    error: null,
    init: false,
    loading: false,
  },
  order: {
    error: null,
    loading: false,
    data: null,
  },
  subscriptions: {
    init: false,
    loading: false,
    error: null,
    subscriptions: [],
  },
  orders: {
    init: false,
    loading: false,
    error: null,
    orders: [],
  },
  pendingOrder: {
    loading: false,
    error: null,
  },
  paymentProviders: {
    loading: false,
    error: null,
    paymentProviders: [],
  },
  paymentProviderSettings: {
    loading: false,
    error: null,
    paymentProviderSettings: [],
  },
  selectedPaymentProvider: null,
  selectedPaymentProviderData: {},
  supportedCountries: {
    countries: [],
    loaded: false,
    error: null,
  },
  cardURLDetails: {
    error: null,
    loading: false,
    data: {},
  },
  paymentDetails: {
    error: null,
    loading: false,
    data: {},
  },
  userSubscriptions: {
    init: false,
    loading: false,
    error: null,
    subscriptions: [],
  },
  paidBrands: {
    error: null,
    loading: false,
    stripeSpecificPaidBrands: [],
    allPaidBrands: [],
  },
};

const removeObjectWithId = (id, array) => {
  return array.filter(item => item.id !== id);
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOGOUT_USER:
      return { ...initialState, supportedCountries: state.supportedCountries };
    case SET_SHOP_ID:
      return { ...state, shopId: action.shopId };
    case GET_PRODUCTS:
      return { ...state, products: { ...state.products, error: null, loading: true } };
    case GET_PRODUCTS_SUCCEEDED:
      return { ...state, products: { ...state.products, items: action.products, loaded: true, loading: false } };
    case GET_PRODUCTS_FAILED:
      return { ...state, products: { ...state.products, error: action.error, loaded: false, loading: false } };
    case GET_ECOM_SUPPORTED_COUNTRIES_SUCCEEDED:
      return {
        ...state,
        supportedCountries: { ...state.supportedCountries, countries: action.supportedCountries, loaded: true },
      };
    case GET_ECOM_SUPPORTED_COUNTRIES_FAILED:
      return {
        ...state,
        supportedCountries: { ...state.supportedCountries, countries: [], error: action.error, loaded: false },
      };
    case INIT_CART:
      return { ...state, cart: { ...state.cart, loading: true } };
    case INIT_CART_SUCCEEDED:
      return {
        ...state,
        cart: { ...state.cart, ...action.cart, error: null, init: true, loading: false },
      };
    case INIT_CART_FAILED:
      return {
        ...state,
        cart: { ...state.cart, error: action.error, init: true, loading: false },
        products: { items: [], loaded: false, loading: false },
      };
    case GET_CART:
      return { ...state, cart: { ...state.cart, loading: true } };
    case GET_CART_SUCCEEDED:
      return { ...state, cart: { ...state.cart, ...action.cart, loading: false } };
    case GET_CART_FAILED:
      return { ...state, cart: { ...state.cart, error: action.error, loading: false } };
    case GET_PAYMENT_PROVIDERS:
      return {
        ...state,
        paymentProviders: [],
        cardURLDetails: { data: [], loading: true, error: null },
      };
    case GET_PAYMENT_PROVIDERS_SUCCEEDED:
      return {
        ...state,
        paymentProviders: action.paymentProviders,
        selectedPaymentProvider: action.paymentProviders.length > 0 ? action.paymentProviders[0] : null,
        cardURLDetails: { data: action.paymentProviders[0].paymentParams.data, loading: false },
      };
    case GET_PAYMENT_PROVIDERS_FAILED:
      return {
        ...state,
        paymentProviders: [],
        cardURLDetails: { data: [], error: action.error, loading: false },
      };
    case GET_ORDER_PAYMENT_PROVIDERS:
      return {
        ...state,
        paymentProviders: [],
        paymentDetails: { data: [], loading: true, error: null },
      };
    case GET_ORDER_PAYMENT_PROVIDERS_SUCCEEDED:
      return {
        ...state,
        paymentProviders: action.paymentProviders,
        selectedPaymentProvider: action.paymentProviders.length > 0 ? action.paymentProviders[0] : null,
        paymentDetails: { data: action.paymentProviders[0].paymentParams.data },
      };
    case GET_ORDER_PAYMENT_PROVIDERS_FAILED:
      return {
        ...state,
        paymentProviders: [],
        paymentDetails: { data: [], error: action.error, loading: false },
      };
    case SELECT_PAYMENT_PROVIDER:
      return { ...state, selectedPaymentProvider: action.paymentProvider };
    case GET_USER_PAYMENT_PROVIDER_SETTINGS:
      return { ...state, paymentProviders: { loading: true }, paymentProviderSettings: { loading: true } };
    case GET_USER_PAYMENT_PROVIDER_SETTINGS_SUCCEEDED:
      return {
        ...state,
        paymentProviders: { paymentProviders: action.paymentProviders, loading: false },
        paymentProviderSettings: {
          paymentProviderSettings: action.paymentProviderSettings,
          loading: false,
        },
        selectedPaymentProvider: action.paymentProviders.length > 0 ? action.paymentProviders[0] : null,
      };
    case GET_USER_PAYMENT_PROVIDER_SETTINGS_FAILED:
      return {
        ...state,
        paymentProviders: { paymentProviders: [], loading: false, error: action.error },
        paymentProviderSettings: { paymentProviderSettings: [], loading: false, error: action.error },
      };
    case UPDATE_PAYMENT_PROVIDER_SETTINGS:
      return { ...state, paymentProviderSettings: { loading: true } };
    case UPDATE_PAYMENT_PROVIDER_SETTINGS_SUCCEEDED:
      return {
        ...state,
        paymentProviderSettings: {
          paymentProviderSettings: replaceItemWithIdInList(
            state.paymentProviderSettings.paymentProviderSettings,
            action.paymentProviderSettings,
          ),
          loading: false,
        },
        selectedPaymentProvider: { ...state.selectedPaymentProvider, hasValidSettings: false },
      };
    case UPDATE_PAYMENT_PROVIDER_SETTINGS_FAILED:
      return {
        ...state,
        paymentProviderSettings: { paymentProviderSettings: [], loading: false, error: action.error },
      };
    case ADD_TO_CART:
      return { ...state, cart: { ...state.cart, loading: true } };
    case ADD_TO_CART_SUCCEEDED:
      return { ...state, cart: { ...state.cart, ...action.cart, loading: false } };
    case ADD_TO_CART_FAILED:
      return { ...state, cart: { ...state.cart, error: action.error, loading: false } };
    case REMOVE_FROM_CART:
      return { ...state, cart: { ...state.cart, loading: true } };
    case REMOVE_FROM_CART_SUCCEEDED:
      return { ...state, cart: { ...state.cart, ...action.cart, loading: false } };
    case REMOVE_FROM_CART_FAILED:
      return { ...state, cart: { ...state.cart, error: action.error, loading: false } };
    case UPDATE_PRODUCT_IN_CART:
      return { ...state, cart: { ...state.cart, loading: true } };
    case UPDATE_PRODUCT_IN_CART_SUCCEEDED:
      return { ...state, cart: { ...state.cart, ...action.cart, loading: false } };
    case UPDATE_PRODUCT_IN_CART_FAILED:
      return { ...state, cart: { ...state.cart, error: action.error, loading: false } };
    case ADD_ADDRESS_TO_CART:
      return { ...state, cart: { ...state.cart, loading: true } };
    case ADD_ADDRESS_TO_CART_SUCCEEDED:
      return { ...state, cart: { ...state.cart, ...action.cart, loading: false } };
    case ADD_ADDRESS_TO_CART_FAILED:
      return { ...state, cart: { ...state.cart, error: action.error, loading: false } };
    case ADD_DISCOUNT:
      return { ...state, cart: { ...state.cart, loading: true } };
    case ADD_DISCOUNT_SUCCEEDED:
      return { ...state, cart: { ...state.cart, ...action.cart, loading: false } };
    case ADD_DISCOUNT_FAILED:
      return { ...state, cart: { ...state.cart, error: action.error, loading: false } };
    case REMOVE_DISCOUNT:
      return { ...state, cart: { ...state.cart, loading: true } };
    case REMOVE_DISCOUNT_SUCCEEDED:
      return { ...state, cart: { ...state.cart, ...action.cart, loading: false } };
    case REMOVE_DISCOUNT_FAILED:
      return { ...state, cart: { ...state.cart, error: action.error, loading: false } };
    case CREATE_ORDER:
      return {
        ...state,
        order: { ...state.order, error: null, loading: true },
        paymentDetails: { data: [], loading: true, error: null },
      };
    case CREATE_ORDER_SUCCEEDED:
      return {
        ...state,
        order: { data: { ...state.order.data, ...action.order }, loading: false },
        paymentDetails: { data: action.order.paymentProvider.data.data },
      };
    case CREATE_ORDER_FAILED:
      return {
        ...state,
        order: { ...state.order, error: action.error, loading: false },
        paymentDetails: { data: [], error: action.error, loading: false },
      };
    case PAY_PENDING_ORDER:
      return {
        ...state,
        pendingOrder: { ...state.pendingOrder, error: null, loading: true },
      };
    case PAY_PENDING_ORDER_SUCCEEDED:
      return {
        ...state,
        pendingOrder: { ...state.pendingOrder, loading: false },
      };
    case PAY_PENDING_ORDER_FAILED:
      return {
        ...state,
        pendingOrder: { ...state.pendingOrder, error: action.error, loading: false },
      };
    case GET_ORDER:
      return { ...state, order: { ...state.order, error: null, loading: true } };
    case GET_ORDER_SUCCEEDED:
      return {
        ...state,
        cart: initialState.cart,
        order: { data: { ...state.order.data, ...action.order }, loading: false },
      };
    case GET_ORDER_STATUS_SUCCEEDED:
      return { ...state, order: { data: { ...state.order.data, ...action.order }, loading: false } };
    case GET_ORDER_FAILED:
      return { ...state, order: { ...state.order, error: action.error, loading: false } };
    case CANCEL_ORDER:
      return { ...state, order: { ...state.order, error: null, loading: true } };
    case CANCEL_ORDER_SUCCEEDED:
      return {
        ...state,
        cart: initialState.cart,
        order: { data: null, loading: false },
        orders: { orders: removeObjectWithId(action.order.id, state.orders.orders) },
      };
    case CANCEL_ORDER_FAILED:
      return { ...state, order: { ...state.order, error: action.error, loading: false } };
    case ABORT_CREATE_ORDER:
      return { ...state, order: { ...state.order, error: null, loading: false } };
    case CLOSE_CREATE_ORDER:
      return { ...state, order: { loading: false, data: null } };
    case GET_SUBSCRIPTIONS:
      return { ...state, subscriptions: { ...state.subscriptions, error: null, loading: true } };
    case GET_SUBSCRIPTIONS_SUCCEEDED:
      return { ...state, subscriptions: { subscriptions: action.subscriptions, init: true, loading: false } };
    case GET_SUBSCRIPTIONS_FAILED:
      return { ...state, subscriptions: { subscriptions: [], error: action.error, init: true, loading: false } };
    case CANCEL_SUBSCRIPTION:
      return { ...state, userSubscriptions: { ...state.userSubscriptions, error: null, loading: true } };
    case CANCEL_SUBSCRIPTION_SUCCEEDED:
      return {
        ...state,
        userSubscriptions: {
          subscriptions: updateProductWithSubscription(
            cloneDeep(state.userSubscriptions.subscriptions),
            action.subscription.deviceSubscription,
          ),
          init: true,
          loading: false,
        },
      };
    case CANCEL_SUBSCRIPTION_FAILED:
      return { ...state, userSubscriptions: { subscriptions: [], error: action.error, init: true, loading: false } };
    case RESUME_SUBSCRIPTION:
      return { ...state, subscriptions: { ...state.userSubscriptions, error: null, loading: true } };
    case RESUME_SUBSCRIPTION_SUCCEEDED:
      return {
        ...state,
        userSubscriptions: {
          subscriptions: updateProductWithSubscription(
            cloneDeep(state.userSubscriptions.subscriptions),
            action.subscription.deviceSubscription,
          ),
          error: null,
          init: true,
          loading: false,
        },
      };
    case RESUME_SUBSCRIPTION_FAILED:
      return { ...state, userSubscriptions: { subscriptions: [], error: action.error, init: true, loading: false } };
    case GET_USERS_ECOM_SUBSCRIPTIONS:
      return { ...state, userSubscriptions: { ...state.subscriptions, error: null, loading: true } };
    case GET_USERS_ECOM_SUBSCRIPTIONS_SUCCEEDED:
      return { ...state, userSubscriptions: { subscriptions: action.subscriptions, init: true, loading: false } };
    case GET_USERS_ECOM_SUBSCRIPTIONS_FAILED:
      return { ...state, userSubscriptions: { subscriptions: [], error: action.error, init: true, loading: false } };
    case GET_ORDERS:
      return { ...state, orders: { loading: true } };
    case GET_ORDERS_SUCCEEDED:
      return { ...state, orders: { orders: action.orders, init: true, loading: false } };
    case GET_ORDERS_FAILED:
      return { ...state, orders: { orders: [], error: action.error, init: true, loading: false } };
    case GET_PAID_BRANDS:
      return {
        ...state,
        paidBrands: { error: null, loading: true },
      };
    case GET_PAID_BRANDS_SUCCEEDED:
      return {
        ...state,
        paidBrands: {
          stripeSpecificPaidBrands: action.stripeSpecificBrands,
          allPaidBrands: action.allPaidBrands,
          loading: false,
          error: null,
        },
      };
    case GET_PAID_BRANDS_FAILED:
      return {
        ...state,
        paidBrands: { stripeSpecificPaidBrands: [], allPaidBrands: [], error: action.error, loading: false },
      };
    default:
      return state;
  }
};
