import React from 'react';
import PropTypes from 'prop-types';

import AccountMigrationInfoHeader from './components/AccountMigrationInfoHeader';
import AccountMigrationInfoDescription from './components/AccountMigrationInfoDescription';

import './account-migration-info-modal.scss';
import { getOldUserTypeObject } from '../../../ProfilePage/components/AccountMigration/utils';

const AccountMigrationInfoModal = ({ onClose, userSite }) => {
  const translationObject = getOldUserTypeObject(userSite);
  return (
    <div className="account-migration-info-modal-wrapper">
      <button onClick={onClose} className="account-migration-info-modal-close-button">
        <i className="fas fa-times" />
      </button>
      <AccountMigrationInfoHeader translationObject={translationObject} />
      <AccountMigrationInfoDescription translationObject={translationObject} />
    </div>
  );
};

AccountMigrationInfoModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  userSite: PropTypes.string.isRequired,
};

export default AccountMigrationInfoModal;
