import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import { Button, Spinner } from 'jpi-cloud-web-ui-components';
import { Wizard, WizardSteps } from '../../../../layout/Wizard';
import Modal from '../../../../layout/Modal';

import { WeekFormats } from '../../weekFormats';
import { setWeeklySchedules } from '../../actions';

import '../../device-scheduling.scss';

const WeekFormatSelector = ({ closePopup, selectedFormat, selectWeekFormat, goNext }) => (
  <Fragment>
    <div className="popup-body">
      <p className="text">
        <FormattedMessage
          id="scheduling.change-week-format.description"
          defaultMessage="How do you want to week to look?"
        />
      </p>

      <div className="popup-main">
        {WeekFormats.map(wf => (
          <div
            key={`wf#${wf.id}`}
            onClick={() => selectWeekFormat(wf)}
            className={classNames({
              'day-selector-item': true,
              'day-selector-item--selected': selectedFormat && wf.id === selectedFormat.id,
            })}
          >
            <FormattedMessage id={`scheduling.${wf.id}`} defaultMessage={wf.id} />
          </div>
        ))}
      </div>
    </div>

    <div className="popup-bottom-panel">
      <Button className="button--secondary" type="submit" onClick={goNext} disabled={!selectedFormat}>
        <FormattedMessage id="scheduling.change-format-btn" defaultMessage="Change format" />
      </Button>
      <Button onClick={closePopup} className="button--default" type="button">
        <FormattedMessage id="scheduling.title.cancel" defaultValue="Cancel" />
      </Button>
    </div>
  </Fragment>
);

WeekFormatSelector.propTypes = {
  closePopup: PropTypes.func.isRequired,
  selectedFormat: PropTypes.object,
  selectWeekFormat: PropTypes.func.isRequired,
  goNext: PropTypes.func.isRequired,
};

const WeekFormatConfirmation = ({ goPrevious, changeWeekFormat }) => (
  <Fragment>
    <div className="popup-body">
      <p className="text">
        <FormattedMessage
          id="scheduling.change-week-format.warning"
          defaultMessage="You're current schedule will be erased if you change week format."
        />
      </p>
      &nbsp; &nbsp; &nbsp;
      <p className="text">
        <FormattedMessage
          id="scheduling.change-week-format.warning-confirmation"
          defaultMessage="Are you sure you want to do this?"
        />
      </p>
    </div>

    <div className="popup-bottom-panel">
      <Button className="button--secondary" type="submit" onClick={changeWeekFormat}>
        <FormattedMessage id="scheduling.change-format-btn" defaultMessage="Change format" />
      </Button>
      <Button onClick={goPrevious} className="button--default" type="button">
        <FormattedMessage id="scheduling.title.cancel" defaultValue="Cancel" />
      </Button>
    </div>
  </Fragment>
);

WeekFormatConfirmation.propTypes = {
  changeWeekFormat: PropTypes.func.isRequired,
  goPrevious: PropTypes.func.isRequired,
};

class ChangeWeekFormat extends React.Component {
  static propTypes = {
    schedule: PropTypes.object.isRequired,
    devices: PropTypes.array.isRequired,
    weeklySchedules: PropTypes.array,

    setWeeklySchedules: PropTypes.func.isRequired,

    isPopupVisible: PropTypes.bool.isRequired,
    closePopup: PropTypes.func.isRequired,
  };

  state = {
    loading: false,
    selectedFormat: null,
  };

  selectWeekFormat = wf => {
    this.setState({ selectedFormat: wf });
  };

  changeWeekFormat = async () => {
    const { weeklySchedules, schedule, devices, setWeeklySchedules, closePopup } = this.props;
    const { selectedFormat } = this.state;

    const newWeeklySchedules = weeklySchedules.map(ws => {
      if (ws.weeklyScheduleId === schedule.weeklyScheduleId) {
        return {
          ...ws,
          weekFormat: selectedFormat.id,
          events: [],
        };
      }
      return ws;
    });

    this.setState({ loading: true });
    await setWeeklySchedules(devices[0].id, newWeeklySchedules);
    this.setState({ loading: false });

    closePopup();
  };

  render() {
    const { isPopupVisible, closePopup } = this.props;
    const { loading, selectedFormat } = this.state;

    return (
      <Modal show={isPopupVisible} backdrop="static">
        <div className="mode-popup">
          <h1 className="titleComponent">
            <FormattedMessage id="scheduling.change-week-format-btn" defaultMessage="Change week format" />
          </h1>

          {loading && <Spinner dark />}
          {!loading && (
            <Wizard stepsCount={2}>
              {({ currentStep, goNext }) => (
                <WizardSteps currentStep={currentStep}>
                  <WeekFormatSelector
                    closePopup={closePopup}
                    selectedFormat={selectedFormat}
                    goNext={goNext}
                    selectWeekFormat={this.selectWeekFormat}
                  />
                  <WeekFormatConfirmation goPrevious={closePopup} changeWeekFormat={this.changeWeekFormat} />
                </WizardSteps>
              )}
            </Wizard>
          )}
        </div>
      </Modal>
    );
  }
}

export default connect(
  ({ devices: { devices }, deviceScheduling: { weeklySchedules } }) => ({
    devices,
    weeklySchedules,
  }),
  {
    setWeeklySchedules,
  },
)(ChangeWeekFormat);
