import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import PremiumFeatureTooltip from '../../../../layout/PremiumFeatureTooltip';

import {
  getCurrentDateFormat,
  checkTodayDate,
  checkTwoYearsBackDate,
  DateRange,
  setCustomDateRange,
  setDateRangeForDifferentTimePeriod,
  nonPremiumNumberOfDays,
} from '../../chart-utils';
import MultiRangeCalendar from '../multi-range-calendar';
import moment from 'moment';

import { events, groups } from '../../../../../services/clarity/events';
import { setupTracker } from '../../../../../services/clarity';

// TODO: Move to separate file
const DateButton = ({ title, onClick, isSelected, disabled, isLoading, userIsViewer }) => {
  const isDisabled = disabled || isLoading;
  return (
    <div
      disabled={isDisabled}
      className={`date-button${isSelected ? ' --selected' : ''} ${isDisabled ? '--disabled' : ''}`}
      onClick={isDisabled ? null : onClick}
    >
      <FormattedMessage id={`history.range.${title}`} />
      {!isLoading && <PremiumFeatureTooltip premiumFeatureType={userIsViewer ? 'permissionAccess' : 'history'} />}
    </div>
  );
};

DateButton.propTypes = {
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  isSelected: PropTypes.bool,
  isDemo: PropTypes.bool,
  userIsViewer: PropTypes.bool,
};

const trackEventsMap = {
  [DateRange.Day]: events.CHART_PERIOD_DAY,
  [DateRange.Week]: events.CHART_PERIOD_WEEK,
  [DateRange.Month]: events.CHART_PERIOD_MONTH,
  [DateRange.Year]: events.CHART_PERIOD_YEAR,
};

const trackEvent = setupTracker(groups.HISTORY);

// TODO: Add test
const DateRangeComponent = ({
  range = DateRange.Day,
  setDateRange,
  updateChart,
  premiumFeatures,
  isDemo,
  userIsViewer,
  dateFrom,
  dateTo,
  aggregationMethod,
  numberOfChart,
  disabled,
  isLoading,
  selectedLanguage,
}) => {
  const [showCalendar, setShowCalendar] = useState(false);
  const [shouldFetchChartDataWithDebounce, setShouldFetchChartDataWithDebounce] = useState(false);

  useEffect(() => {
    let interval = null;

    if (shouldFetchChartDataWithDebounce) {
      interval = setTimeout(() => {
        updateChart({ dateFrom, dateTo, numberOfChart, aggregationMethod });
        setShouldFetchChartDataWithDebounce(false);
      }, 500);
    }

    return () => {
      if (interval) {
        clearTimeout(interval);
      }
    };
  }, [shouldFetchChartDataWithDebounce, range, aggregationMethod, numberOfChart, dateFrom, dateTo]);

  const updateDateRangeWithDebounce = ({ dateRange = range, minDate = dateFrom, maxDate = dateTo }) => {
    setDateRange({ dateRange, dateFrom: minDate, dateTo: maxDate, numberOfChart });
    setShouldFetchChartDataWithDebounce(true);
  };

  const toggleCalendarDisplay = () => {
    setShowCalendar(prevShowCalendar => !prevShowCalendar);
  };

  const handlePeriod = dateRange => () => {
    if (dateRange === range) return;

    const { dateTo: maxDate, dateFrom: minDate } = setDateRangeForDifferentTimePeriod(dateRange);

    updateDateRangeWithDebounce({ dateRange, minDate, maxDate });

    trackEvent(trackEventsMap[dateRange]);
  };

  const handleCustomPeriod = () => {
    if (range === DateRange.Custom) return;

    const periodType = premiumFeatures?.history ? DateRange.Custom : 'nonPremiumUserCustom';
    setDateRangeForDifferentTimePeriod(periodType);

    updateDateRangeWithDebounce({ dateRange: DateRange.Custom, minDate: dateFrom, maxDate: dateTo });

    trackEvent(events.CHART_PERIOD_CUSTOM);
    setShowCalendar(true);
  };

  const calcaulatedMinDate = new Date(
    !premiumFeatures.history ? moment().subtract(nonPremiumNumberOfDays, 'days') : moment().subtract(2, 'years'),
  );

  const isPreviousDateRangeButtonDisabled =
    checkTwoYearsBackDate(dateFrom) || (range === DateRange.Month && !premiumFeatures.history) || isLoading;

  const isNextDateRangeButtonDisabled =
    checkTodayDate(dateTo) || (range === DateRange.Month && !premiumFeatures.history) || isLoading;

  return (
    <div className="date-range-container">
      <div className="chart-control-group date-range">
        <DateButton
          title="day"
          isLoading={isLoading}
          className="chart-control-group__button"
          onClick={handlePeriod(DateRange.Day)}
          userIsViewer={userIsViewer}
          isSelected={range === DateRange.Day}
        />
        <DateButton
          title="week"
          isLoading={isLoading}
          className="chart-control-group__button"
          onClick={handlePeriod(DateRange.Week)}
          userIsViewer={userIsViewer}
          isSelected={range === DateRange.Week}
        />
        <DateButton
          title="month"
          isLoading={isLoading}
          className="chart-control-group__button"
          onClick={handlePeriod(DateRange.Month)}
          userIsViewer={userIsViewer}
          isSelected={range === DateRange.Month}
        />
        <DateButton
          disabled={!isDemo && disabled}
          isLoading={isLoading}
          title="year"
          className="chart-control-group__button"
          onClick={handlePeriod(DateRange.Year)}
          userIsViewer={userIsViewer}
          isSelected={range === DateRange.Year}
        />
        <DateButton
          title="custom"
          isLoading={isLoading}
          className="chart-control-group__button"
          onClick={handleCustomPeriod}
          userIsViewer={userIsViewer}
          isSelected={range === DateRange.Custom}
        />
      </div>
      <div className="quick-step-container">
        {range !== DateRange.Custom && (
          <>
            <div>
              <span className="quick-step-container__span">
                <p>
                  <FormattedMessage id={`history.range.${range?.toLowerCase()}`} />
                </p>
                {range === DateRange.Month ? (
                  <p>
                    <FormattedMessage id={`history.month.${getCurrentDateFormat(DateRange, range, dateFrom)}`} />{' '}
                    <FormattedMessage id={`${getCurrentDateFormat(DateRange, 'Year', dateFrom)}`} />
                  </p>
                ) : (
                  getCurrentDateFormat(DateRange, range, dateFrom)
                )}
              </span>
            </div>
            <div>
              <button
                aria-label="Previous date range"
                className="quick-step-container__button"
                disabled={isPreviousDateRangeButtonDisabled}
                onClick={() => {
                  const { minDate, maxDate } = setCustomDateRange(range, dateFrom, dateTo, true);
                  updateDateRangeWithDebounce({ minDate, maxDate });
                }}
              >
                <i className="fas fa-chevron-left"></i>
              </button>
              <button
                aria-label="Next date range"
                className="quick-step-container__button"
                disabled={isNextDateRangeButtonDisabled}
                onClick={() => {
                  const { minDate, maxDate } = setCustomDateRange(range, dateFrom, dateTo, false);
                  updateDateRangeWithDebounce({ minDate, maxDate });
                }}
              >
                <i className="fas fa-chevron-right"></i>
              </button>
            </div>
          </>
        )}
        {range === DateRange.Custom && (
          <>
            {showCalendar && (
              <MultiRangeCalendar
                min={calcaulatedMinDate}
                dateTo={dateTo ? new Date(dateTo) : new Date()}
                dateFrom={dateFrom && dateTo ? new Date(dateFrom) : calcaulatedMinDate}
                setShowCalendar={setShowCalendar}
                setDateOnGraph={(customDateFrom, customDateTo) => {
                  updateDateRangeWithDebounce({ minDate: customDateFrom, maxDate: customDateTo });
                }}
                selectedLanguage={selectedLanguage}
              />
            )}
            <p>
              <FormattedMessage id={`${getCurrentDateFormat(DateRange, range, dateFrom)}`} />
              --
              <FormattedMessage id={`${getCurrentDateFormat(DateRange, range, dateTo)}`} />
            </p>
            <div>
              <button className="quick-step-container__button" onClick={toggleCalendarDisplay}>
                <i className={`fas fa-chevron-${showCalendar ? 'up' : 'down'}`}></i>
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

DateRangeComponent.propTypes = {
  range: PropTypes.string.isRequired,
  premiumFeatures: PropTypes.object.isRequired,
  setDateRange: PropTypes.func.isRequired,
  updateChart: PropTypes.func.isRequired,
  dateFrom: PropTypes.instanceOf(Date).isRequired,
  dateTo: PropTypes.instanceOf(Date).isRequired,
  isDemo: PropTypes.bool,
  userIsViewer: PropTypes.bool,
  aggregationMethod: PropTypes.string,
  numberOfChart: PropTypes.string,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  selectedLanguage: PropTypes.string,
};

// TODO: Refactor and useMemo hook
export default React.memo(DateRangeComponent, (prev, next) => {
  const isDateFromUpdated = prev.dateFrom.valueOf() !== next.dateFrom.valueOf();
  const isDateToUpdated = prev.dateTo?.valueOf() !== next.dateTo?.valueOf();
  const isRangeUpdated = prev.range !== next.range;
  const isDemoUpdated = prev.isDemo !== next.isDemo;
  const isAggregationMethodUpdated = prev.aggregationMethod !== next.aggregationMethod;
  const isNumberOfChartUpdated = prev.numberOfChart !== next.numberOfChart;
  const isPremiumFeatureUpdated = prev.premiumFeatures.history !== next.premiumFeatures.history;
  const isDisabledUpdated = prev.disabled !== next.disabled;
  const isLoadingUpdated = prev.isLoading !== next.isLoading;
  return !(
    isDateFromUpdated ||
    isDateToUpdated ||
    isRangeUpdated ||
    isDemoUpdated ||
    isAggregationMethodUpdated ||
    isNumberOfChartUpdated ||
    isPremiumFeatureUpdated ||
    isDisabledUpdated ||
    isLoadingUpdated
  );
});
