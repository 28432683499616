import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Button } from 'jpi-cloud-web-ui-components';
import TimePicker from '../TimePicker';
import moment from 'moment';

import { dateFormatForVaction } from '../../../../constants/constants';

const VacationTimeSelector = ({ selectedTimeRange, setTime, goNext, goPrevious, isAmPmHours, selectedDateRange }) => {
  const startDate = moment(selectedDateRange?.startDate).format(dateFormatForVaction);
  const endDate = moment(selectedDateRange?.endDate).format(dateFormatForVaction);

  return (
    <Fragment>
      <div className="popup-body">
        <div className="popup-header">
          <p className="text vacationDate">
            <FormattedMessage id="new-vacation.step2.vacationDate" defaultMessage="Your vacation is set for" />
            &nbsp;
            <b>
              {startDate} - {endDate}
            </b>
          </p>
          <p className="text vacationTime">
            <FormattedMessage
              id="new-vacation.step2.text"
              defaultMessage="What time do you want this vacation to start and finish?"
            />
          </p>
        </div>
        <div className="popup-main">
          <div className="time-selector" role="combobox" aria-label="pick start time">
            <div className="time-selector-label">
              <b>
                <FormattedMessage id="new-vacation.step2.starts" defaultMessage="Starts" />
              </b>
            </div>
            <TimePicker
              time={selectedTimeRange.start}
              onTimeChange={newTime => setTime('start', newTime)}
              isAmPmHours={isAmPmHours}
            />
          </div>
          <div className="time-selector" role="combobox" aria-label="pick end time">
            <div className="time-selector-label">
              <b>
                <FormattedMessage id="new-vacation.step2.finishes" defaultMessage="Finishes" />
              </b>
            </div>
            <TimePicker
              time={selectedTimeRange.end}
              onTimeChange={newTime => setTime('end', newTime)}
              isAmPmHours={isAmPmHours}
            />
          </div>
        </div>
      </div>

      <div className="popup-bottom-panel">
        <Button className="button--secondary" type="submit" onClick={goNext}>
          <FormattedMessage id="scheduling.title.next" defaultMessage="Next" />
        </Button>
        <Button onClick={goPrevious} className="button--default" type="button">
          <FormattedMessage id="scheduling.title.back" defaultValue="Back" />
        </Button>
      </div>
    </Fragment>
  );
};

VacationTimeSelector.propTypes = {
  selectedTimeRange: PropTypes.object.isRequired,
  isAmPmHours: PropTypes.bool,
  setTime: PropTypes.func.isRequired,
  goNext: PropTypes.func.isRequired,
  goPrevious: PropTypes.func.isRequired,
  selectedDateRange: PropTypes.shape({
    startDate: PropTypes.instanceOf(Date),
    endDate: PropTypes.instanceOf(Date),
  }).isRequired,
};

export default VacationTimeSelector;
