import { Button, Spinner } from 'jpi-cloud-web-ui-components';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { NotificationManager } from 'react-notifications';
import { connect } from 'react-redux';

import { editMenuRawSetting, getDeviceMenu, invokeDeviceEvent } from './actions';
import {
  UIBoolean,
  UIDropdown,
  UIEvent,
  UIGroup,
  UIHeader,
  UIInfoCountdown,
  UIInfoParameter,
  UIInfoText,
  UILink,
  UINumerical,
  UIOptionGroup,
  UISlider,
  UITextBox,
  UITextSetting,
  UITime,
  UITimeSetting,
} from './device-menu-items';
import DeviceMenuHelpModal from './DeviceMenuHelpModal';

import './device-menu.scss';

const notificationTimeoutMilliseconds = 3000;

const canShowMenuRows = menu => {
  // Magic menu ids that're allowed to show were copied from the mobile app.
  return (
    menu.menuType === 'MENU_TYPE_LIST' ||
    (menu.menuType === 'MENU_TYPE_CUSTOM' &&
      (menu.menuTypeId == 60011 ||
        menu.menuTypeId == 60012 ||
        menu.menuTypeId == 60016 ||
        menu.menuTypeId == 60017 ||
        menu.menuTypeId == 60008 ||
        menu.menuTypeId == 60032 ||
        menu.menuTypeId == 60033 ||
        menu.menuTypeId == 60034))
  );
};

export const MenuItem = ({
  menuItem,
  followLink,
  processEvent,
  changeSetting,
  isManageEnabled,
  isDemoUser,
  premiumFeatureType,
  accordionId,
}) => {
  switch (menuItem.type) {
    case 'uievent':
      return <UIEvent menuItem={menuItem} processEvent={processEvent} isDemoUser={isDemoUser} />;
    case 'uiinfotext':
      return <UIInfoText menuItem={menuItem} />;
    case 'uilongtext':
      return <UIInfoText menuItem={menuItem} />;
    case 'uitimesetting':
      return (
        <UITimeSetting
          menuItem={menuItem}
          changeSetting={changeSetting}
          isManageEnabled={isManageEnabled}
          isDemoUser={isDemoUser}
        />
      );
    case 'uiinfoparameter':
      return <UIInfoParameter menuItem={menuItem} isDemoUser={isDemoUser} />;
    case 'uioptiongroup':
      return (
        <UIOptionGroup
          menuItem={menuItem}
          changeSetting={changeSetting}
          isManageEnabled={isManageEnabled}
          isDemoUser={isDemoUser}
          premiumFeatureType={premiumFeatureType}
        />
      );
    case 'uitextsetting':
      return (
        <UITextSetting
          menuItem={menuItem}
          changeSetting={changeSetting}
          isManageEnabled={isManageEnabled}
          isDemoUser={isDemoUser}
        />
      );
    case 'uiboolean':
      return (
        <UIBoolean
          menuItem={menuItem}
          changeSetting={changeSetting}
          isManageEnabled={isManageEnabled}
          isDemoUser={isDemoUser}
          premiumFeatureType={premiumFeatureType}
        />
      );
    case 'uinumerical':
      return (
        <UINumerical
          menuItem={menuItem}
          changeSetting={changeSetting}
          isManageEnabled={isManageEnabled}
          isDemoUser={isDemoUser}
          premiumFeatureType={premiumFeatureType}
        />
      );
    case 'uiheader':
      return <UIHeader menuItem={menuItem} />;
    case 'uilink':
      return <UILink menuItem={menuItem} followLink={followLink} />;
    case 'uiinfocountdown':
      return <UIInfoCountdown menuItem={menuItem} />;
    case 'uitime':
      return <UITime menuItem={menuItem} changeSetting={changeSetting} isDemoUser={isDemoUser} />;
    case 'uitextbox':
      return (
        <UITextBox
          menuItem={menuItem}
          changeSetting={changeSetting}
          isManageEnabled={isManageEnabled}
          isDemoUser={isDemoUser}
          premiumFeatureType={premiumFeatureType}
        />
      );
    case 'uidropdown':
      return (
        <UIDropdown
          menuItem={menuItem}
          changeSetting={changeSetting}
          isManageEnabled={isManageEnabled}
          isDemoUser={isDemoUser}
          premiumFeatureType={premiumFeatureType}
        />
      );
    case 'uislider':
      return (
        <UISlider
          menuItem={menuItem}
          changeSetting={changeSetting}
          isManageEnabled={isManageEnabled}
          isDemoUser={isDemoUser}
          premiumFeatureType={premiumFeatureType}
        />
      );
    case 'uigroup':
      return (
        <UIGroup
          accordionId={accordionId}
          menuItem={menuItem}
          isManageEnabled={isManageEnabled}
          premiumFeatureType={premiumFeatureType}
          changeSetting={changeSetting}
          isDemoUser={isDemoUser}
        />
      );
    default:
      return null;
  }
};

MenuItem.propTypes = {
  menuItem: PropTypes.object.isRequired,
  followLink: PropTypes.func.isRequired,
  processEvent: PropTypes.func.isRequired,
  changeSetting: PropTypes.func.isRequired,
  isManageEnabled: PropTypes.bool,
  premiumFeatureType: PropTypes.string,
  accordionId: PropTypes.number,
};

class DeviceMenu extends React.Component {
  static propTypes = {
    device: PropTypes.object.isRequired,
    deviceMenu: PropTypes.object.isRequired,
    getDeviceMenu: PropTypes.func.isRequired,
    invokeDeviceEvent: PropTypes.func.isRequired,
    editMenuRawSetting: PropTypes.func.isRequired,
    isManageEnabled: PropTypes.bool,
    userInfo: PropTypes.object,
    premiumFeatureType: PropTypes.string,
  };

  state = {
    menuLevelsHistory: [0],
    menuLoading: false,
    deviceUnavailable: false,
    currentMenuId: 0,
    showHelpModal: false,
    loadingDeviceSubMenu: false,
  };

  async componentDidMount() {
    await this.handleDeviceMenuUpdate(this.props.device.id, 0);

    this.startRefreshIntervalCycle(async () => {
      const [menuLevel] = this.state.menuLevelsHistory;

      if (!this.props.deviceMenu.updateSettingsProgressCounter) {
        await this.handleDeviceMenuUpdate(this.props.device.id, menuLevel);
      }
    });
  }

  componentWillUnmount() {
    this.disposeRefreshIntervalCycle(false);
  }

  startRefreshIntervalCycle = handler => {
    const second = 1000;
    const refreshIntervalCycle = setInterval(handler, 10 * second);
    this.setState({ refreshIntervalCycle });
  };

  disposeRefreshIntervalCycle = isSettable => {
    if (this.state.refreshIntervalCycle) {
      clearInterval(this.state.refreshIntervalCycle);
      if (isSettable) {
        this.setState({ ...this.state, menuLevelsHistory: [] });
      }
    }
  };

  followLink = async link => {
    this.setState({ ...this.state, loadingDeviceSubMenu: true });
    if (await this.handleDeviceMenuUpdate(this.props.device.id, link.id)) {
      this.setState({
        ...this.state,
        menuLevelsHistory: [link.id, ...this.state.menuLevelsHistory],
        loadingDeviceSubMenu: false,
      });
    } else {
      this.setState({ ...this.state, loadingDeviceSubMenu: false });
    }
  };

  processEvent = async menuItem => {
    const { device, invokeDeviceEvent } = this.props;
    const [menuLevel] = this.state.menuLevelsHistory;
    await invokeDeviceEvent(device.id, menuItem.eventId);
    await this.handleDeviceMenuUpdate(device.id, menuLevel);
  };

  changeSetting = async (menuItem, value, needsRefresh = false, showSpinner = true) => {
    const {
      device: { id },
      editMenuRawSetting,
    } = this.props;
    const [menuLevel] = this.state.menuLevelsHistory;

    if (needsRefresh && showSpinner) {
      this.setState({ ...this.state, menuLoading: true });
    }

    const unit = menuItem.metadata && menuItem.metadata.unit;
    var editingResult = editMenuRawSetting(id, this.state.currentMenuId, menuItem.parameterId, value, unit).catch(
      error => {
        if (error.response.status == 403 || error.response.status == 401) {
          NotificationManager.error(
            <FormattedMessage
              id="devices.noPermissionToChangeSettings"
              defaultMessage="You are not authorized to change device settings"
            />,
            <FormattedMessage id="security.Error" defaultMessage="Error" />,
            notificationTimeoutMilliseconds,
          );
        } else if (error.response.status == 400) {
          NotificationManager.error(
            <FormattedMessage id="devices.failedToChangeSettings" defaultMessage="Failed to change device settings" />,
            <FormattedMessage id="security.Error" defaultMessage="Error" />,
            notificationTimeoutMilliseconds,
          );
        } else {
          throw error;
        }
      },
    );

    await editingResult;

    if (needsRefresh) {
      setTimeout(async () => {
        if (await this.handleDeviceMenuUpdate(id, menuLevel)) {
          this.setState({ ...this.state, menuLoading: false });
        }
      }, 2000);
    }
  };

  back = () => {
    this.setState({ ...this.state, menuLevelsHistory: this.state.menuLevelsHistory.filter((_, i) => i !== 0) });
  };

  handleDeviceMenuUpdate = async (deviceId, id) => {
    const deviceMenuUpdated = await this.props.getDeviceMenu(deviceId, id);

    if (!deviceMenuUpdated) {
      this.setState({ ...this.state, deviceUnavailable: true });
      return false;
    }

    this.setState({ ...this.state, currentMenuId: id });
    return true;
  };

  render() {
    const { deviceMenu, isManageEnabled, userInfo, premiumFeatureType, device } = this.props;
    const { deviceUnavailable, menuLevelsHistory } = this.state;
    const [menuLevel] = menuLevelsHistory;

    const menu = deviceMenu[device.id] ? deviceMenu[device.id][menuLevel] : null;

    if (deviceUnavailable || !device.online) {
      return (
        <div className="menu-unavailable-wrapper">
          <FormattedMessage
            id="devices.menu-simplified-unavailable"
            defaultMessage="Could not connect to device. Some functionality may not be available."
          />
        </div>
      );
    }

    if (!menu || this.state.menuLoading) {
      return (
        <div className="menu-spinner-wrapper">
          <Spinner dark />
        </div>
      );
    }

    if (!canShowMenuRows(menu)) {
      return (
        <div>
          {menuLevelsHistory.length > 1 && (
            <Button className="button--default button--bottom-spacing" onClick={this.back} type="button">
              <FormattedMessage id="button.back" defaultMessage="Back" />
            </Button>
          )}
          <p className="panel panel-primary">Menu does not exists</p>
        </div>
      );
    }

    const filteredRows = menu.rows.filter(r => r.visible && !r.hideCloud && !r.hideNonPro);

    return (
      <div className="device-menu-row">
        <div className="menu-heading">
          <h3>
            {menu.number === '0' ? '' : menu.number} {menu.name.text}
          </h3>
          {menu && menu.help && menu.help.text.length > 0 && (
            <Button
              className="button--default button--bottom-spacing"
              onClick={() => this.setState({ ...this.state, showHelpModal: !this.state.showHelpModal })}
              type="button"
              disabled={this.state.loadingDeviceSubMenu}
            >
              <FormattedMessage id="menu.systems.help" defaultMessage="Help" />
            </Button>
          )}
        </div>
        {this.state.loadingDeviceSubMenu ? (
          <Spinner dark />
        ) : (
          <ul className="list-group">
            {filteredRows.map((menuItem, i) => {
              return (
                <li key={`menuItem_${this.props.device.id}_#${i}`} className="list-group-item">
                  <MenuItem
                    accordionId={i}
                    menuItem={menuItem}
                    processEvent={this.processEvent}
                    changeSetting={this.changeSetting}
                    followLink={this.followLink}
                    isManageEnabled={isManageEnabled}
                    premiumFeatureType={premiumFeatureType}
                    isDemoUser={userInfo && userInfo.isDemo}
                  />
                </li>
              );
            })}
          </ul>
        )}
        {menuLevelsHistory.length > 1 && (
          <Button
            className="button--default button--bottom-spacing"
            onClick={this.back}
            type="button"
            disabled={this.state.loadingDeviceSubMenu}
          >
            <FormattedMessage id="button.back" defaultMessage="Back" />
          </Button>
        )}
        <DeviceMenuHelpModal
          menu={menu}
          showHelpModal={this.state.showHelpModal}
          hideHelpModal={() => this.setState({ ...this.state, showHelpModal: !this.state.showHelpModal })}
        />
      </div>
    );
  }
}

export default connect(
  ({ deviceMenu, app: { userInfo } }) => ({
    deviceMenu,
    userInfo,
  }),
  {
    getDeviceMenu,
    invokeDeviceEvent,
    editMenuRawSetting,
  },
)(DeviceMenu);
