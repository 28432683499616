import React from 'react';
import { brandsAndUrl } from '../../../../assets/brands/brands';
import google from '../../../../assets/img/heyGoogle.svg';
import alexa from '../../../../assets/img/WWA.svg';
import IFTT from '../../../../assets/img/IFTTT.svg';

const smartHomeDevices = [
  {
    deviceName: 'google',
    url: google,
    height: 30,
  },
  {
    deviceName: 'alexa',
    url: alexa,
    height: 30,
  },
  {
    deviceName: 'IFTT',
    url: IFTT,
    height: 30,
  },
];

export const SmartDeviceLogo = () => {
  return (
    <div className="smart-device-logo-wrapper">
      {smartHomeDevices.map(device => (
        <div className="device-logo-cont" key={device.deviceName}>
          <img className="device-logo" src={device.url} alt={device.deviceName} height={device.height} width="70" />
        </div>
      ))}
    </div>
  );
};

export const BrandLogo = () => {
  return (
    <div className="brand-list-wrapper">
      {brandsAndUrl.map(
        brand =>
          !brand.hide && (
            <div
              className="brand-logo-wrapper"
              role="button"
              tabIndex="0"
              onClick={() => window.open(brand.companyUrl, '_blank')}
              onKeyDown={() => window.open(brand.companyUrl, '_blank')}
            >
              <img className="brand-logo" key={brand.brand} src={brand.url} alt={brand.brand} height="70" width="70" />
            </div>
          ),
      )}
    </div>
  );
};

const WorksWithBrands = () => {
  return (
    <div className="works-with-brands-wrapper">
      <SmartDeviceLogo />
      <BrandLogo />
    </div>
  );
};

export default WorksWithBrands;
