import * as api from '../../../api';
import { isVersionUpdateAvailable } from '../../AppInitializer/utils';

export const GET_DEVICES_REQUEST = 'devices/GET_DEVICES_REQUEST';
export const GET_DEVICES_RESPONSE = 'devices/GET_DEVICES_RESPONSE';
export const GET_DEVICES_FAILED = 'devices/GET_DEVICES_FAILED';
export const getDevices = systemId => async dispatch => {
  dispatch({ type: GET_DEVICES_REQUEST });

  try {
    const response = await api.getDevices(systemId);
    if (response.status === 200) {
      let devices = response.data.map(d => ({
        ...d,
        outdatedFirmware: isVersionUpdateAvailable(d.desiredFwVersion, d.currentFwVersion),
      }));

      devices = devices.map(d => {
        if (d.slaves && d.slaves.length) {
          d.slaves = d.slaves.map(s => ({ ...s, gatewayDeviceId: d.id }));
        }
        return d;
      });
      dispatch({
        type: GET_DEVICES_RESPONSE,
        devices,
      });

      return devices;
    }
    dispatch({ type: GET_DEVICES_FAILED });
  } catch {
    dispatch({ type: GET_DEVICES_FAILED });
  }

  return [];
};

export const DISCONNECT_DEVICE_REQUEST = 'devices/DISCONNECT_DEVICE_REQUEST';
export const DISCONNECT_DEVICE_RESPONSE = 'devices/DISCONNECT_DEVICE_RESPONSE';
export const disconnectDevice = (device, group) => async dispatch => {
  dispatch({ type: DISCONNECT_DEVICE_REQUEST });
  const response = await api.disconnectDevice(device.id, group.id);

  if (response.status === 200) {
    dispatch({ type: DISCONNECT_DEVICE_RESPONSE, device });
  }
};

export const GET_SUBSCRIPTIONS_IN_GROUP_REQUEST = 'devices/GET_SUBSCRIPTIONS_IN_GROUP_REQUEST';
export const GET_SUBSCRIPTIONS_IN_GROUP_RESPONSE = 'devices/GET_SUBSCRIPTIONS_IN_GROUP_RESPONSE';
export const GET_SUBSCRIPTIONS_IN_GROUP_FAILED = 'devices/GET_SUBSCRIPTIONS_IN_GROUP_FAILED';
export const getSubscriptionsInGroup = groupId => async dispatch => {
  dispatch({ type: GET_SUBSCRIPTIONS_IN_GROUP_REQUEST });

  try {
    const response = await api.getDeviceSubscriptionsInGroup(groupId);

    if (response.status === 200) {
      const deviceSubscriptions = response.data;
      dispatch({
        type: GET_SUBSCRIPTIONS_IN_GROUP_RESPONSE,
        deviceSubscriptions,
      });
      return deviceSubscriptions;
    }
    dispatch({ type: GET_SUBSCRIPTIONS_IN_GROUP_FAILED });
  } catch {
    dispatch({ type: GET_SUBSCRIPTIONS_IN_GROUP_FAILED });
  }

  return {};
};

export const SET_SELECTED_DEVICE = 'devices/SET_SELECTED_DEVICE';
export const setSelectedDevice = device => async dispatch => {
  dispatch({ type: SET_SELECTED_DEVICE, device });
};

export const HIDE_CONFIRM_ON_DEVICE = 'devices/HIDE_CONFIRM_ON_DEVICE';
export const hideConfirmOnDevice = () => async dispatch => {
  dispatch({ type: HIDE_CONFIRM_ON_DEVICE });
};

export const SET_DEVICE_FIRMWARE_LOADING_STATUS = 'devices/SET_DEVICE_FIRMWARE_LOADING_STATUS';

export const setFwInfoLoading =
  (loadingUpdateInfo = false) =>
  async dispatch => {
    dispatch({ type: SET_DEVICE_FIRMWARE_LOADING_STATUS, loadingUpdateInfo });
  };

export const GET_DEVICE_FIRMWARE_UPDATE_STATUS_RESPONSE = 'devices/GET_DEVICE_FIRMWARE_UPDATE_STATUS_RESPONSE';
export const GET_DEVICE_FIRMWARE_UPDATE_STATUS_FAILED = 'devices/GET_DEVICE_FIRMWARE_UPDATE_STATUS_FAILED';

export const getDeviceUpdateStatus = deviceId => async dispatch => {
  try {
    const response = await api.getDeviceUpdateStatus(deviceId);
    if (response.status === 200) {
      const updateInfo = response.data;
      dispatch({ type: GET_DEVICE_FIRMWARE_UPDATE_STATUS_RESPONSE, updateInfo });
      return updateInfo;
    }
    dispatch({ type: GET_DEVICE_FIRMWARE_UPDATE_STATUS_FAILED });
  } catch {
    dispatch({ type: GET_DEVICE_FIRMWARE_UPDATE_STATUS_FAILED });
  }
};

export const updateStatus = {
  notStarted: 'not-started',
  inProgress: 'in-progress',
  finished: 'finished',
  error: 'error',
};

export const UPDATE_DEVICE_FIRMWARE_REQUEST = 'devices/UPDATE_DEVICE_FIRMWARE_REQUEST';
export const UPDATE_DEVICE_FIRMWARE_RESPONSE = 'devices/UPDATE_DEVICE_FIRMWARE_RESPONSE';
export const UPDATE_DEVICE_FIRMWARE_FAILED = 'devices/UPDATE_DEVICE_FIRMWARE_FAILED';

export const updateDeviceFirmware =
  (deviceId, slaveDeviceId = '') =>
  async dispatch => {
    dispatch({ type: UPDATE_DEVICE_FIRMWARE_REQUEST });

    try {
      const response = await api.updateDeviceFirmware(deviceId, slaveDeviceId);
      if (response.status === 200) {
        dispatch({
          type: UPDATE_DEVICE_FIRMWARE_RESPONSE,
        });
        dispatch(getDeviceUpdateStatus(deviceId));
        return true;
      }
      dispatch({ type: UPDATE_DEVICE_FIRMWARE_FAILED });
    } catch {
      dispatch({ type: UPDATE_DEVICE_FIRMWARE_FAILED });
    }

    return false;
  };
