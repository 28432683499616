import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { NotificationManager } from 'react-notifications';

import TileManager from '../../tiles/TileManager';
import SystemStatus from '../../layout/SystemStatus';
import AccountMigrationInfoModal from './components/AccountMigrationInfoModal';
import { useCancelMigrationBanner } from '../../../services/queries/useCancelMigrationBanner';

import './home.scss';

const HomePage = ({ history, userInfo }) => {
  const [shouldShowMigrationInfo, setShouldShowMigrationInfo] = useState(false);
  const { informAboutMigration, userSite } = userInfo || {};

  const mutation = useCancelMigrationBanner();

  useEffect(() => {
    if (informAboutMigration) {
      setShouldShowMigrationInfo(true);
    }
  }, [informAboutMigration]);

  const onClose = () => {
    setShouldShowMigrationInfo(false);
    mutation.mutate();
  };

  if (mutation.isError) {
    NotificationManager.error(mutation.error.message);
  }

  return (
    <div className="page-content">
      <div className="home__wrapper">
        <div className="row">
          {shouldShowMigrationInfo && <AccountMigrationInfoModal onClose={onClose} userSite={userSite} />}
          <SystemStatus push={history.push} />
          <TileManager />
        </div>
      </div>
    </div>
  );
};

HomePage.propTypes = {
  history: PropTypes.object.isRequired,
  userInfo: PropTypes.object.isRequired,
};

export default connect(({ app: { userInfo } }) => ({
  userInfo,
}))(HomePage);
