import PropTypes from 'prop-types';
import React from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';

import PremiumFeatureTooltip from '../../layout/PremiumFeatureTooltip';

import './number-input.scss';

class NumberInput extends React.Component {
  constructor(props) {
    super(props);

    this.timeout = null;
  }

  state = {
    value: this.props.value,
  };

  handleChange = step => () => {
    if (!step || this.props.isDisabledAndVisibleTooltip || this.props.disabledDueToOffline || this.props.pendingValue)
      return;

    let newVal = +this.state.value + step * (+this.props.stepVal || 1);

    if (newVal < this.props.minVal) {
      // TODO: Add note to user or disable
      newVal = this.props.minVal;
    }

    if (newVal > this.props.maxVal) {
      newVal = this.props.maxVal;
    }

    this.setState(
      () => ({
        value: newVal,
      }),
      () => this.sendPatchSettingRequest(this.state.value),
    );
  };

  sendPatchSettingRequest = currentValue => {
    clearTimeout(this.timeout);

    this.timeout = setTimeout(
      currentValue => (currentValue === this.state.value ? this.props.onChange(currentValue) : () => {}),
      500,
      currentValue,
    );
  };

  replaceValues = v => {
    const { replaceValues } = this.props;
    if (replaceValues && replaceValues.length) {
      const replacer = replaceValues.find(ev => ev.value.toString() === v.toString());
      if (replacer) {
        return replacer.text;
      }
    }
    return null;
  };

  renderReplacer = text => {
    const textLengthForShowTooltip = 23;
    const scheduleTextLengthForShowTooltip = 13;
    const replaceTextLength =
      this.props.className == 'shortText' ? scheduleTextLengthForShowTooltip : textLengthForShowTooltip;

    const renderTooltip = () => {
      return text.length > replaceTextLength ? <Popover id="replacer">{text}</Popover> : <></>;
    };

    return (
      <OverlayTrigger delayHide={500} trigger={['hover', 'focus']} placement="top" overlay={renderTooltip()}>
        <div className="text-replacer">{text}</div>
      </OverlayTrigger>
    );
  };

  // FIXME: @deprecated method used as patch!
  UNSAFE_componentWillReceiveProps({ value }) {
    this.setState({ value });
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  render() {
    const formatter = this.props.valueFormatter || (v => v);
    const replaceWith = this.replaceValues(this.state.value);

    const btnsDisabled =
      this.props.isDisabledAndVisibleTooltip ||
      this.props.disabledDueToOffline ||
      this.props.pendingValue ||
      this.props.isPending;

    return (
      <div className="numberinputComponent-container" style={{ opacity: this.props.pendingValue ? 0.5 : 1 }}>
        <div
          className="numberinputComponent__minus numberinputComponent__button"
          onClick={this.handleChange(-1)}
          style={{ opacity: btnsDisabled ? 0.5 : 1, pointerEvents: btnsDisabled ? 'none' : 'auto' }}
        >
          <div className="numberinputComponent__element">
            <a className="numberinputComponent__buttonicon">-</a>
          </div>
        </div>
        {this.props.isDisabledAndVisibleTooltip && (
          <PremiumFeatureTooltip
            premiumFeatureType={this.props.premiumFeatureType ? this.props.premiumFeatureType : 'number'}
          />
        )}
        {this.props.disabledDueToOffline && <PremiumFeatureTooltip translationId="addDevice.systemSelector.offline" />}
        {replaceWith ? (
          this.renderReplacer(replaceWith)
        ) : (
          <input
            disabled
            className="numberinputComponent__input"
            type="text"
            pattern="[0-9]*"
            value={
              this.props.unit && this.props.unit.trim() === '$'
                ? this.props.unit + formatter(this.state.value)
                : formatter(this.state.value) + (this.props.unit || '')
            }
          />
        )}
        <div
          className="numberinputComponent__plus numberinputComponent__button"
          onClick={this.handleChange(1)}
          style={{ opacity: btnsDisabled ? 0.5 : 1, pointerEvents: btnsDisabled ? 'none' : 'auto' }}
        >
          <div className="numberinputComponent__element">
            <a className="numberinputComponent__buttonicon">+</a>
          </div>
        </div>
        {this.props.isDisabledAndVisibleTooltip && (
          <PremiumFeatureTooltip
            premiumFeatureType={this.props.premiumFeatureType ? this.props.premiumFeatureType : 'number'}
          />
        )}
        {this.props.disabledDueToOffline && <PremiumFeatureTooltip translationId="addDevice.systemSelector.offline" />}
      </div>
    );
  }
}

NumberInput.propTypes = {
  value: PropTypes.number,
  pendingValue: PropTypes.number,
  unit: PropTypes.string,
  onChange: PropTypes.func,
  stepVal: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  minVal: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  maxVal: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isDisabledAndVisibleTooltip: PropTypes.bool,
  disabledDueToOffline: PropTypes.bool,
  premiumFeatureType: PropTypes.string,
  valueFormatter: PropTypes.func,
  replaceValues: PropTypes.array,
  className: PropTypes.string,
  isPending: PropTypes.bool,
};

export default NumberInput;
