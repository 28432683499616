import React from 'react';
import { NotificationManager } from 'react-notifications';
import { FormattedMessage } from 'react-intl';
import { resetAlarmErrorMessages, unknownErrorMessage, activateAidModeErrorMessages } from '../../constants';

import * as api from '../../../../../api';

export const RESET_ALARM_REQUEST = 'alarm/RESET_ALARM_REQUEST';
export const RESET_ALARM_RESPONSE_SUCCEEDED = 'alarm/RESET_ALARM_RESPONSE_SUCCEEDED';
export const RESET_ALARM_RESPONSE_FAILED = 'alarm/RESET_ALARM_RESPONSE_FAILED';

export const resetAlarm = alarm => async dispatch => {
  dispatch({ type: RESET_ALARM_REQUEST, alarm });

  try {
    await api.resetAlarm(alarm);
    dispatch({ type: RESET_ALARM_RESPONSE_SUCCEEDED, alarm });
  } catch (e) {
    const errorDetails = resetAlarmErrorMessages[e.response?.status] || unknownErrorMessage;
    NotificationManager.error(<FormattedMessage id={errorDetails.id} defaultMessage={errorDetails.defaultMessage} />);
    dispatch({
      type: RESET_ALARM_RESPONSE_FAILED,
      alarm,
    });
  }
};

export const ACTIVATE_AID_MODE_ALARM_REQUEST = 'alarm/ACTIVATE_AID_MODE_ALARM_REQUEST';
export const ACTIVATE_AID_MODE_ALARM_RESPONSE_SUCCEEDED = 'alarm/ACTIVATE_AID_MODE_ALARM_RESPONSE_SUCCEEDED';
export const ACTIVATE_AID_MODE_ALARM_RESPONSE_FAILED = 'alarm/ACTIVATE_AID_MODE_ALARM_RESPONSE_FAILED';

export const activateAidModeForAlarm = alarm => async dispatch => {
  const aidMode = alarm.activeAidMode === 'on' ? 'off' : 'on';
  dispatch({ type: ACTIVATE_AID_MODE_ALARM_REQUEST, alarm, aidMode });

  try {
    await api.activateAidModeForAlarm(alarm, aidMode);
    dispatch({ type: ACTIVATE_AID_MODE_ALARM_RESPONSE_SUCCEEDED, request: { alarm, aidMode } });
  } catch (e) {
    const errorDetails = activateAidModeErrorMessages[e.response?.status] || unknownErrorMessage;
    NotificationManager.error(<FormattedMessage id={errorDetails.id} defaultMessage={errorDetails.defaultMessage} />);
    dispatch({
      type: ACTIVATE_AID_MODE_ALARM_RESPONSE_FAILED,
      request: { alarm, aidMode },
    });
  }
};
