import moment from 'moment';
import { dateTimeFormatString } from '../components/constants/constants';

const getTilesByUserKey = (systemId, userId) => `TILE_USER_CONFIGURATION_${systemId}_${userId}`;
const getPrepopulatedSystemsByUserKey = userId => `PREPOPULATED_SYSTEMS_${userId}`;
const getTilesLastRefreshedByUserKey = (systemId, userId) => `TILES_LAST_REFRESHED_${systemId}_${userId}`;

const defaultTiles = [];

/*
 overwrites all tile configuration
*/
export const write = (tiles, systemId, userId) => {
  const json = JSON.stringify(tiles.map(t => ({ ...t, expanded: false })));
  localStorage.setItem(getTilesByUserKey(systemId, userId), json);
  return Promise.resolve();
};

export const setLastRefreshed = (userId, systemId) => {
  if (!userId || !systemId) {
    return new Promise(resolve => resolve({ status: 400 }));
  }
  const now = moment.utc().format(dateTimeFormatString);
  localStorage.setItem(getTilesLastRefreshedByUserKey(systemId, userId), now);
  return Promise.resolve();
};

export const resetAllTilesLastRefreshed = () => {
  const keys = [];
  for (let i = 0; i < localStorage.length; i++) {
    if (localStorage.key(i).startsWith('TILES_LAST_REFRESHED_')) {
      keys.push(localStorage.key(i));
    }
  }
  for (let i = 0; i < keys.length; i++) {
    localStorage.removeItem(keys[i]);
  }
  return Promise.resolve();
};

export const getLastRefreshed = (userId, systemId) => {
  if (!userId || !systemId) {
    return new Promise(resolve => resolve({ status: 400 }));
  }

  let lastRefreshed = localStorage.getItem(getTilesLastRefreshedByUserKey(systemId, userId));
  if (!lastRefreshed) {
    lastRefreshed = moment.utc().subtract(1, 'years').format(dateTimeFormatString);
  }
  return new Promise(resolve => {
    const response = {
      status: 200,
      data: lastRefreshed,
    };

    resolve(response);
  });
};

/*
 removes all tiles by system/user combination
*/
export const remove = (systemId, userId) => {
  const json = localStorage.getItem(getTilesByUserKey(systemId, userId));

  if (!json) {
    return new Promise(resolve => {
      const response = {
        status: 404,
      };

      resolve(response);
    });
  }

  const lastRefreshed = localStorage.getItem(getTilesLastRefreshedByUserKey(systemId, userId));
  if (lastRefreshed) {
    localStorage.removeItem(getTilesLastRefreshedByUserKey(systemId, userId));
  }

  localStorage.removeItem(getTilesByUserKey(systemId, userId));
  return new Promise(resolve => {
    const response = {
      status: 200,
    };

    resolve(response);
  });
};

export const removeByDevice = (systemId, userId, deviceId) => {
  const storageKey = getTilesByUserKey(systemId, userId);
  const json = localStorage.getItem(storageKey);

  if (!json) {
    return new Promise(resolve => {
      const response = {
        status: 404,
      };

      resolve(response);
    });
  }

  const tiles = json ? JSON.parse(json) : defaultTiles;
  const filteredTiles = tiles.filter(tile => tile.deviceId !== deviceId);

  const updatedJson = JSON.stringify(filteredTiles);
  localStorage.setItem(storageKey, updatedJson);

  const lastRefreshed = localStorage.getItem(getTilesLastRefreshedByUserKey(systemId, userId));
  if (lastRefreshed) {
    localStorage.removeItem(getTilesLastRefreshedByUserKey(systemId, userId));
  }

  return new Promise(resolve => {
    const response = {
      status: 200,
      tiles: filteredTiles,
    };

    resolve(response);
  });
};

/*
TODO: implement when knowing how its supposed to work
*/
export const get = (systemId, userId) => {
  if (!systemId) {
    return new Promise(resolve => resolve({ status: 400 }));
  }
  const json = localStorage.getItem(getTilesByUserKey(systemId, userId));
  let tiles = json ? JSON.parse(json) : defaultTiles;

  // if default value was used
  // to distinguish tile switch on the UI
  if (!json && systemId === 'system_2') {
    tiles = [...tiles].reverse();
  }

  return new Promise(resolve => {
    const response = {
      status: 200,
      data: tiles,
    };

    resolve(response);
  });
};

export const getPrepopulationStatus = (userId, systemId) => {
  if (!userId || !systemId) {
    return new Promise(resolve => resolve({ status: 400 }));
  }

  const key = getPrepopulatedSystemsByUserKey(userId);
  const json = localStorage.getItem(key);

  const prepopulatedSystems = json ? JSON.parse(json) : [];

  return new Promise(resolve => {
    const response = {
      status: 200,
      data: prepopulatedSystems.includes(systemId),
    };

    resolve(response);
  });
};

export const setSystemAsPrepopulated = (userId, systemId) => {
  const key = getPrepopulatedSystemsByUserKey(userId);
  const json = localStorage.getItem(key);

  const prepopulatedSystems = json ? JSON.parse(json) : [];

  if (!prepopulatedSystems.includes(systemId)) {
    prepopulatedSystems.push(systemId);
  }

  const resultJson = JSON.stringify(prepopulatedSystems);
  localStorage.setItem(key, resultJson);
  return Promise.resolve();
};

export const unsetSystemAsPrepopulated = (userId, systemId) => {
  const key = getPrepopulatedSystemsByUserKey(userId);
  const json = localStorage.getItem(key);

  let prepopulatedSystems = json ? JSON.parse(json) : [];

  if (prepopulatedSystems.includes(systemId)) {
    prepopulatedSystems = prepopulatedSystems.filter(system => system != systemId);
  }

  const resultJson = JSON.stringify(prepopulatedSystems);
  localStorage.setItem(key, resultJson);
  return Promise.resolve();
};
