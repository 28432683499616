import React, { Component } from 'react';
import { HamburgerSubMenu } from './components/HamburgerSubMenu/HamburgerSubMenu';
import HamburgerMenuButton from './components/HamburgerMenuButton';

import './hamburger-menu.scss';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { logoutUser } from '../../../../pages/Login/actions';

class HamburgerMenu extends Component {
  state = {
    menuOpen: false,
  };

  componentWillUnmount() {
    document.removeEventListener('click', this.handleOutsideClick, false);
  }

  handleMenuClick() {
    if (!this.state.menuOpen) {
      document.addEventListener('click', this.handleOutsideClick, false);
    } else {
      document.removeEventListener('click', this.handleOutsideClick, false);
    }

    this.setState({ menuOpen: !this.state.menuOpen });
  }

  handleOutsideClick = e => {
    if (this.node && this.node.contains(e.target)) {
      return;
    }

    document.removeEventListener('click', this.handleOutsideClick, false);
    this.setState({ menuOpen: false });
  };

  render() {
    const { menuItems, redirectIotStore, selectedSystem, logout } = this.props;
    const { menuOpen } = this.state;
    const subMenus = menuItems.map((val, index) => (
      <HamburgerSubMenu
        key={index}
        {...val}
        redirectIotStore={redirectIotStore}
        selectedSystem={selectedSystem}
        logoutUser={logout}
      >
        {val.children}
      </HamburgerSubMenu>
    ));

    return (
      <div
        ref={node => {
          this.node = node;
        }}
      >
        <HamburgerMenuButton open={menuOpen} onClick={() => this.handleMenuClick()} />
        {menuOpen && (
          <div className="hamburgerMenu">
            <div className="hamburgerMenu__list">{subMenus}</div>
          </div>
        )}
      </div>
    );
  }
}

HamburgerMenu.propTypes = {
  menuItems: PropTypes.array,
  logout: PropTypes.func.isRequired,
  redirectIotStore: PropTypes.func,
  selectedSystem: PropTypes.object,
};

const mapStateToProps = ({ logout, app: { selectedSystem } }) => ({
  ...logout,
  selectedSystem,
});

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(logoutUser(false)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HamburgerMenu);
