import {
  GET_DEVICES_REQUEST,
  GET_DEVICES_RESPONSE,
  GET_DEVICES_FAILED,
  DISCONNECT_DEVICE_RESPONSE,
  GET_SUBSCRIPTIONS_IN_GROUP_REQUEST,
  GET_SUBSCRIPTIONS_IN_GROUP_RESPONSE,
  GET_SUBSCRIPTIONS_IN_GROUP_FAILED,
  SET_SELECTED_DEVICE,
  SET_DEVICE_FIRMWARE_LOADING_STATUS,
  GET_DEVICE_FIRMWARE_UPDATE_STATUS_RESPONSE,
  GET_DEVICE_FIRMWARE_UPDATE_STATUS_FAILED,
  UPDATE_DEVICE_FIRMWARE_RESPONSE,
  UPDATE_DEVICE_FIRMWARE_FAILED,
  UPDATE_DEVICE_FIRMWARE_REQUEST,
  HIDE_CONFIRM_ON_DEVICE,
  updateStatus,
} from './actions';

const initialState = {
  loading: false,
  devices: [],
  loadingSubscriptions: false,
  subscriptions: {},
  selectedDevice: null,
  updateInfo: null,
  loadingUpdateInfo: true,
  isConfirmOnDeviceShow: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_DEVICES_REQUEST:
      return { ...state, loading: true, devices: [] };
    case GET_DEVICES_RESPONSE:
      return { ...state, loading: false, devices: action.devices };
    case GET_DEVICES_FAILED:
      return { ...state, loading: false, devices: [] };
    case DISCONNECT_DEVICE_RESPONSE:
      return { ...state, devices: state.devices.filter(d => d.id !== action.device.id) };
    case GET_SUBSCRIPTIONS_IN_GROUP_REQUEST:
      return { ...state, loadingSubscriptions: true, subscriptions: {} };
    case GET_SUBSCRIPTIONS_IN_GROUP_RESPONSE:
      return { ...state, loadingSubscriptions: false, subscriptions: action.deviceSubscriptions };
    case GET_SUBSCRIPTIONS_IN_GROUP_FAILED:
      return { ...state, loadingSubscriptions: false, subscriptions: {} };
    case SET_SELECTED_DEVICE:
      return { ...state, selectedDevice: action.device };
    case SET_DEVICE_FIRMWARE_LOADING_STATUS:
      return { ...state, loadingUpdateInfo: action.loadingUpdateInfo };
    case GET_DEVICE_FIRMWARE_UPDATE_STATUS_RESPONSE:
      return { ...state, updateInfo: action.updateInfo, loadingUpdateInfo: false };
    case UPDATE_DEVICE_FIRMWARE_REQUEST:
      return { ...state, isConfirmOnDeviceShow: true };
    case HIDE_CONFIRM_ON_DEVICE:
      return { ...state, isConfirmOnDeviceShow: false };
    case GET_DEVICE_FIRMWARE_UPDATE_STATUS_FAILED:
      return { ...state, updateInfo: null, loadingUpdateInfo: false };
    case UPDATE_DEVICE_FIRMWARE_RESPONSE:
      return { ...state, fwUpdateInitializationStatus: updateStatus.finished };
    case UPDATE_DEVICE_FIRMWARE_FAILED:
      return { ...state, fwUpdateInitializationStatus: updateStatus.error };
    default:
      return state;
  }
};
