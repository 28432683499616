import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { Button, Spinner } from 'jpi-cloud-web-ui-components';

import { getSmartGuideQuestions, setSmartGuideAnswerOption } from './actions';
import { MenuItem } from '../../pages/DeviceSettings/device-menu';
import { invokeDeviceEvent } from '../../pages/DeviceSettings/device-menu/actions';
import { editTileRawSetting } from '../../tiles/TileManager/actions';
import TileIcons, { getTileIcon } from '../GenericTile/tile-icons';
import { USER_SECURITY_LEVELS } from '../../pages/SystemProfile/components/SecurityTab/costants';

import PremiumFeatureTooltip from '../../layout/PremiumFeatureTooltip';

import './smartguide.scss';

const SmartGuideWelcomeView = ({
  goToQuestion,
  systemStatus,
  isManageEnabled,
  tileHeadingStyles,
  tileTextStyles,
  tileButtonStyles,
  userRole,
}) => (
  <div className="smart-guide__info-wrapper tile__contentWrapper">
    <h3 className="smart-guide__tile-heading" style={tileHeadingStyles}>
      <FormattedMessage
        id="smartguide.welcomeView.title"
        defaultMessage="Need assistance? No problem. Our SmartGuide is here to help!"
        description="Title on SmartGuide welcome view"
      />
    </h3>
    <p className="smart-guide__tile-description" style={tileTextStyles}>
      <FormattedMessage
        id="smartguide.welcomeView.description"
        defaultMessage="Let us help you adapt and optimize your system. SmartGuide will give you continous feedback"
        description="Description on SmartGuide welcome view"
      />
    </p>
    <div className="smart-guide__link">
      <FormattedMessage
        id="smartguide.welcomeView.startSmartGuideButton"
        defaultMessage="Start SmartGuide"
        description="Start button on SmartGuide welcome view"
      >
        {text => (
          <>
            {!isManageEnabled && (
              <div style={{ textAlign: 'center' }}>
                <div className="badge badge-success premium-badge">PREMIUM</div>
                <PremiumFeatureTooltip
                  premiumFeatureType={userRole === USER_SECURITY_LEVELS.viewer ? 'permissionAccess' : 'manage'}
                />
              </div>
            )}
            <Button
              disabled={
                userRole === USER_SECURITY_LEVELS.viewer || !isManageEnabled || systemStatus.currentStatus !== 'online'
              }
              onClick={() => goToQuestion(0)}
              className="button--secondary"
              style={tileButtonStyles}
              type="button"
            >
              {text}
            </Button>
            {userRole === USER_SECURITY_LEVELS.viewer && (
              <PremiumFeatureTooltip premiumFeatureType="permissionAccess" />
            )}
            {userRole !== USER_SECURITY_LEVELS.viewer && !isManageEnabled && (
              <PremiumFeatureTooltip premiumFeatureType="manage" />
            )}
            {systemStatus.currentStatus !== 'online' && (
              <PremiumFeatureTooltip translationId="addDevice.systemSelector.offline" />
            )}
          </>
        )}
      </FormattedMessage>
    </div>
  </div>
);
SmartGuideWelcomeView.propTypes = {
  goToQuestion: PropTypes.func.isRequired,
  systemStatus: PropTypes.object,
  isManageEnabled: PropTypes.bool,
  userRole: PropTypes.string,
  tileHeadingStyles: PropTypes.object,
  tileTextStyles: PropTypes.object,
  tileButtonStyles: PropTypes.object,
};

const noop = () => {};
class UIGuideAnswer extends React.Component {
  static propTypes = {
    answer: PropTypes.object.isRequired,
    deviceId: PropTypes.string,

    goToQuestion: PropTypes.func.isRequired,
    invokeDeviceEvent: PropTypes.func.isRequired,
    editTileRawSetting: PropTypes.func.isRequired,
    isManageEnabled: PropTypes.bool,
    guideNumber: PropTypes.number,
    setSmartGuideAnswerOption: PropTypes.func.isRequired,
  };
  state = {
    setting: null,
  };
  handleClick = async () => {
    const { answer, deviceId, goToQuestion, invokeDeviceEvent, editTileRawSetting } = this.props;

    if (this.state.setting) {
      const unit = this.state.setting.setting.metadata && this.state.setting.setting.metadata.unit;
      await editTileRawSetting(deviceId, this.state.setting.setting.parameterId, this.state.setting.value, unit);
      this.setState({ ...this.state, setting: null });
    }
    if (answer.eventId !== 0) {
      await invokeDeviceEvent(deviceId, answer.eventId);
    }

    goToQuestion(answer.guideNumber);
  };
  changeSetting = (setting, value) => {
    const { answer, deviceId, guideNumber, setSmartGuideAnswerOption } = this.props;
    this.setState({ ...this.state, setting: { setting, value } });
    setSmartGuideAnswerOption(deviceId, guideNumber, answer, setting, value);
  };
  render() {
    const { answer, isManageEnabled } = this.props;
    return (
      <div className="smart-guide__link">
        {answer.setting && (
          <MenuItem
            menuItem={answer.setting}
            followLink={noop}
            processEvent={noop}
            changeSetting={this.changeSetting}
            isManageEnabled={isManageEnabled}
          />
        )}
        <Button disabled={!isManageEnabled} onClick={this.handleClick} className="button--secondary" type="button">
          {answer.text.text}
        </Button>
      </div>
    );
  }
}

const SmartGuideQuestion = ({
  question,
  deviceId,
  goToQuestion,
  invokeDeviceEvent,
  editTileRawSetting,
  clearHistory,
  isManageEnabled,
  guideNumber,
  setSmartGuideAnswerOption,
}) => (
  <div className="smart-guide__info-wrapper tile__contentWrapper">
    <h3 className="smart-guide__tile-heading">{question.question.text}</h3>
    {question.answers.length === 0 && (
      <div className="smart-guide__link">
        <FormattedMessage
          id="smartguide.step.restartSmartGuideButton"
          defaultMessage="Restart smart guide"
          description="Restart smart guide button on SmartGuide tile"
        >
          {text => (
            <Button onClick={clearHistory} className="button--secondary" type="text">
              {text}
            </Button>
          )}
        </FormattedMessage>
      </div>
    )}
    {question.answers.map((a, i) => (
      <UIGuideAnswer
        key={`smart_guide_answer_${i}`}
        goToQuestion={goToQuestion}
        invokeDeviceEvent={invokeDeviceEvent}
        editTileRawSetting={editTileRawSetting}
        deviceId={deviceId}
        isManageEnabled={isManageEnabled}
        answer={a}
        guideNumber={guideNumber}
        setSmartGuideAnswerOption={setSmartGuideAnswerOption}
      />
    ))}
  </div>
);

SmartGuideQuestion.propTypes = {
  question: PropTypes.object.isRequired,
  deviceId: PropTypes.string,
  isManageEnabled: PropTypes.bool,

  goToQuestion: PropTypes.func.isRequired,
  clearHistory: PropTypes.func.isRequired,
  invokeDeviceEvent: PropTypes.func.isRequired,
  editTileRawSetting: PropTypes.func.isRequired,
  guideNumber: PropTypes.number,
  setSmartGuideAnswerOption: PropTypes.func.isRequired,
};

class SmartGuide extends React.Component {
  static propTypes = {
    expanded: PropTypes.bool,
    deviceId: PropTypes.string,
    smartGuide: PropTypes.object,
    isManageEnabled: PropTypes.bool,
    currentStatus: PropTypes.object,
    systemStatus: PropTypes.object,
    brandId: PropTypes.string,
    userRole: PropTypes.string,

    getSmartGuideQuestions: PropTypes.func.isRequired,
    setSmartGuideAnswerOption: PropTypes.func.isRequired,
    invokeDeviceEvent: PropTypes.func.isRequired,
    editTileRawSetting: PropTypes.func.isRequired,
  };

  state = {
    smartGuideHistory: [],
    loading: false,
  };

  goToQuestion = async guideNumber => {
    const { getSmartGuideQuestions, deviceId } = this.props;
    this.setState({ ...this.state, loading: true });
    await getSmartGuideQuestions(deviceId, guideNumber);

    this.setState({ ...this.state, loading: false, smartGuideHistory: [guideNumber, ...this.state.smartGuideHistory] });
  };

  clearHistory = () => {
    this.setState({ ...this.state, smartGuideHistory: [] });
  };

  back = () => {
    this.setState({ ...this.state, smartGuideHistory: this.state.smartGuideHistory.filter((_, i) => i !== 0) });
  };

  render() {
    const {
      deviceId,
      smartGuide,
      invokeDeviceEvent,
      editTileRawSetting,
      isManageEnabled,
      systemStatus,
      brandId,
      userRole,
      setSmartGuideAnswerOption,
    } = this.props;
    const { loading, smartGuideHistory } = this.state;
    const currentQuestion = smartGuide[deviceId] ? smartGuide[deviceId][smartGuideHistory[0]] : null;

    return (
      <div className="smart-guide">
        {loading && (
          <div className="smart-guide__info-wrapper tile__contentWrapper">
            <Spinner />
          </div>
        )}

        {!loading && (!isManageEnabled || (isManageEnabled && !currentQuestion)) && (
          <SmartGuideWelcomeView
            goToQuestion={this.goToQuestion}
            systemStatus={systemStatus}
            userRole={userRole}
            isManageEnabled={isManageEnabled}
          />
        )}

        {!loading && isManageEnabled && currentQuestion && (
          <SmartGuideQuestion
            question={currentQuestion.payload}
            clearHistory={this.clearHistory}
            goToQuestion={this.goToQuestion}
            invokeDeviceEvent={invokeDeviceEvent}
            editTileRawSetting={editTileRawSetting}
            deviceId={deviceId}
            isManageEnabled={isManageEnabled && userRole !== USER_SECURITY_LEVELS.viewer}
            guideNumber={currentQuestion.payload.guideNumber}
            setSmartGuideAnswerOption={setSmartGuideAnswerOption}
          />
        )}

        {!loading && isManageEnabled && smartGuideHistory.length > 0 && (
          <div className="smart-guide__link">
            <FormattedMessage
              id="smartguide.step.backSmartGuideButton"
              defaultMessage="Back"
              description="Back button on SmartGuide tile"
            >
              {text => (
                <Button onClick={this.back} className="button--default" type="button">
                  {text}
                </Button>
              )}
            </FormattedMessage>
          </div>
        )}

        <div className="smart-guide__image-wrapper tile-icon-wrapper">
          <img src={getTileIcon(TileIcons, brandId, 'smartGuide')} alt="Smart Guide icon" />
        </div>
      </div>
    );
  }
}

export default connect(
  ({ app, smartGuide, features: { premiumFeatures }, systemStatus }) => ({
    smartGuide,
    isManageEnabled: premiumFeatures && premiumFeatures.manage,
    systemStatus,
    userRole: app.selectedSystem && app.selectedSystem.userRole,
    brandId: app.selectedSystem && app.selectedSystem.brandId ? app.selectedSystem.brandId : 'MYUPLINK',
  }),
  { getSmartGuideQuestions, setSmartGuideAnswerOption, invokeDeviceEvent, editTileRawSetting },
)(SmartGuide);
