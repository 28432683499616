import { push } from 'connected-react-router';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Spinner, Title } from 'jpi-cloud-web-ui-components';
import { getDevices } from '../Devices/actions';
import DeviceMenu from './device-menu';
import { menuSystem } from '../../FeaturesFlags/menuSystem';
import { getDeviceFeatures } from '../../FeaturesFlags/actions';
import { USER_SECURITY_LEVELS } from '../SystemProfile/components/SecurityTab/costants';

import './device-settings.scss';

const DevicesList = ({ devices, isManageEnabled, premiumFeatureType }) => (
  <div className="devices-list">
    {devices.map(d => (
      <div key={d.id} className="list-item">
        <DeviceMenu device={d} isManageEnabled={isManageEnabled} premiumFeatureType={premiumFeatureType} />
      </div>
    ))}
  </div>
);

DevicesList.propTypes = {
  devices: PropTypes.arrayOf(PropTypes.object).isRequired,
  isManageEnabled: PropTypes.bool,
  visibleMenus: PropTypes.array.isRequired,
  premiumFeatureType: PropTypes.string,
};

class DeviceSettings extends React.Component {
  static propTypes = {
    selectedSystem: PropTypes.object,
    devices: PropTypes.arrayOf(PropTypes.object).isRequired,
    isManageEnabled: PropTypes.bool,
    getDevices: PropTypes.func.isRequired,
    getDeviceFeatures: PropTypes.func.isRequired,
    goToPage: PropTypes.func.isRequired,
    loadingDeviceFeatures: PropTypes.bool.isRequired,
    menuType: PropTypes.string.isRequired,
  };

  state = {
    selectedDevice: null,
    visibleMenus: [],
    loading: true,
  };

  async componentDidMount() {
    if (this.state.loading) {
      this.setState({ loading: false });
    }
  }

  async componentDidUpdate(prevProps) {
    if (this.state.loading) {
      this.setState({ loading: false });
    }
    if (
      (!prevProps.selectedSystem && !!this.props.selectedSystem) ||
      (prevProps.selectedSystem &&
        this.props.selectedSystem &&
        this.props.selectedSystem.id !== prevProps.selectedSystem.id)
    ) {
      this.setState({ loading: true });
      const devices = await this.props.getDevices(this.props.selectedSystem.id);
      if (devices && devices.length > 0) {
        await this.props.getDeviceFeatures(devices[0].id);
      }
      this.setState({ loading: false });
    }
  }

  render() {
    const isMenuSupported = this.props.menuType !== menuSystem.none;
    const isManageEnabled = this.props.isManageEnabled == undefined ? false : this.props.isManageEnabled;

    const userIsViewer =
      this.props.selectedSystem && this.props.selectedSystem.userRole === USER_SECURITY_LEVELS.viewer;
    const loading = this.state.loading || this.props.devices.loading || this.props.loadingDeviceFeatures;

    if (!loading && !isMenuSupported) {
      this.props.goToPage('/');
      return null;
    }

    return (
      <div className="page-content">
        <div className="device-settings__wrapper row">
          <div className="col-lg-12">
            {loading ? (
              <Spinner dark />
            ) : this.props.devices && this.props.devices.length > 0 ? (
              <Title titleTranslationId="label.system-menu" />
            ) : (
              <h1 align="center">
                <FormattedMessage id="devices.DevicesEmptyList" defaultMessage="Device list is empty" />
              </h1>
            )}
          </div>
        </div>
        <div className="row">
          {!loading && !isManageEnabled && (
            <h3 className="manage-disabled-message">
              <FormattedMessage id="device-settings.manageDisabled" defaultMessage="Premium subscription is required" />
            </h3>
          )}
          {!loading && isMenuSupported && (
            <DevicesList
              devices={this.props.devices}
              isManageEnabled={isManageEnabled && !userIsViewer}
              premiumFeatureType={userIsViewer ? 'permissionAccess' : 'manage'}
              visibleMenus={this.state.visibleMenus}
              updateFirmware={this.updateFirmware}
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ app: { selectedSystem }, features: { premiumFeatures, deviceFeatures }, devices }) => ({
  selectedSystem,
  isManageEnabled: premiumFeatures && premiumFeatures.manage,
  loadingDeviceFeatures: deviceFeatures && deviceFeatures.loading,
  menuType: deviceFeatures && deviceFeatures.menuSystem,
  ...devices,
});

const mapDispatchToProps = {
  getDevices,
  goToPage: push,
  getDeviceFeatures,
};

export default connect(mapStateToProps, mapDispatchToProps)(DeviceSettings);
