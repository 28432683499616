import React from 'react';
import PropTypes from 'prop-types';

import AccountMigrationModal from './AccountMigrationModal';
import { FormattedMessage } from 'react-intl';
import { Button } from 'jpi-cloud-web-ui-components';

import './account-migration-modal.scss';

const AccountMigrationErrorModal = ({ onClose, isOpen }) => {
  return (
    <AccountMigrationModal
      isOpen={isOpen}
      onClose={onClose}
      action={
        <Button onClick={onClose} className="button--secondary account-migration-modal-button-error">
          <FormattedMessage id="button.ok" defaultMessage="Ok" />
        </Button>
      }
    >
      <FormattedMessage
        id="accountMigrationModal.error"
        defaultMessage="Unable to complete data transfer. 
Please try again."
      />
    </AccountMigrationModal>
  );
};

AccountMigrationErrorModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

export default AccountMigrationErrorModal;
