import profileIcon from '../../../assets/img/profile.svg';
import { menuSystem } from '../../FeaturesFlags/menuSystem';

import {
  isSystemEligibleForHidingHistory,
  isSystemEligibleForHidingPremium,
  isSystemHidingStoreMenu,
  selectDevicesFirmwareId,
} from '../../../utils/system';
import { BRANDS_WITH_ECOM, BRANDS_WITH_HIDDEN_PRODUCT_REGISTRATION } from '../../constants/constants';

const isIotStoreEnabled = process.env.IOT_STORE_ENABLED === 'true';

export const generalMenu = [
  {
    name: 'Messages',
    id: 'menu.items.messages',
    path: '/alarms',
  },
  {
    name: 'Software',
    id: 'menu.items.software',
    path: '/update-firmware',
  },
  {
    name: 'Product Registration',
    id: 'menu.items.product-registration',
    path: '/product-registration',
    visible: ({ userCountryName, brandId }) =>
      userCountryName === 'Germany' && !BRANDS_WITH_HIDDEN_PRODUCT_REGISTRATION.includes(brandId?.toLowerCase()),
  },
];

export const systemMenu = [
  {
    name: 'Dashboard',
    id: 'menu.items.dashboard',
    path: '/dashboard',
  },
  {
    name: 'History',
    id: 'menu.items.history',
    path: '/history',
    visible: ({ brandId, firmwareId } = {}) => !isSystemEligibleForHidingHistory(brandId, firmwareId),
  },
  {
    name: 'Profile',
    id: 'menu.items.system-profile',
    path: '/system-profile',
  },
  {
    name: 'Menu',
    id: 'menu.items.device-menu',
    path: '/device-settings',
    visible: ({ hasSystems, menuType }) => !hasSystems || (hasSystems && menuType !== menuSystem.none),
    menuName: ({ menu }) => (menu && menu.name) || '',
  },
  {
    name: 'Devices',
    id: 'menu.items.devices',
    path: '/devices',
  },
];

export const storeMenu = [
  {
    name: 'Redeem voucher',
    id: 'menu.items.redeem-voucher',
    path: '/redeem-voucher',
    visible: ({ isSystemAdmin }) => isSystemAdmin,
    disabled: ({ hasSystems }) => !hasSystems,
  },
  {
    name: 'Premium',
    id: 'menu.items.premium',
    path: '/store',
    disabled: ({ hasSystems }) => !hasSystems,
  },
  {
    name: 'Smart Home Devices',
    id: 'menu.items.iot-store',
    path: '/',
    visible: ({ isUserCountrySweden, brandId }) =>
      isUserCountrySweden && isIotStoreEnabled && BRANDS_WITH_ECOM.includes(brandId?.toLowerCase()),
  },
];

export const profileMenu = [
  {
    name: 'Profile settings',
    id: 'profile-settings.title',
    path: '/profile-settings',
  },
  {
    name: 'Log out',
    id: 'menu.items.logout',
  },
];

export const mainMenu = [
  {
    name: 'General',
    id: 'menu.headers.general',
    align: 'left',
    useIcon: false,
    icon: '',
    children: generalMenu,
  },
  {
    name: 'System',
    id: 'menu.headers.system',
    align: 'left',
    useIcon: false,
    icon: '',
    children: systemMenu,
    disabled: ({ hasSystems }) => !hasSystems,
  },
  {
    name: 'Store',
    id: 'menu.headers.store',
    align: 'left',
    useIcon: false,
    icon: '',
    children: storeMenu,
    hidden: system =>
      system
        ? isSystemEligibleForHidingPremium(system.brandId, selectDevicesFirmwareId(system.devices)) ||
          isSystemHidingStoreMenu(system.brandId)
        : false,
  },
  {
    name: 'Language',
    id: 'menu.headers.language',
    align: 'right',
  },
  {
    name: 'Profile',
    id: 'menu.headers.profile',
    align: 'right',
    useIcon: true,
    icon: profileIcon,
    children: profileMenu,
  },
];
