import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import * as client from '../../../../../../Shop/client';
import Modal from '../../../../../../layout/Modal';
import { Button, Title } from 'jpi-cloud-web-ui-components';

import './resumesubscription.scss';
import { connect } from 'react-redux';

const ResumeSubscription = ({
  resumeSubscription,
  setResumeSubscription,
  subscriptionId,
  resumeSubscriptionModel,
  deviceId,
}) => {
  const confirmResumeSubscription = async () => {
    await resumeSubscription(subscriptionId, deviceId);
    setResumeSubscription(!resumeSubscriptionModel);
  };

  return (
    <Modal className="checkout-modal-dialog" show={resumeSubscriptionModel}>
      <div className="resumeSubscription">
        <div className="popup-modal">
          <div className="resumeSubscription_wrapper">
            <div className="resumeSubscription__title">
              <Title
                className="subscriptionTitle"
                titleTranslationId="shop.page.resumeSubscription"
                defaultMessage="Resume Subscription"
              />
            </div>
            <div className="resumeSubscription__description">
              <FormattedMessage
                id="shop.page.aboutToResumeSubscription"
                defaultMessage="Are you sure you want to resume your subscription to myUplink"
              />
            </div>
            <div className="resumeSubscription__button-wrapper">
              <Button className="resumeSubscription__resumeSubscription-button" onClick={confirmResumeSubscription}>
                <FormattedMessage id="button.resumeSubscription" defaultMessage="Resume Subscription" />
              </Button>
              <Button
                className="resumeSubscription__back-button"
                onClick={() => setResumeSubscription(!resumeSubscriptionModel)}
              >
                <FormattedMessage id="button.cancel" defaultMessage="Cancel" />
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

ResumeSubscription.propTypes = {
  dispatch: PropTypes.func.isRequired,
  setResumeSubscription: PropTypes.func.isRequired,
  resumeSubscriptionModel: PropTypes.bool.isRequired,
  resumeSubscription: PropTypes.func.isRequired,
  subscriptionId: PropTypes.string.isRequired,
  deviceId: PropTypes.string.isRequired,
};

const mapDispatchToProps = dispatch => ({
  dispatch,
  resumeSubscription(id, deviceId) {
    dispatch(client.resumeSubscription(id, deviceId));
  },
});

export default connect(null, mapDispatchToProps)(ResumeSubscription);
