import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import LanguageSelector from '../../../../../LanguageSelector';
import MenuContext from '../../../../MenuContext';
import HamburgerSubMenuItem from '../HamburgerSubMenuItem/HamburgerSubMenuItem';
import { freeBrand } from '../../../../../../constants/constants';
import './hamburger-sub-menu.scss';

export const HamburgerSubMenu = ({ id, name, children, redirectIotStore, selectedSystem, disabled, logoutUser }) => {
  if (id === 'menu.headers.language') {
    return (
      <div className="hamburgerMenuItem--languageSelector hamburgerMenuItem">
        <div className="hamburgerMenuItem__menuItemList">
          <div className="hamburgerMenuItem__menuItemHeading">
            <FormattedMessage id={id} defaultMessage={name} />
          </div>
          <div className="hamburgerMenuItem__menuItem">
            <LanguageSelector />
          </div>
        </div>
      </div>
    );
  }
  return (
    <MenuContext.Consumer>
      {context => {
        return (
          !(selectedSystem && selectedSystem.brandId.toLowerCase() === freeBrand && name === 'Store') && (
            <div className="hamburgerMenuItem">
              <div className="hamburgerMenuItem__menuItemList">
                <div className="hamburgerMenuItem__menuItemHeading">
                  {id === 'menu.headers.system' && context.deviceUpdateAvailable && (
                    <span className="badge badge-danger">!</span>
                  )}
                  <FormattedMessage id={id} defaultMessage={name} />
                </div>
                {children
                  .map(item => ({
                    ...item,
                    disabled: disabled && disabled(context) ? true : item.disabled && item.disabled(context),
                  }))
                  .filter(item => !item.visible || item.visible(context))
                  .map(
                    item =>
                      !(
                        selectedSystem &&
                        selectedSystem.brandId.toLowerCase() === freeBrand &&
                        item.name === 'Premium'
                      ) && (
                        <HamburgerSubMenuItem
                          key={item.id}
                          menuItem={item}
                          redirectIotStore={redirectIotStore}
                          selectedSystem={selectedSystem}
                          logoutUser={logoutUser}
                        />
                      ),
                  )}
              </div>
            </div>
          )
        );
      }}
    </MenuContext.Consumer>
  );
};

HamburgerSubMenu.propTypes = {
  name: PropTypes.string,
  id: PropTypes.string,
  onLinkClick: PropTypes.func,
  children: PropTypes.any,
  disabled: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  redirectIotStore: PropTypes.func,
  selectedSystem: PropTypes.object,
  logoutUser: PropTypes.func,
};
