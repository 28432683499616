import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Button, Input, Checkbox } from 'jpi-cloud-web-ui-components';
import { USER_SECURITY_LEVELS } from '../costants';
import { InputDropdown } from '../../../../../inputs/Dropdown';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Formik } from 'formik';
import * as yup from 'yup';
import { formatErrorMessage } from '../../../../../../localization/message-formatting';

const schema = yup.object().shape({
  email: yup.string().email('email.email').trim().required('email.required'),
  securityLevel: yup.object().required('security-level.required'),
  HP24DataConsent: yup.bool().when('securityLevel', {
    is: val => val?.value === USER_SECURITY_LEVELS.hp24installer,
    then: yup.bool().required('HP24DataConsent.required').oneOf([true], 'HP24DataConsent.required'),
    otherwise: yup.bool(),
  }),
});

const errorMessages = {
  'email.email': {
    id: 'email.error.validation.email',
    defaultMessage: 'E-mail is not valid',
  },
  'email.required': {
    id: 'email.error.validation.required',
    defaultMessage: 'E-mail is mandatory field',
  },
  'security-level.required': {
    id: 'system-profile.security.error.validation.security-level.required',
    defaultMessage: 'Security level is mandatory field',
  },
  'HP24DataConsent.required': {
    id: 'security.error.validation.HP24DataConsent.required',
    defaultMessage: 'You must accept the Data Consent',
  },
};

const AddUserToSystemForm = ({ onSubmit, userConnectLoading, intl, isDemo, userRoles, userRolesLoading }) => {
  const formikRef = useRef();

  useEffect(() => {
    if (userRolesLoading) {
      formikRef.current?.setFieldValue('securityLevel', '');
    }
  }, [userRolesLoading]);

  return (
    <Formik
      innerRef={formikRef}
      initialValues={{
        email: '',
        securityLevel: '',
        HP24DataConsent: false,
      }}
      validationSchema={schema}
      onSubmit={onSubmit}
    >
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
        <div>
          <form onSubmit={handleSubmit} className="form--half">
            <Input
              placeholder={intl.formatMessage({ id: 'security.EmailPlaceholder' })}
              type="email"
              name="email"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              disabled={isDemo}
              error={errors.email && touched.email && formatErrorMessage(intl, errorMessages, errors.email)}
            />
            <InputDropdown
              id={Date.now().toString(36) + Math.random().toString(36).slice(2) + 'securityLevel'}
              name="securityLevel"
              items={userRoles}
              placeholder={intl.formatMessage({ id: 'security.SecurityLevel', defaultMessage: 'Security Level' })}
              selectedItem={values.securityLevel}
              error={
                errors.securityLevel &&
                touched.securityLevel &&
                formatErrorMessage(intl, errorMessages, errors.securityLevel)
              }
              disabled={isDemo || userRolesLoading}
              labelGetter={e => intl.formatMessage(e.text)}
              onSelect={i => setFieldValue('securityLevel', i)}
            />
            {values.securityLevel?.value === USER_SECURITY_LEVELS.hp24installer && (
              <Checkbox
                id="HP24DataConsent"
                name="HP24DataConsent"
                onChange={handleChange}
                checked={values.HP24DataConsent}
                aria-required="true"
                label={
                  <FormattedMessage
                    id="security.HP24DataConsent"
                    defaultMessage="By sharing access to a heatpump24 account, you give your approval to the heatpump24 account to monitor and make changes to your system. myUpTech AB takes no responsibility whatsoever for the heatpump24 account's actions or failure to act in this respect and you are not entitled to make any claims against myUpTech AB for the heatpump24 account’s actions or failures to act. The heatpump24 solution is managed by ait-deutschland GmbH."
                  />
                }
                onLabelClick={() => setFieldValue('HP24DataConsent', !values.HP24DataConsent)}
                error={
                  errors.HP24DataConsent &&
                  touched.HP24DataConsent &&
                  formatErrorMessage(intl, errorMessages, errors.HP24DataConsent)
                }
              />
            )}
            <div className="button-wrapper">
              <Button className="button--secondary" type="submit" disabled={userConnectLoading || isDemo}>
                <FormattedMessage id="security.Invite" defaultMessage="Invite" />
              </Button>
            </div>
          </form>
        </div>
      )}
    </Formik>
  );
};

AddUserToSystemForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  userConnectLoading: PropTypes.bool,
  intl: PropTypes.object,
  isDemo: PropTypes.bool,
  userRoles: PropTypes.array.isRequired,
  userRolesLoading: PropTypes.bool,
};

export default injectIntl(AddUserToSystemForm);
