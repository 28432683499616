import * as api from '../../../api';

export const GET_TILE_SETTINGS_REQUEST = 'tileSettings/GET_TILE_SETTINGS_REQUEST';
export const GET_TILE_SETTINGS_RESPONSE = 'tileSettings/GET_TILE_SETTINGS_RESPONSE';
export const CLEAN_TILE_SETTINGS = 'tileSettings/CLEAN_TILE_SETTINGS';

export const getTileSettings =
  ({ deviceId, parameterIds, silent }) =>
  async dispatch => {
    if (!silent) dispatch({ type: GET_TILE_SETTINGS_REQUEST });

    const { status, data } = await api.getDeviceParameterValue(deviceId, parameterIds);
    if (status === 200) {
      dispatch({ type: GET_TILE_SETTINGS_RESPONSE, settings: data });
    }

    // TODO: Process errors!!!
  };

export const cleanTileSettings = () => dispatch => {
  dispatch({ type: CLEAN_TILE_SETTINGS });
};
