import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';

const Price = ({ currency, locale, price }) => {
  const formattedPrice = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currency,
  }).format(price);
  return formattedPrice;
};

Price.propTypes = {
  locale: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
  price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

const mapStateToProps = state => ({
  locale: state.language.selectedLanguage,
});

const withConnect = connect(mapStateToProps, undefined);

export default compose(withConnect)(Price);
