import { GET_TILE_SETTINGS_REQUEST, GET_TILE_SETTINGS_RESPONSE, CLEAN_TILE_SETTINGS } from './actions';

import { EDIT_TILE_SETTING } from '../TileManager/actions';

const initialState = {
  loading: false,
  settingValues: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case EDIT_TILE_SETTING: {
      const newState = {
        ...state,
        settingValues: state.settingValues.map(sv =>
          sv.parameterId !== action.parameterId
            ? sv
            : {
                ...sv,
                value: action.value,
                pendingValue: action.value,
              },
        ),
      };
      return newState;
    }
    case GET_TILE_SETTINGS_REQUEST:
      return { ...state, loading: true };
    case GET_TILE_SETTINGS_RESPONSE:
      return { ...state, loading: false, settingValues: action.settings };
    case CLEAN_TILE_SETTINGS:
      return { ...initialState };
    default:
      return state;
  }
};
