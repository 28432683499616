// FIXME: Make generic mechanism for storing charts
const HISTORY_CHART_STORE_KEYS = {
  first: 'HISTORY_CHART[1]',
  second: 'HISTORY_CHART[2]',
};

// TODO: Move this whole file under services
export const getFirstChartData = () => {
  const allCharts = localStorage.getItem(HISTORY_CHART_STORE_KEYS.first);
  return JSON.parse(allCharts);
};

export const setFirstChartData = charts => {
  localStorage.setItem(HISTORY_CHART_STORE_KEYS.first, JSON.stringify(charts));
};

export const removeFirstChart = () => localStorage.removeItem(HISTORY_CHART_STORE_KEYS.first);

export const getSecondChartData = () => {
  const allCharts = localStorage.getItem(HISTORY_CHART_STORE_KEYS.second);
  return JSON.parse(allCharts);
};

export const setSecondChartData = charts => {
  localStorage.setItem(HISTORY_CHART_STORE_KEYS.second, JSON.stringify(charts));
};

export const removeSecondChart = () => localStorage.removeItem(HISTORY_CHART_STORE_KEYS.second);

export const hasSecondChart = () => HISTORY_CHART_STORE_KEYS.second in localStorage;
