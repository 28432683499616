export const getValueFromLocalStorage = key => {
  try {
    const value = localStorage.getItem(key);
    if (value !== null) {
      return JSON.parse(value);
    }
    return null;
  } catch (error) {
    return null;
  }
};

export const setValueInLocalStorage = (key, value) => {
  if (value !== null) {
    localStorage.setItem(key, JSON.stringify(value));
  }
};

export const deleteValueFromLocalStorage = key => {
  localStorage.removeItem(key);
};
