import moment from 'moment';
import {
  GET_USER_SUBSCRIPTIONS_RESPONSE,
  GET_DEVICE_AVAILABLE_FEATURES_RESPONSE,
  GET_DEVICE_AVAILABLE_FEATURES_FAILED,
  GET_USER_SUBSCRIPTIONS_FAILED,
  GET_DEVICE_AVAILABLE_FEATURES_REQUEST,
} from './actions';
import { menuSystem } from './menuSystem';

const featuresTitles = {
  history: 'history',
  manage: 'manage',
  organization: 'organization',
};

const initialPremiumFeatures = {
  history: false,
  manage: false,
  organization: false,
};

const initialDeviceFeatures = {
  loading: false,
  menuSystem: menuSystem.none,
  menu: {
    name: '',
    system: menuSystem.none,
  },
  resetAlarm: false,
  smartMode: false,
  aidMode: false,
  smartGuide: false,
  scheduling: {
    scheduleOverride: false,
    scheduleWeekly: false,
    scheduleVacation: false,
  },
  zones: false,
  boost: {
    hotWater: false,
    ventilation: false,
  },
};

const initialState = {
  premiumFeatures: initialPremiumFeatures,
  deviceFeatures: initialDeviceFeatures,
  isDashboardFree: false,
};

const saveSubscriptions = (state, subscriptions) => ({
  ...state,
  isDashboardFree: subscriptions.isDashboardFree,
  premiumFeatures: {
    ...Object.keys(featuresTitles).reduce((acc, f) => {
      acc[f] = subscriptions?.subscriptions?.some(
        s =>
          s.internalTitle.toLowerCase().includes(featuresTitles[f]) &&
          ((moment(s.validUntil).isAfter(moment()) && s.valid) || s.isFree),
      );
      return acc;
    }, {}),
  },
});

const saveDeviceFeatures = (state, deviceFeatures) => ({
  ...state,
  deviceFeatures: {
    ...deviceFeatures,
    loading: false,
  },
});

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_USER_SUBSCRIPTIONS_RESPONSE:
      return saveSubscriptions(state, action.subscriptions);
    case GET_USER_SUBSCRIPTIONS_FAILED:
      return { ...state, premiumFeatures: initialPremiumFeatures };
    case GET_DEVICE_AVAILABLE_FEATURES_REQUEST:
      return {
        ...state,
        deviceFeatures: {
          ...state.deviceFeatures,
          loading: true,
        },
      };
    case GET_DEVICE_AVAILABLE_FEATURES_RESPONSE:
      return saveDeviceFeatures(state, action.deviceFeatures);
    case GET_DEVICE_AVAILABLE_FEATURES_FAILED:
      return saveDeviceFeatures(state, initialDeviceFeatures);
    default:
      return state;
  }
};
