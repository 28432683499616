import { Spinner } from 'jpi-cloud-web-ui-components';
import PropTypes from 'prop-types';
import React from 'react';
import Spacer from '../../../layout/Spacer';

const DeviceMenuItemHeader = ({ title, isLoaderShown }) => {
  return (
    <>
      <div className="device-menu-item-header">
        <span>{title}</span>
        {isLoaderShown && <Spinner size={2} dark />}
      </div>
      <Spacer y={15} />
    </>
  );
};

DeviceMenuItemHeader.propTypes = {
  title: PropTypes.string.isRequired,
  isLoaderShown: PropTypes.bool,
};

export default DeviceMenuItemHeader;
