import * as api from '../../../../../api';

export const GET_THIRD_PARTY_APPS_REQUEST = 'profile/GET_THIRD_PARTY_APPS_REQUEST';
export const GET_THIRD_PARTY_APPS_RESPONSE_SUCCEEDED = 'profile/GET_THIRD_PARTY_APPS_RESPONSE_SUCCEEDED';
export const GET_THIRD_PARTY_APPS_RESPONSE_FAILED = 'profile/GET_THIRD_PARTY_APPS_RESPONSE_FAILED';
export const REMOVE_THIRD_PARTY_APP_ACCESS_SUCCEEDED = 'profile/REMOVE_THIRD_PARTY_APP_ACCESS_SUCCEEDED';
export const REMOVE_THIRD_PARTY_APP_ACCESS_FAILED = 'profile/REMOVE_THIRD_PARTY_APP_ACCESS_FAILED';

export const getThirdPartyApps = userId => async dispatch => {
  dispatch({ type: GET_THIRD_PARTY_APPS_REQUEST });

  try {
    const response = await api.getThirdPartyApps(userId);
    if (response.status === 200) {
      dispatch({ type: GET_THIRD_PARTY_APPS_RESPONSE_SUCCEEDED, data: response.data });
      return true;
    }
  } catch (error) {
    dispatch({ type: GET_THIRD_PARTY_APPS_RESPONSE_FAILED });
    return false;
  }

  dispatch({ type: GET_THIRD_PARTY_APPS_RESPONSE_FAILED });
  return false;
};

export const removeAccessForThirdPartyApp = (userId, clientId) => async dispatch => {
  try {
    const response = await api.removeThirdPartyAppAccess(userId, clientId);
    if (response.status === 204) {
      dispatch({ type: REMOVE_THIRD_PARTY_APP_ACCESS_SUCCEEDED, id: clientId });
      return true;
    }
  } catch (error) {
    dispatch({ type: REMOVE_THIRD_PARTY_APP_ACCESS_FAILED });
    return false;
  }

  dispatch({ type: REMOVE_THIRD_PARTY_APP_ACCESS_FAILED });
  return false;
};
