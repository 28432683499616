import {
  GET_DEVICE_MENU_RESPONSE,
  EDIT_DEVICE_MENU_RAW_SETTING,
  EDIT_DEVICE_MENU_RAW_SETTING_COMPLETE,
} from './actions';

const initialState = {
  updateSettingsProgressCounter: 0,
};

const saveDeviceMenu = (state, deviceId, menuId, menu) => {
  if (state[deviceId]) {
    return { ...state, [deviceId]: { ...state[deviceId], [menuId]: menu } };
  }

  return { ...state, [deviceId]: { [menuId]: menu } };
};

const editDeviceMenuRaw = (state, action) => {
  const needleRowIndex = state[action.deviceId][action.menuId].rows.findIndex(
    r => r.parameterId === action.parameterId,
  );
  const newRows = [...state[action.deviceId][action.menuId].rows];
  if (needleRowIndex > -1) {
    newRows[needleRowIndex] = {
      ...newRows[needleRowIndex],
      value: {
        ...newRows[needleRowIndex].value,
        integerValue: action.value,
        stringValue: action.value,
      },
    };
  }

  const newState = {
    ...state,
    updateSettingsProgressCounter: state.updateSettingsProgressCounter + 1,
    [action.deviceId]: {
      ...state[action.deviceId],
      [action.menuId]: {
        ...state[action.deviceId][action.menuId],
        rows: newRows,
      },
    },
  };

  return newState;
};

export default (state = initialState, action) => {
  switch (action.type) {
    case EDIT_DEVICE_MENU_RAW_SETTING_COMPLETE:
      return {
        ...state,
        updateSettingsProgressCounter:
          state.updateSettingsProgressCounter > 0 ? state.updateSettingsProgressCounter - 1 : 0,
      };
    case EDIT_DEVICE_MENU_RAW_SETTING:
      return editDeviceMenuRaw(state, action);
    case GET_DEVICE_MENU_RESPONSE:
      if (state.updateSettingsProgressCounter) {
        return { ...state };
      }
      return saveDeviceMenu(state, action.deviceId, action.id, action.menu);
    default:
      return state;
  }
};
