import * as api from '../../../../../api';

export const DISCONNECT_USER_REQUEST = 'system-profile/DISCONNECT_USER_REQUEST';
export const DISCONNECT_USER_RESPONSE_SUCCEEDED = 'system-profile/DISCONNECT_USER_RESPONSE_SUCCEEDED';
export const DISCONNECT_USER_RESPONSE_FAILED = 'system-profile/DISCONNECT_USER_RESPONSE_FAILED';
export const disconnectUser = (system, user) => async dispatch => {
  dispatch({ type: DISCONNECT_USER_REQUEST });
  try {
    const response = await api.disconnectUser(system.id, user.id);

    if (response.status === 204) {
      dispatch({ type: DISCONNECT_USER_RESPONSE_SUCCEEDED });
      return true;
    }
    dispatch({ type: DISCONNECT_USER_RESPONSE_FAILED });
  } catch {
    dispatch({ type: DISCONNECT_USER_RESPONSE_FAILED });
  }

  return false;
};
