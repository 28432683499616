import { validateOldUserCredentials } from '.';
import { API } from './client';

export const cancelMigrationNotification = async () => {
  const response = API.delete('/v2/users/profile/migration-notification');
  return response.data;
};

export const validateUser = async ({ password, email }) => {
  const userValidationResponse = await validateOldUserCredentials(email, password);
  return userValidationResponse.data.id;
};

export const migrateUser = async ({ oldUserId }) => {
  const migrationResponse = await API.put(`/v2/old-users/migrate/merge?&oldUserId=${oldUserId}`);
  return migrationResponse.data;
};

export default {
  cancelMigrationNotification,
  migrateUser,
};
