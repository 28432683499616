import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { addDays } from 'date-fns';
import moment from 'moment';

import { Button, Spinner, Title } from 'jpi-cloud-web-ui-components';

import { Wizard, WizardSteps } from '../../../../../components/layout/Wizard';
import Modal from '../../../../../components/layout/Modal';

import SelectMode from '../EditModesPopup/SelectMode';

import VacationTimeSelector from './VacationTimeSelector';
import InvalidEndDatePopup from './InvalidEndDatePopup';
import VacationForm from './VacationForm';
import { isCountryUseAmPmHours } from '../../../../../api/countries';

import './vacation-popup.scss';

const defaultTimeRange = { start: '00:00', end: '00:00' };

const getDefaultDateRange = () => ({
  startDate: addDays(new Date(), 1),
  endDate: addDays(new Date(), 1),
  key: 'selectionRange',
});

// TODO: Add TS
function VacationPopup({
  modes,
  devices,
  isPopupOpen,
  scheduleConfig = {},
  vacationSchedules = [],
  selectedSystem,
  selectedLanguage,
  weekStartsOnIndex,
  editableVacation,
  userIsViewer,
  getScheduleConfig,
  setVacationSchedules,
  closePopup,
}) {
  const [loading, setLoading] = useState(false);
  const [invalidEndDate, setInvalidEndDate] = useState(false);

  const [mode, setMode] = useState(null);
  const [details, setDetails] = useState({ name: '', isEnabled: true });
  const [selectedDateRange, setDateRange] = useState(getDefaultDateRange());
  const [selectedTimeRange, setTimeRange] = useState(defaultTimeRange);

  const [mainDevice = {}] = devices;
  const { vacationTimeAvailable } = scheduleConfig;

  useEffect(() => {
    loadVacations();
  }, []);

  const loadVacations = async () => {
    if (editableVacation) {
      const editableVacationStart = moment(editableVacation.start);
      const editableVacationEnd = moment(editableVacation.end);

      setDetails({
        name: editableVacation.name,
        isEnabled: editableVacation.isEnabled,
      });

      setDateRange({
        startDate: editableVacationStart.toDate(),
        endDate: editableVacationEnd.toDate(),
        key: 'selectionRange',
      });

      setTimeRange({
        start: editableVacationStart.format('HH:mm'),
        end: editableVacationEnd.format('HH:mm'),
      });

      setMode(modes.find(m => m.modeId === editableVacation.modeId));
    }

    setLoading(true);

    await getScheduleConfig(mainDevice.id);

    setLoading(false);
  };

  const handleTimeChange = (range, time) => {
    setTimeRange({ ...selectedTimeRange, [range]: time });
  };

  const resetSelectedMode = () => {
    setDateRange(getDefaultDateRange());
    setTimeRange(defaultTimeRange);
    setMode(null);
  };

  const closeEndDatePopup = () => {
    setInvalidEndDate(false);
    closePopup();
  };

  const saveVacation = async () => {
    const start = moment(selectedDateRange.startDate)
      .set({
        hour: moment(selectedTimeRange.start, 'HH:mm').format('HH'),
        minute: moment(selectedTimeRange.start, 'HH:mm').format('mm'),
        second: 0,
        millisecond: 0,
      })
      .toISOString();

    const end = moment(selectedDateRange.endDate)
      .set({
        hour: vacationTimeAvailable ? moment(selectedTimeRange.end, 'HH:mm').format('HH') : 23,
        minute: vacationTimeAvailable ? moment(selectedTimeRange.end, 'HH:mm').format('mm') : 59,
        second: vacationTimeAvailable ? 0 : 59,
        millisecond: 0,
      })
      .toISOString();

    const vacation = {
      modeId: mode.modeId,
      ...details,
      start,
      end,
    };

    const momentEnd = moment(vacation.end);

    if (momentEnd.isBefore(moment()) || momentEnd.isSame(moment())) return setInvalidEndDate(true);

    const vacations = editableVacation
      ? vacationSchedules.map(schedule =>
          moment(schedule.start).isSame(moment(editableVacation.start), 'day') ? vacation : schedule,
        )
      : [vacation, ...vacationSchedules];

    setLoading(true);

    await setVacationSchedules(mainDevice.id, vacations);

    setLoading(false);
    resetSelectedMode();
    closePopup();
  };

  const deleteVacation = async () => {
    const vacations = vacationSchedules.filter(schedule => schedule !== editableVacation);

    // FIXME: Use DELETE request for this, not PUT
    await setVacationSchedules(mainDevice.id, vacations);
    resetSelectedMode();
    closePopup();

    await loadVacations();
  };

  const disabledDateRanges = editableVacation
    ? vacationSchedules.filter(vs => !moment(vs.start).isSame(moment(editableVacation.start), 'day'))
    : vacationSchedules;

  const editableDateRangeProps = editableVacation
    ? {
        onDeleteVacation: deleteVacation,
        showDeleteBtn: true,
      }
    : {};

  return (
    <Modal show={isPopupOpen} backdrop="static">
      <div className="mode-popup">
        <Title
          titleTranslationId={`scheduling.title.${editableVacation ? 'edit' : 'create'}-vacation`}
          defaultMessage={`${editableVacation ? 'Edit' : 'Create'} vacation`}
        />
        <Button className="button--cancel" type="button" onClick={closePopup}>
          <i className="fas fa-times" />
        </Button>
        {loading ? (
          <div role="progressbar">
            <Spinner dark />
          </div>
        ) : invalidEndDate ? (
          <InvalidEndDatePopup endDateHandler={closeEndDatePopup} />
        ) : (
          <Wizard stepsCount={vacationTimeAvailable ? 3 : 2}>
            {({ currentStep, goNext, goPrevious }) => {
              const vForm = (
                <VacationForm
                  vacationDetails={details}
                  disabledDateRanges={disabledDateRanges}
                  selectedDateRange={selectedDateRange}
                  selectedLanguage={selectedLanguage}
                  weekStartsOnIndex={weekStartsOnIndex}
                  scheduleConfig={scheduleConfig}
                  onRangeChange={setDateRange}
                  onDetailsChange={setDetails}
                  goPrevious={closePopup}
                  goNext={goNext}
                  {...editableDateRangeProps}
                />
              );
              const tSelector = (
                <VacationTimeSelector
                  isAmPmHours={isCountryUseAmPmHours(selectedSystem?.address?.country?.countryCode2Alpha)}
                  selectedTimeRange={selectedTimeRange}
                  selectedDateRange={selectedDateRange}
                  setTime={handleTimeChange}
                  goPrevious={goPrevious}
                  goNext={goNext}
                />
              );
              const mSelector = (
                <SelectMode
                  modes={modes}
                  selectedMode={mode}
                  nextButtonLabel={`scheduling.title.${editableVacation ? 'save' : 'create'}`}
                  scheduleConfig={scheduleConfig}
                  userIsViewer={userIsViewer}
                  modeSelectorEnabled
                  isVacation
                  onSelectedChanged={setMode}
                  resetSelectedMode={resetSelectedMode}
                  goPrevious={goPrevious}
                  goNext={saveVacation}
                />
              );
              const steps = [vForm, vacationTimeAvailable && tSelector, mSelector].filter(Boolean);

              return (
                <WizardSteps currentStep={currentStep}>
                  {steps.map((step, i) => (
                    <div className="vacation-schedule-step" key={i}>
                      {step}
                    </div>
                  ))}
                </WizardSteps>
              );
            }}
          </Wizard>
        )}
      </div>
    </Modal>
  );
}

VacationPopup.propTypes = {
  isPopupOpen: PropTypes.bool,
  closePopup: PropTypes.func.isRequired,
  setVacationSchedules: PropTypes.func.isRequired,
  getScheduleConfig: PropTypes.func.isRequired,
  userIsViewer: PropTypes.bool.isRequired,
  scheduleConfig: PropTypes.object.isRequired,
  selectedSystem: PropTypes.object.isRequired,
  vacationSchedules: PropTypes.array.isRequired,
  selectedLanguage: PropTypes.string.isRequired,
  weekStartsOnIndex: PropTypes.number.isRequired,
  devices: PropTypes.array.isRequired,
  modes: PropTypes.array.isRequired,
  editableVacation: PropTypes.object,
};

export default VacationPopup;
