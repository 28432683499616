import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Price from '../../../../../../Shop/components/Price';

import './productdetails.scss';

const productDetails = ({ order }) => {
  return (
    <div className="ProductDetails__productWrapper">
      <div className="ProductDetails__subscription-wrapper">
        {order.data.orderDetails.lineItems.map(product => (
          <div className="ProductDetails__productMapping" key={product.id}>
            <div className="ProductDetails__partition" />
            <div className="ProductDetails__content-wrapper">
              <div className="ProductDetails__product-heading">
                <FormattedMessage
                  id={'product.' + product.product.title + '.title'}
                  defaultMessage={product.product.title}
                />
              </div>
              <div className="ProductDetails__taxAndSubtotal">
                <div className="ProductDetails__subtotal-wrapper">
                  <p className="ProductDetails__subtotal-heading">
                    <FormattedMessage id="shop.subtotal" defaultMessage="Subtotal" />
                    &#58;
                  </p>
                  <p className="ProductDetails__tax-heading">
                    <FormattedMessage id="shop.tax" defaultMessage="Tax" />
                    &#58;
                  </p>
                </div>
                <div className="ProductDetails__tax-wrapper">
                  <p className="ProductDetails__subtotal-price">
                    <Price currency={order.data.currency} price={product.price} />
                  </p>
                  <p className="ProductDetails__tax-price">
                    <Price currency={order.data.currency} price={product.tax[0] ? product.tax[0].price : '0.00'} />
                  </p>
                </div>
              </div>
            </div>
          </div>
        ))}
        <div className="ProductDetails__partition" />
        <div className="ProductDetails__shippingAndTotal">
          <div className="ProductDetails__product-heading responsive-heading"></div>
          <div className="ProductDetails__content-wrapper responsive-shippingAndTotalWrapper">
            <div className="ProductDetails__key-wrapper">
              <p className="ProductDetails__shipping-heading">
                <FormattedMessage id="shop.shipping" defaultMessage="Shipping" />
                &#58;
              </p>
              <p className="ProductDetails__total-price-heading">
                <FormattedMessage id="shop.total" defaultMessage="Total" />
                &#58;
              </p>
            </div>
            <div className="ProductDetails__value-wrapper">
              <p className="ProductDetails__shipping-price">
                <Price currency={order.data.currency} price={'0.00'} />
              </p>
              <p className="ProductDetails__total-price">
                <Price currency={order.data.currency} price={order.data.orderDetails.totalPrice} />
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

productDetails.propTypes = {
  order: PropTypes.object.isRequired,
};
export default productDetails;
