import {
  GET_THIRD_PARTY_APPS_REQUEST,
  GET_THIRD_PARTY_APPS_RESPONSE_SUCCEEDED,
  GET_THIRD_PARTY_APPS_RESPONSE_FAILED,
  REMOVE_THIRD_PARTY_APP_ACCESS_SUCCEEDED,
} from './actions';

const initialState = {
  thirdPartyApps: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_THIRD_PARTY_APPS_REQUEST:
    case GET_THIRD_PARTY_APPS_RESPONSE_FAILED:
      return { ...state, thirdPartyApps: [] };
    case GET_THIRD_PARTY_APPS_RESPONSE_SUCCEEDED:
      return { ...state, thirdPartyApps: action.data };
    case REMOVE_THIRD_PARTY_APP_ACCESS_SUCCEEDED:
      return { ...state, thirdPartyApps: state.thirdPartyApps.filter(app => app.clientId !== action.id) };
    default:
      return state;
  }
};
