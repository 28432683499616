import * as api from '../../../api';
import * as lastUsedLanguage from '../../../api/language';
import * as tileApi from '../../../api/tiles';
import { timeFormatDefaultLocale } from 'd3-time-format';
import { reloadTranslations } from '../../../text-db/actions';
import { getCultureByLanguage } from '../../../localization/cultures';
import moment from 'moment';

export const LANGUAGE_SELECTED = 'language/LANGUAGE_SELECTED';
export const languageSelected = (language, userInfo) => async dispatch => {
  const culture = getCultureByLanguage(language);

  timeFormatDefaultLocale(culture.d3TimeLocale);
  moment.locale(culture.momentLocale);

  if (userInfo?.id && !userInfo.isDemo) {
    await api.updateUserLanguage(userInfo, culture.language);
  }

  dispatch(reloadTranslations(culture.language));

  dispatch({
    type: LANGUAGE_SELECTED,
    selectedLanguage: culture.language,
    languageTag: culture.languageTag,
  });
};

export const SET_LAST_USED_LANGUAGE_REQUEST = 'language/SET_LAST_USED_LANGUAGE_REQUEST';
export const setLastUsedLanguage = language => dispatch => {
  tileApi.resetAllTilesLastRefreshed();
  const culture = getCultureByLanguage(language);

  dispatch({ type: SET_LAST_USED_LANGUAGE_REQUEST });
  lastUsedLanguage.set(culture.language);
};
