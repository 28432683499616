import * as api from '../../../api';

export const RECOVERY_LINK_SENT = 'forgotPassword/RECOVERY_LINK_SENT';
export const RECOVERY_LINK_NO_SUCH_EMAIL = 'forgotPassword/RECOVERY_LINK_NO_SUCH_EMAIL';
export const RECOVERY_LINK_SEND_FAILED = 'forgotPassword/RECOVERY_LINK_SEND_FAILED';

export const sendRecoveryLink = email => async dispatch => {
  try {
    var result = await api.sendRecoveryLink(email);
    dispatch({ type: RECOVERY_LINK_SENT });
    return result;
  } catch (error) {
    if (!error.response) {
      dispatch({ type: RECOVERY_LINK_SEND_FAILED });
      return false;
    }
    switch (error.response.status) {
      case 404: {
        dispatch({ type: RECOVERY_LINK_NO_SUCH_EMAIL });
        break;
      }
      default:
        dispatch({ type: RECOVERY_LINK_SEND_FAILED });
    }
  }
};

export const verifyRecoveryToken = token => () => api.verifyRecoveryToken(token);
export const resetPassword = (token, password) => () => api.resetPassword(token, password);
