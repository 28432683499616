import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import infoCircle from '../../../../../../assets/img/info-circle.svg';

import '../account-migration-info-modal.scss';

const AccountMigrationInfoHeader = ({ translationObject }) => (
  <div className="account-migration-info-modal-header">
    <img src={infoCircle} className="icon" alt="Migration Info Banner" />

    <FormattedMessage
      id={`accountMigrationInfoModal${translationObject.key}.header`}
      defaultMessage={`${translationObject.value} Data Transfer`}
    />
  </div>
);

AccountMigrationInfoHeader.propTypes = {
  translationObject: PropTypes.object,
};

export default AccountMigrationInfoHeader;
