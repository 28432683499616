import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DeleteConfirmationForm from './components/DeleteConfirmationForm/DeleteConfirmationForm';
import Spacer from '../../layout/Spacer';
import { FormattedMessage } from 'react-intl';

const DeleteConfirmation = ({ history, userLoggedIn }) => {
  const searchParams = new URLSearchParams(window.location.search);
  const token = searchParams.get('token');

  useEffect(() => {
    if (userLoggedIn && token) {
      history.push(`/profile-settings?token=${token}`);
    } else if (!token) {
      history.push('/login');
    }
  }, [history, userLoggedIn, token]);

  return (
    <section>
      <h1 className="form__heading">
        <FormattedMessage id="label.system-profile.delete-account-title" defaultMessage="Delete personal account" />
      </h1>
      <Spacer y={16} />
      <DeleteConfirmationForm history={history} token={token} />
    </section>
  );
};

DeleteConfirmation.propTypes = {
  history: PropTypes.object.isRequired,
  userLoggedIn: PropTypes.bool,
};

export default connect(
  ({ app: { userLoggedIn } }) => ({
    userLoggedIn,
  }),
  {},
)(DeleteConfirmation);
