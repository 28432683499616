import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import { Button, Spinner, Title } from 'jpi-cloud-web-ui-components';

import Tabs from '../../layout/Tabs';
import PremiumFeatureTooltip from '../../layout/PremiumFeatureTooltip';

import ToggleScheduleOverride from './components/ToggleScheduleOverride';
import WeeklySchedule from './components/WeeklySchedule';
import VacationSchedule from './components/VacationSchedule';
import EditModesPopup from './components/EditModesPopup';

import { getScheduleConfig } from './actions';
import { getSystemStatus } from '../../../components/layout/SystemStatus/actions';
import { USER_SECURITY_LEVELS } from '../SystemProfile/components/SecurityTab/costants';

import './device-scheduling.scss';

const tabs = ['scheduling.tab.Schedule', 'scheduling.tab.Vacation'];

const SubHeader = ({ openEditModesPopup, userIsViewer, modes, overrideAvailable, scheduleConfig }) => (
  <div className="device-scheduling__item">
    {overrideAvailable && <ToggleScheduleOverride userIsViewer={userIsViewer} />}
    <div className="edit-modes-btn-container">
      {userIsViewer && modes.length <= 0 ? (
        <PremiumFeatureTooltip
          translationId="scheduling.title.no-modes"
          defaultMessage="You don't have modes to view"
        />
      ) : !scheduleConfig ? (
        <PremiumFeatureTooltip
          translationId="scheduling.config.loading"
          defaultMessage="Scheduling configurations are loading..."
        />
      ) : null}
      <Button
        id="edit-modes"
        type="button"
        className="button--default"
        onClick={openEditModesPopup}
        disabled={(userIsViewer && modes.length <= 0) || !scheduleConfig}
      >
        {userIsViewer ? (
          <FormattedMessage id="scheduling.title.view-modes" defaultMessage="View Modes" />
        ) : (
          <FormattedMessage id="scheduling.title.edit-modes" defaultMessage="Edit Modes" />
        )}
      </Button>
    </div>
  </div>
);

SubHeader.propTypes = {
  openEditModesPopup: PropTypes.func.isRequired,
  userIsViewer: PropTypes.bool.isRequired,
  overrideAvailable: PropTypes.bool,
  modes: PropTypes.array.isRequired,
  scheduleConfig: PropTypes.object.isRequired,
};

class DeviceScheduling extends React.Component {
  static propTypes = {
    schedulingDeviceFeatures: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    selectedSystem: PropTypes.object,
    userInfo: PropTypes.object,
    devices: PropTypes.array.isRequired,
    scheduleConfig: PropTypes.object,
    getScheduleConfig: PropTypes.func.isRequired,
    modes: PropTypes.array.isRequired,
    weeklySchedules: PropTypes.array.isRequired,
    currentStatus: PropTypes.string.isRequired,
    getSystemStatus: PropTypes.func.isRequired,
  };

  state = {
    activeTab: tabs[0],
    isModePopupOpen: false,
    selectedModes: [],
    isLoading: false,
    deviceStatus: true,
  };

  checkIfSchedulingIsAvailable = () => {
    const {
      scheduleConfig,
      schedulingDeviceFeatures: { scheduleOverride, scheduleWeekly, scheduleVacation },
    } = this.props;

    // TODO: SCHEDULING MOCK. Remove all mocking logic when scheduling support is implemented on the device level.
    // We should show appropriate message on tab, if it contains a functionality that isn't supported by device.
    const schedulingAvailable = scheduleOverride || scheduleWeekly || scheduleVacation;
    if (!schedulingAvailable || scheduleConfig.weeklySchedulesNumber < 1) {
      this.props.history.push('/');
    }
  };
  //fetch device status continuously in order to check device status if it goes offline during any scheduling changes.
  startRefreshIntervalCycle = handler => {
    const refreshIntervalCycle = setInterval(handler, 10 * 1000);
    this.setState({ refreshIntervalCycle });
  };

  disposeRefreshIntervalCycle = () => {
    if (this.state.refreshIntervalCycle) {
      clearInterval(this.state.refreshIntervalCycle);
    }
  };

  async componentDidMount() {
    if (!this.props.selectedSystem.online) {
      this.props.history.push('/');
    }
    this.setState({ deviceStatus: this.props.currentStatus });
    await this.props.getScheduleConfig(this.props.devices[0].id);
    this.checkIfSchedulingIsAvailable();
    this.startRefreshIntervalCycle(async () => {
      const res = await this.props.getSystemStatus(this.props.selectedSystem.id);
      this.setState({ deviceStatus: res });
    });
  }

  async componentDidUpdate(prevProps) {
    if (!this.props.selectedSystem.online) {
      return this.props.history.push('/');
    }
    if (prevProps.devices !== this.props.devices && this.props.devices.length) {
      this.setState({ isLoading: true });
      await this.props.getScheduleConfig(this.props.devices[0].id);
      this.setState({ isLoading: false, activeTab: tabs[0] });
    }

    if (prevProps.schedulingDeviceFeatures !== this.props.schedulingDeviceFeatures) {
      this.checkIfSchedulingIsAvailable();
    }
  }

  componentWillUnmount() {
    this.disposeRefreshIntervalCycle();
  }

  _selectTab = tab => {
    this.setState({ activeTab: tab });
  };

  openPopup = () => {
    this.setState({ isModePopupOpen: true });
  };

  closePopup = () => {
    this.setState({ isModePopupOpen: false });
  };

  getScheduleFeatures = (scheduleConfig, isViewer, isDemo) => {
    const {
      weeklyAvailable,
      vacationAvailable,
      weekFormatAvailable,
      stopTimeAvailable,
      allowUnscheduled,
      maxEventsNumber,
    } = scheduleConfig;

    const availableTabs = [weeklyAvailable && tabs[0], vacationAvailable && tabs[1]].filter(tab => tab);

    if (!availableTabs.length) {
      return <></>;
    }

    if (!availableTabs.includes(this.state.activeTab)) {
      this.setState({ activeTab: availableTabs[0] });
    }

    return (
      <Tabs labels={availableTabs} onClickTabItem={this._selectTab} activeTab={this.state.activeTab}>
        {this.state.activeTab === tabs[0] && (
          <WeeklySchedule
            stopTimeAvailable={stopTimeAvailable && allowUnscheduled}
            maxEventsNumber={maxEventsNumber}
            userIsViewer={isViewer || isDemo}
            weekFormatAvailable={weekFormatAvailable}
          />
        )}
        {this.state.activeTab === tabs[1] && <VacationSchedule userIsViewer={isViewer || isDemo} />}
      </Tabs>
    );
  };

  canDeleteMode = mode => {
    const { weeklySchedules } = this.props;
    const hasAnyEvent = weeklySchedules.some(ws => ws.events.some(ev => ev.modeId === mode.modeId));
    return !hasAnyEvent;
  };

  render() {
    const {
      selectedSystem,
      scheduleConfig,
      modes,
      devices,
      schedulingDeviceFeatures: { scheduleOverride, scheduleWeekly, scheduleVacation },
    } = this.props;

    const isViewer = selectedSystem && selectedSystem.userRole === USER_SECURITY_LEVELS.viewer;
    const isDemo = this.props.userInfo && this.props.userInfo.isDemo;

    if (this.state.isLoading || !devices.length) {
      return <Spinner dark />;
    }

    return (
      <div className="page-content">
        <Title titleTranslationId="scheduling.header" defaultMessage="Scheduling" />
        {this.props.currentStatus === 'offline' || this.state.deviceStatus === 'offline' ? (
          <div className="menu-unavailable-wrapper">
            <FormattedMessage
              id="scheduling.error.request.scheduling.device-disconnected"
              defaultMessage="The scheduling can't be reset. Check that device is connected."
            />
          </div>
        ) : !(scheduleOverride || scheduleWeekly || scheduleVacation) ? (
          <div className="menu-unavailable-wrapper">
            <FormattedMessage
              id="scheduling.error.allowScheduling.false"
              defaultMessage="Your device do not support scheduling."
            />
          </div>
        ) : (
          <>
            <div className="device-scheduling__wrapper">
              <SubHeader
                openEditModesPopup={this.openPopup}
                userIsViewer={isViewer || isDemo}
                modes={modes}
                scheduleConfig={scheduleConfig}
                overrideAvailable={scheduleConfig && scheduleConfig.overrideAvailable}
              />

              {scheduleConfig && this.getScheduleFeatures(scheduleConfig, isViewer, isDemo)}
            </div>
            {this.state.isModePopupOpen && (
              <EditModesPopup
                isModePopupOpen={this.state.isModePopupOpen}
                closePopup={this.closePopup}
                userIsViewer={isViewer || isDemo}
                canBeDeleted={this.canDeleteMode}
              />
            )}
          </>
        )}
      </div>
    );
  }
}

export default connect(
  ({
    app: { selectedSystem, userInfo },
    devices: { devices },
    features: {
      deviceFeatures: { scheduling },
    },
    systemStatus: { currentStatus },
    deviceScheduling: { modes, scheduleConfig, weeklySchedules },
  }) => ({
    modes,
    selectedSystem,
    userInfo,
    schedulingDeviceFeatures: scheduling,
    currentStatus: currentStatus,
    devices,
    scheduleConfig,
    weeklySchedules,
  }),
  {
    getScheduleConfig,
    getSystemStatus,
  },
)(DeviceScheduling);
