import moment from 'moment';
import { defaultValueLowerLimit } from '../../constants/constants';
import { applyTickFormat } from './components/chart/tick-format';

export const getPredictedPoint = (lastPoint, dateTo) => {
  return { ...lastPoint, timestamp: moment(dateTo).utc().add(10, 'days').format('YYYY-MM-DDTHH:mm:ssZ') };
};

export const determineCurveType = curveStyle => {
  switch (curveStyle) {
    case 'smooth':
      return 'curveMonotoneX';
    case 'straight':
      return 'curveLinear';
    case 'binary':
      return 'curveStepAfter';
    default:
      return 'curveMonotoneX';
  }
};

export const calculateYDomain = (minValue, maxValue, verticalMarginPercentage) => {
  const variationRange = maxValue - minValue;

  const marginValue =
    variationRange == 0
      ? (maxValue / 100) * verticalMarginPercentage
      : (variationRange / 100) * verticalMarginPercentage;

  return [minValue - marginValue, maxValue + marginValue];
};

export const DISCRETE_COLOR_RANGE = [
  '#1A237E',
  '#FF5722',
  '#33658A',
  '#B41D1D',
  '#ECB322',
  '#6588cd',
  '#66b046',
  '#a361c7',
  '#55a47b',
  '#cb6141',
];

export const getColor = (color, isActive = true) => (isActive ? color : '#E2E2E2');

export const ChartType = {
  line: 'line',
  column: 'Column',
  area: 'Area',
};

export const chartNumber = ['first', 'second'];

export const checkIsFirstChart = num => num === chartNumber[0];
export const formatDate = d => moment(d).utc().format('YYYY-MM-DD HH:mm:ss');

export const DateRange = { Year: 'Year', Month: 'Month', Week: 'Week', Day: 'Day', Custom: 'Custom' };

export const month = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const checkTodayDate = date => {
  const d = moment();
  return d.format('YYYY-MM-DD') === moment(date).format('YYYY-MM-DD');
};

export const checkTwoYearsBackDate = date => {
  const d = moment();
  d.subtract(2, 'years');
  d.startOf('year');
  return d.format('DD/MM/YYYY') === moment(date).format('DD/MM/YYYY');
};

export const getCurrentDateFormat = (DateRange, range, date) => {
  switch (range) {
    case DateRange.Year:
      return moment(date).format('YYYY');
    case DateRange.Month:
      return month[moment(date).month()];
    case DateRange.Week:
      return moment(date).week();
    default:
      return moment(date).format('DD/MM/YYYY');
  }
};

export const setCustomDateRange = (dateRange, dateFrom, dateTo, stepBack) => {
  const minDate = moment(dateFrom);
  let maxDate = moment(dateTo);
  const today = moment();
  const d = moment();

  switch (dateRange) {
    case DateRange.Year: {
      if (!stepBack && today.year() !== minDate.year()) {
        minDate.add(1, 'year');
        if (minDate.year() === today.year()) {
          maxDate = moment();
        } else {
          maxDate.add(1, 'year');
        }
      }
      if (stepBack && !checkTwoYearsBackDate(minDate)) {
        minDate.subtract(1, 'year');
        minDate.startOf('year');
        if (maxDate.year() !== today.year()) {
          maxDate.subtract(1, 'year');
        } else {
          maxDate = moment(minDate).endOf('Year');
        }
      }

      break;
    }
    case DateRange.Month: {
      if (!stepBack && !checkTodayDate(maxDate)) {
        minDate.add(1, 'month');
        if (today.format('MMM YYYY') === minDate.format('MMM YYYY')) {
          maxDate = moment();
        } else {
          maxDate.add(1, 'month');
        }
      }
      if (stepBack && !checkTwoYearsBackDate(minDate)) {
        minDate.subtract(1, 'month');
        if (maxDate.month() !== today.month()) {
          maxDate.subtract(1, 'month');
        } else {
          maxDate = moment(minDate).endOf('month');
        }
      }

      break;
    }
    case DateRange.Week: {
      if (!stepBack && !checkTodayDate(maxDate)) {
        minDate.add(1, 'week');
        if (moment(d).week() === moment(minDate).week()) {
          maxDate = today;
        } else {
          maxDate = moment(minDate).endOf('week');
        }
      }
      if (stepBack && !checkTwoYearsBackDate(minDate)) {
        minDate.subtract(1, 'week');
        if (!checkTodayDate(maxDate)) {
          maxDate.subtract(1, 'week');
        } else {
          maxDate = moment(minDate).endOf('week');
        }
      }
      break;
    }
    default: {
      if (!stepBack && !checkTodayDate(minDate)) {
        minDate.add(1, 'day');
        if (checkTodayDate(minDate)) {
          maxDate = today;
        } else {
          maxDate.add(1, 'day');
          maxDate = moment(maxDate).endOf('day');
        }
      }
      if (stepBack && !checkTwoYearsBackDate(minDate)) {
        minDate.subtract(1, 'day');
        if (!checkTodayDate(maxDate)) {
          maxDate.subtract(1, 'day');
        } else {
          maxDate = moment(minDate).endOf('day');
        }
      }
      break;
    }
  }
  return { minDate, maxDate };
};

export const setDateRangeForDifferentTimePeriod = range => {
  const dateTo = moment();
  let dateFrom;

  switch (range) {
    case DateRange.Year:
      dateFrom = moment().startOf('Year');
      break;
    case DateRange.Month:
      dateFrom = moment().startOf('month');
      break;
    case DateRange.Week:
      dateFrom = moment().startOf('week');
      break;
    case DateRange.Custom:
      dateFrom = moment().subtract(2, 'years');
      break;
    case 'nonPremiumUserCustom':
      dateFrom = moment().subtract(1, 'month');
      break;
    default:
      dateFrom = moment().startOf('day');
      break;
  }
  return { dateTo, dateFrom };
};

export const findTimeDiff = (endDate, startDate, timeDiffRange) => {
  const timeDifference = Math.ceil(moment.duration(endDate.diff(startDate)).asHours());
  const timeDifferencePercentage = ((timeDiffRange * 2) / 100) * timeDifference;
  return timeDifferencePercentage;
};

export const findBarWidth = (date, dateRange) => {
  switch (dateRange) {
    case dateRangeType.Day:
      date.subtract(5, 'minutes');
      break;
    case dateRangeType.Week:
      date.subtract(30, 'minutes');
      break;
    case dateRangeType.Month:
      date.subtract(6, 'hours');
      break;
    case dateRangeType.Year:
      date.subtract(5, 'days');
      break;
    case dateRangeType.Custom:
      date.subtract(10, 'days');
      break;
  }
  return date;
};

export const dateRangeType = {
  Day: 'Day',
  Week: 'Week',
  Month: 'Month',
  Year: 'Year',
  Custom: 'Custom',
};

export const yDomainMargin = 10;

export const calculateXDomain = (dateRange, dateFrom, dateTo) => {
  const startDate = moment(dateFrom),
    endDate = moment(dateTo);

  // X domain is extended so values located close to edges
  // are not cut off by Borders component.
  // These values are also slightly modified for each date range
  // to make labels fit. Will probably be reworked
  // during CLOUDTEAM-2945.
  switch (dateRange) {
    case dateRangeType.Day:
      startDate.subtract(3.5, 'minutes');
      endDate.add(3.5, 'minutes');
      break;
    case dateRangeType.Week:
      startDate.subtract(75, 'minutes');
      endDate.add(75, 'minutes');
      break;
    case dateRangeType.Month:
      startDate.subtract(12, 'hours');
      endDate.add(12, 'hours');
      break;
    case dateRangeType.Year:
      startDate.subtract(5, 'days');
      endDate.add(5, 'days');
      break;
  }
  return [startDate.toDate(), endDate.toDate()];
};

export const findCurveStyle = (chart, parameters) => {
  const p = parameters && parameters.find(res => res.id === chart.category);
  return p ? p.parameters.find(res => res.parameterName === chart.parameter).curveStyle : 'smooth';
};

export const translateXAxisLabel = input => {
  //can be used in future
  // const useMoment = this.props.dateRange === dateRangeType.Year;
  // if (useMoment) {
  //   return moment(input).format('MMMM');
  // }
  return applyTickFormat(input);
};

export const generatePointSeries = (chart, range) => {
  const points = chart.precedingPoint ? [chart.precedingPoint, ...chart.points] : chart.points;

  return (
    points &&
    points.map(p => {
      const yValue = Number(Number(p.value).toFixed(p.decimals));
      const hasValidYValue = p.value !== null && p.value !== undefined && yValue >= defaultValueLowerLimit;
      if (chart.chartType === ChartType.column) {
        return {
          x0: moment.utc(new Date(findBarWidth(moment(p.timestamp), range)).toISOString()),
          x: moment(p.timestamp),
          y: hasValidYValue ? yValue : null,
        };
      }
      return {
        x: moment(p.timestamp),
        y: hasValidYValue ? yValue : null,
      };
    })
  );
};

export const onChangeChart = (chart, chartType, parameters) =>
  parameters.map(param => (param.parameterId === chart.parameterId ? { ...param, ...chart, chartType } : param));

export const crosshairIsInSelectedRange = (hoveredValues, selectedZoom) => {
  if (!hoveredValues) {
    return false;
  }

  if (!selectedZoom) {
    return true;
  }

  return hoveredValues.x > selectedZoom.left && hoveredValues.x < selectedZoom.right;
};

export const findScrollWidth = (dateRange, isLeft, selectedZoom, dateFrom, dateTo) => {
  const startDate = moment(selectedZoom.left);
  const endDate = moment(selectedZoom.right);

  switch (dateRange) {
    case dateRangeType.Day:
      isLeft ? startDate.subtract(5, 'minutes') : startDate.add(5, 'minutes');
      isLeft ? endDate.subtract(5, 'minutes') : endDate.add(5, 'minutes');
      break;
    case dateRangeType.Week:
      isLeft ? startDate.subtract(5 * 3, 'minutes') : startDate.add(5 * 3, 'minutes');
      isLeft ? endDate.subtract(5 * 3, 'minutes') : endDate.add(5 * 3, 'minutes');
      break;
    case dateRangeType.Month:
      isLeft ? startDate.subtract(5, 'hours') : startDate.add(5, 'hours');
      isLeft ? endDate.subtract(5, 'hours') : endDate.add(5, 'hours');
      break;
    case dateRangeType.Year:
      isLeft ? startDate.subtract(5 * 3, 'hours') : startDate.add(5 * 3, 'hours');
      isLeft ? endDate.subtract(5 * 3, 'hours') : endDate.add(5 * 3, 'hours');
      break;
    case dateRangeType.Custom:
      isLeft ? startDate.subtract(1, 'days') : startDate.add(1, 'days');
      isLeft ? endDate.subtract(1, 'days') : endDate.add(1, 'days');
      break;
  }
  if (startDate.isAfter(dateFrom) && endDate.isBefore(dateTo)) {
    return { left: startDate, right: endDate };
  }
  return selectedZoom;
};

const getNearestChartPointBeforeOrEqual = (points, value) => {
  if (!points || points.length === 0) {
    return null;
  }

  let nearestPoint = null;
  for (const point of points) {
    if (moment(point.timestamp).isAfter(value)) {
      break;
    }
    nearestPoint = point;
  }
  return nearestPoint;
};

export const onHover = (value, charts, parameters) => {
  const hoveredValues = {
    x: moment(value.x),
    values: charts.map(c => {
      const hoveredPoint = getNearestChartPointBeforeOrEqual(c.points, value.x);
      if (hoveredPoint) {
        const enumValueText = parameters
          ?.find(p => p.id === c.category)
          ?.parameters?.find(p => p.parameterId === c.parameterId)
          ?.enumValues?.find(v => +v.value === hoveredPoint.value)?.text;

        if (enumValueText) {
          return {
            title: c.parameter,
            value: enumValueText,
          };
        }

        const value = (Math.round(hoveredPoint.value * 100) / 100).toFixed(hoveredPoint.decimals);
        const formattedValue = value <= defaultValueLowerLimit ? '--' : `${value} ${c.unit}`;

        return {
          title: c.parameter,
          value: formattedValue,
        };
      }
    }),
  };
  return hoveredValues;
};

export const nonPremiumNumberOfDays = 30;
