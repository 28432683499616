import { Title } from 'jpi-cloud-web-ui-components';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import Price from '../../../../../../Shop/components/Price';
import { orderTypeDefaultMessageMap, orderTypeMessageMap } from '../constants';

import './paymentdetails.scss';

const paymentDetails = ({ order }) => {
  const { data } = order || {};
  const { status, currency, price, orderDetails, statusUpdatedAt } = data || {};
  const {
    customAttributes: { transactionId, cardNoMask },
    orderType,
  } = orderDetails || {};

  return (
    <div className="PaymentDetails">
      <div className="PaymentDetails__paymentWrapper">
        <div className="PaymentDetails__payment-heading">
          <Title
            className="PaymentDetailsTitle"
            titleTranslationId="orders.orderPage.paymentTitle"
            defaultMessage="Your Payments"
          />
        </div>
        <div className="PaymentDetails__paymentDetails-wrapper">
          <div className="PaymentDetails__statusAndDate">
            <p className="PaymentDetails__paymentStatus">
              <FormattedMessage id={'orders.page.orderStatus.' + status} defaultMessage={status} />
            </p>
            <p className="PaymentDetails__statusUpdatedAt">{moment.utc(statusUpdatedAt).format('MMMM D YYYY')}</p>
          </div>
          <div className="PaymentDetails__subscription-wrapper-block">
            <p className="PaymentDetails__subscriptionType">
              <FormattedMessage
                id={orderTypeMessageMap[orderType]}
                defaultMessage={orderTypeDefaultMessageMap[orderType]}
              />
            </p>
            <p className="PaymentDetails__totalPrice">
              <Price currency={currency} price={price} />
            </p>
          </div>
        </div>
        {(transactionId || cardNoMask) && (
          <div className="PaymentDetails__paymentIdAndCardNo">
            <div className="PaymentDetails__paymentDetails-col1">
              <p className="PaymentDetails__paymentId-title">
                <FormattedMessage id="orders.orderPage.transactionId" defaultMessage="Transaction Id" />
              </p>
              &#58;&nbsp;
              <p className="PaymentDetails__paymentId">{transactionId}</p>
            </div>
            <div className="PaymentDetails__paymentDetails-col2">
              <p className="PaymentDetails__cardDetails-title">
                <FormattedMessage id="orders.orderPage.cardDetails" defaultMessage="Card Details" />
              </p>
              &#58;&nbsp;
              <p className="PaymentDetails__cardNo">{cardNoMask}</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

paymentDetails.propTypes = {
  order: PropTypes.object.isRequired,
};
export default paymentDetails;
