import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';

import { Button } from 'jpi-cloud-web-ui-components';
import Spacer from '../../../layout/Spacer';

const LoginWithAzureB2C = ({ loginWithAzureB2C, isLoginPage }) => {
  return (
    <section className="form--half login-form-azure">
      <h1 className="form__heading">
        {isLoginPage ? (
          <FormattedMessage id="login.header.variant" defaultMessage="Welcome back!" />
        ) : (
          <FormattedMessage id="login.header.sign.up" defaultMessage="Join myUplink" />
        )}
      </h1>
      <div className="button-wrapper--large">
        <Button type="button" className="link--secondary" onClick={loginWithAzureB2C}>
          <i className="fa fa-envelope"></i>
          <FormattedMessage id="login.button.text.variant" defaultMessage="Continue with email" />
        </Button>
        <Spacer y={24} />
        {isLoginPage ? (
          <p>
            <FormattedMessage id="login.no.account.text" defaultMessage="Don't have a personal account?" />{' '}
            <a href="/sign-up" className="link-text link-text-underline">
              <FormattedMessage id="login.signup" defaultMessage="Sign up" />
            </a>
          </p>
        ) : (
          <p>
            <FormattedMessage id="login.no.account.text.sign.up" defaultMessage="Already have an account?" />{' '}
            <a href="/login" className="link-text link-text-underline">
              <FormattedMessage id="login_button_text" defaultMessage="Log in" />
            </a>
          </p>
        )}
        <Spacer y={60} />
      </div>
      <div className="login-form-azure-acknowledge">
        <FormattedMessage
          id="login.acknowledge"
          values={{
            termsLink: (
              <a
                className="link-text link-text-underline"
                href="/legal/terms-of-service"
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms & Conditions
              </a>
            ),
            privacyLink: (
              <a
                className="link-text link-text-underline"
                href="/legal/privacy-policy"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>
            ),
          }}
        />
      </div>
    </section>
  );
};

LoginWithAzureB2C.propTypes = {
  loginWithAzureB2C: PropTypes.func.isRequired,
  isLoginPage: PropTypes.bool.isRequired,
};

export default injectIntl(LoginWithAzureB2C);
