import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Link from '../inputs/Link';

const NibeFirmwareChangeLogUrl = 'https://www.nibe.eu/myuplink_changelog';
const FirmwareToProductType = {
  'nibe-e1': 'nibe-f-F1145-F1245',
  'nibe-e2': 'nibe-f-F370-F470',
  'nibe-e3': 'nibe-f-F750',
  'nibe-e4': 'nibe-f-F1126-F1226',
  'nibe-e5': 'nibe-f-F1345',
  'nibe-e6': 'nibe-f-VVM310-VVM500',
  'nibe-e7': 'nibe-f-SMO20',
  'nibe-e8': 'nibe-f-SMO40',
  'nibe-e9': 'nibe-f-VVM225-VVM320-VVM325',
  'nibe-e10': 'nibe-f-F1155-F1255',
  'nibe-e11': 'nibe-f-F730',
  'nibe-e13': 'nibe-f-F1355',
  'nibe-n': 'nibe-n',
};

const getReleaseNoteLink = firmwareTypeId => {
  const isEligibleForReleaseNote = !!firmwareTypeId && Object.keys(FirmwareToProductType).includes(firmwareTypeId);

  if (!isEligibleForReleaseNote) return null;

  return `${NibeFirmwareChangeLogUrl}/${FirmwareToProductType[firmwareTypeId]}.pdf`;
};

const ReleaseNotesLink = ({ firmwareTypeId, ...rest }) => {
  const releaseNoteLink = getReleaseNoteLink(firmwareTypeId);

  if (!releaseNoteLink) {
    return null;
  }

  return (
    <Link to={releaseNoteLink} {...rest}>
      <FormattedMessage id="devices.release-notes" defaultMessage="Release notes" />
    </Link>
  );
};

ReleaseNotesLink.propTypes = {
  firmwareTypeId: PropTypes.string,
};

export default ReleaseNotesLink;
