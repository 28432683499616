import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Button } from 'jpi-cloud-web-ui-components';

import Spacer from '../../../../layout/Spacer';
import Divider from '../../../../layout/Divider/Divider';

import './account-migration-section.scss';

const AccountMigrationSection = ({ openMigrationModal, translationObject }) => (
  <div className="account-migration-section">
    <Spacer y={50} />
    <div className="account-migration-section-divider-wrapper">
      <Divider />
    </div>
    <Spacer y={50} />
    <h4 className="account-migration-section-header">
      <FormattedMessage
        id={`accountMigrationSection${translationObject.key}.title`}
        defaultMessage={`${translationObject.value} Migration`}
      />
    </h4>
    <Spacer y={24} />
    <p className="account-migration-section-content">
      <FormattedMessage
        id={`accountMigrationSection${translationObject.key}.description`}
        defaultMessage={`Here you can migrate your old ${translationObject.value} account with existing one, all of your device data 
and configuration will be transferred.`}
      />
    </p>
    <Spacer y={50} />
    <Button className="button--secondary" onClick={openMigrationModal}>
      <FormattedMessage id="accountMigrationSection.migrateButton" defaultMessage="Start Migration" />
    </Button>
  </div>
);

AccountMigrationSection.propTypes = {
  openMigrationModal: PropTypes.func,
  translationObject: PropTypes.object,
};

export default AccountMigrationSection;
