import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Spinner } from 'jpi-cloud-web-ui-components';
import Link from '../../../../inputs/Link';

import { getBrandDetails } from './actions';

class AboutManufacturerTab extends React.Component {
  static propTypes = {
    selectedSystemBrandId: PropTypes.string,
    brandDetails: PropTypes.object,
    brandAddress: PropTypes.object,
    brandDetailsLoaded: PropTypes.bool,
    getBrandDetails: PropTypes.func.isRequired,
  };

  async getBrandData(selectedSystemBrandId) {
    if (selectedSystemBrandId != null) {
      await this.props.getBrandDetails(selectedSystemBrandId);
    }
  }

  async componentDidMount() {
    await this.getBrandData(this.props.selectedSystemBrandId);
  }

  async componentDidUpdate(prevProps) {
    if (this.props.selectedSystemBrandId !== prevProps.selectedSystemBrandId) {
      await this.getBrandData(this.props.selectedSystemBrandId);
    }
  }

  render() {
    const { selectedSystemBrandId, brandDetails, brandAddress, brandDetailsLoaded } = this.props;

    if (!brandDetailsLoaded) {
      return <Spinner dark />;
    }

    if (!selectedSystemBrandId) {
      return null;
    }

    return (
      <div className="about-manufacturer">
        <div className="system-profile-info-item">
          <div className="system-profile-info-item-value">
            <span className="hero__logo-rectangle" />
            <div className="signature-name">{brandDetails.signatureName}</div>
            <br />
            {brandAddress && brandAddress.city ? (
              <>
                <div className="line-one">{brandAddress.lineOne},</div>
                {brandAddress.lineTwo && <div className="line-two">{brandAddress.lineTwo},</div>}
                <div className="city">{brandAddress.city},</div>
                {brandAddress.region && <div className="region">{brandAddress.region}</div>}
                <div className="postal-code">{brandAddress.postalCode}</div>
                <div className="country">{brandAddress.country.name}</div>
              </>
            ) : (
              <div className="value">
                {' '}
                <FormattedMessage id="address.no-info" defaultMessage="There is no info about address" />
              </div>
            )}
            <br />
            {brandDetails.phone && <div className="phone">{brandDetails.phone} </div>}
            <br />
            <div className="website">
              <Link keyFor={brandDetails.website} to={brandDetails.website}>
                {brandDetails.website}
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  ({ app: { selectedSystem }, aboutManufacturerTab }) => ({
    selectedSystemBrandId: selectedSystem ? selectedSystem.brandId : null,
    ...aboutManufacturerTab,
  }),
  {
    getBrandDetails,
  },
)(AboutManufacturerTab);
