import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import PremiumFeatureTooltip from '../../layout/PremiumFeatureTooltip';
import './RadioGroup.scss';

const RadioGroup = props => {
  const {
    id,
    items,
    onChange,
    labelGetter = item => item.text,
    selectedItem: initialSelectedItem,
    comparator = (a, b) => a === b,
    isDisabledAndVisibleTooltip,
    premiumFeatureType,
    isPending,
  } = props;

  const [selectedItem, setSelectedItem] = useState(initialSelectedItem);
  const timeout = useRef(null);
  const selectedItemRef = useRef(selectedItem);
  selectedItemRef.current = selectedItem;

  const sendChangeRequest = async item => {
    setSelectedItem(item);
    if (!onChange) return;

    clearTimeout(timeout.current);

    timeout.current = setTimeout(async () => {
      timeout.current = null;

      if (item !== selectedItemRef.current) {
        return;
      }

      await onChange(item);
    }, 1000);
  };

  useEffect(() => {
    if (timeout.current || initialSelectedItem !== selectedItemRef.current) return;

    setSelectedItem(initialSelectedItem);

    return () => {
      clearTimeout(timeout.current);
    };
  }, [initialSelectedItem]);

  const handleChange = (event, item) => {
    setSelectedItem(item);
    sendChangeRequest(item);
  };

  const getControlId = i => `${id}_${i}`;

  return (
    <div className="radio-group-wrapper">
      {items.map((item, i) => (
        <div className="radioGroupComponent" key={getControlId(i)}>
          <input
            className="radioGroupComponent__input"
            type="radio"
            id={getControlId(i)}
            name={id}
            value={item.value}
            disabled={isDisabledAndVisibleTooltip || item.disabled || isPending}
            checked={selectedItem ? comparator(selectedItem, item) : item.checked}
            onChange={event => handleChange(event, item)}
          />
          {isDisabledAndVisibleTooltip && (
            <PremiumFeatureTooltip premiumFeatureType={premiumFeatureType ? premiumFeatureType : 'option'} />
          )}
          <label className="radioGroupComponent__label" htmlFor={getControlId(i)}>
            {labelGetter(item)}
          </label>
        </div>
      ))}
    </div>
  );
};

RadioGroup.propTypes = {
  id: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChange: PropTypes.func,
  labelGetter: PropTypes.func,
  selectedItem: PropTypes.object,
  comparator: PropTypes.func,
  isDisabledAndVisibleTooltip: PropTypes.bool,
  premiumFeatureType: PropTypes.string,
  isPending: PropTypes.bool,
};

export default RadioGroup;
