import * as api from '../../../api';

export const REDEEM_VOUCHER_REQUEST = 'redeemVoucher/REDEEM_VOUCHER_REQUEST';
export const REDEEM_VOUCHER_RESPONSE_SUCCEEDED = 'redeemVoucher/REDEEM_VOUCHER_RESPONSE_SUCCEEDED';
export const REDEEM_VOUCHER_RESPONSE_FAILED = 'redeemVoucher/REDEEM_VOUCHER_RESPONSE_FAILED';
export const redeemVoucher = (systemId, voucherCode) => async dispatch => {
  dispatch({ type: REDEEM_VOUCHER_REQUEST });

  try {
    const response = await api.redeemVoucher(systemId, voucherCode);

    if (response.status === 200)
      dispatch({ type: REDEEM_VOUCHER_RESPONSE_SUCCEEDED, redeemVoucherResponse: response.data });
    else dispatch({ type: REDEEM_VOUCHER_RESPONSE_FAILED, error: response.data.description });
  } catch (e) {
    const description = e.response && e.response.data && e.response.data.description;
    dispatch({ type: REDEEM_VOUCHER_RESPONSE_FAILED, error: description || '' });
  }
};

export const CLEAR_ERROR_MESSAGE = 'redeemVoucher/CLEAR_ERROR_MESSAGE';
export const clearErrorMessage = () => async dispatch => {
  dispatch({ type: CLEAR_ERROR_MESSAGE });
};
