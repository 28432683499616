import React, { useState } from 'react';
import PropTypes, { string } from 'prop-types';
import { connect } from 'react-redux';
import * as client from '../../../../Shop/client';
import { FormattedMessage } from 'react-intl';
import { Button, Spinner, Title } from 'jpi-cloud-web-ui-components';
import Price from '../../../../Shop/components/Price';
import UserDetailModal from './UserDetailModal';
import { USER_SECURITY_LEVELS } from '../../../SystemProfile/components/SecurityTab/costants';
import './cart.scss';

const shoppingCart = ({
  cart,
  removeProduct,
  getPaymentProviders,
  address,
  selectedSystem,
  selectPaymentSettingTab,
  devices,
}) => {
  const [isUserDetail, toggleUserDetailPopup] = useState(false);
  const [showUserDetail, setShowUserDetail] = useState(false);
  const [productDetails, toggleProductDetailsPopup] = useState(false);
  const [{ id: deviceId }] = devices;

  const isViewerOrManager =
    selectedSystem &&
    (selectedSystem.userRole.toLowerCase() === USER_SECURITY_LEVELS.viewer ||
      selectedSystem.userRole.toLowerCase() === USER_SECURITY_LEVELS.manager);
  const getPaymentProviderData = () => {
    if (cart && cart.lineItems) {
      getPaymentProviders(cart, deviceId);
      const paymentProvider = getPaymentProviders;
      if (paymentProvider) {
        toggleUserDetailPopup(!isUserDetail);
        setShowUserDetail(!showUserDetail);
      }
    }
  };

  return (
    <div className="shoppingCart">
      <div className="shoppingCart__heading">
        <Title className="CartTitle" titleTranslationId="shop.cart.heading" default FormattedMessage="Shopping Cart" />
      </div>
      {!cart || cart.loading || !cart.lineItems ? (
        <Spinner />
      ) : (
        <>
          {cart.lineItems.length === 0 && (
            <div className="shoppingCart__isProductAvailable">
              <FormattedMessage id="cart.emptyCart" defaultMessage="Your shopping cart is empty" />
            </div>
          )}
          {cart.lineItems.length === 0 || (
            <>
              <div className="shoppingCart__cart-wrapper">
                {cart.lineItems.map(shoppingCart => (
                  <div className="shoppingCart__product-wrapper" key={shoppingCart.id}>
                    <div className="shoppingCart__partition" />
                    <div className="shoppingCart__content-wrapper">
                      <div className="shoppingCart__product-heading">
                        <FormattedMessage
                          id={'product.' + shoppingCart.title + '.title'}
                          defaultMessage={shoppingCart.title}
                        />
                      </div>
                      <div className="shoppingCart__total-content-wrapper">
                        <div className="shoppingCart__taxAndSubtotal">
                          <div className="shoppingCart__subtotal-wrapper">
                            <p className="shoppingCart__subtotal-heading">
                              <FormattedMessage id="shop.subtotal" defaultMessage="Subtotal" />
                              &#58;
                            </p>
                          </div>
                          <div className="shoppingCart__tax-wrapper">
                            <p className="shoppingCart__subtotal-price">
                              <Price currency={cart.currencyCode} price={shoppingCart.totalPrice} />
                            </p>
                          </div>
                        </div>
                        <div
                          className="shoppingCart__removeIcon"
                          onClick={() => {
                            removeProduct(shoppingCart);
                          }}
                        >
                          <i className="fa fa-times" aria-hidden="true"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
                <div className="shoppingCart__partition" />
                <div className="shoppingCart__shippingAndTotal">
                  <div className="shoppingCart__product-heading responsive-heading"></div>
                  <div className="shoppingCart__content-wrapper responsive-shippingAndTotalWrapper">
                    <div className="shoppingCart__key-wrapper">
                      <p className="shoppingCart__total-tax-heading">
                        <FormattedMessage id="shop.tax" defaultMessage="Total Tax" />
                        &#58;
                      </p>
                      <p className="shoppingCart__shipping-heading">
                        <FormattedMessage id="shop.shipping" defaultMessage="Shipping" />
                        &#58;
                      </p>
                      <p className="shoppingCart__total-price-heading">
                        <FormattedMessage id="shop.total" defaultMessage="Total" />
                        &#58;
                      </p>
                    </div>
                    {cart.currencyCode && cart.totalPrice && (
                      <div className="shoppingCart__value-wrapper">
                        <p className="shoppingCart__tax-price">
                          <Price currency={cart.currencyCode} price={cart.totalTax} />
                        </p>
                        <p className="shoppingCart__shipping-price">
                          <Price currency={cart.currencyCode} price={'0.00'} />
                        </p>
                        <p className="shoppingCart__total-price">
                          <Price currency={cart.currencyCode} price={cart.totalPrice} />
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="shoppingCart__buttonWrapper">
                {!isViewerOrManager && (
                  <Button
                    className="shoppingCart__checkout-button"
                    onClick={() => {
                      getPaymentProviderData();
                    }}
                  >
                    <FormattedMessage id="shop.cart.Checkout" defaultMessage="Checkout" />
                  </Button>
                )}
              </div>
            </>
          )}
          <UserDetailModal
            cart={cart}
            toggleUserDetailPopup={toggleUserDetailPopup}
            isUserDetail={isUserDetail}
            address={address}
            productDetails={productDetails}
            toggleProductDetailsPopup={toggleProductDetailsPopup}
            selectPaymentSettingTab={selectPaymentSettingTab}
          />
        </>
      )}
    </div>
  );
};

shoppingCart.propTypes = {
  cart: PropTypes.shape({
    init: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.shape({
      key: PropTypes.string.isRequired,
      internal_error: PropTypes.shape({
        err: PropTypes.arrayOf(
          PropTypes.shape({
            key: PropTypes.string.isRequired,
            messages: PropTypes.string.isRequired,
          }),
        ),
      }),
      messages: PropTypes.arrayOf(string),
    }),
    lineItems: PropTypes.arrayOf(
      PropTypes.shape({
        variant: PropTypes.shape({
          product: PropTypes.shape({
            id: PropTypes.string.isRequired,
            handle: PropTypes.string.isRequired,
          }),
        }),
        id: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
      }),
    ),
    totalPrice: PropTypes.string.isRequired,
    totalTax: PropTypes.string.isRequired,
    currencyCode: PropTypes.string.isRequired,
  }),
  selectedSystem: PropTypes.shape({
    userRole: PropTypes.string.isRequired,
  }),
  address: PropTypes.object.isRequired,
  removeProduct: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  getPaymentProviders: PropTypes.func.isRequired,
  selectPaymentSettingTab: PropTypes.func.isRequired,
  devices: PropTypes.arrayOf({
    deviceId: PropTypes.string,
  }),
};

const mapStateToProps = state => ({
  getPaymentProviders: state.shop.paymentProviders,
  address: state.app.userInfo.address,
  devices: state.app.selectedSystem.devices,
});

const mapDispatchToProps = dispatch => ({
  dispatch,
  getPaymentProviders(cart, deviceId, activeTab) {
    dispatch(client.getPaymentProviders(cart, deviceId, activeTab));
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(shoppingCart);
