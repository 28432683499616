import { useMutation } from '@tanstack/react-query';
import { cancelMigrationNotification } from '../../api/accountMigration';

export function getQueryKey() {
  return ['cancelMigrationBanner'];
}

export function useCancelMigrationBanner() {
  return useMutation({
    queryKey: getQueryKey,
    mutationFn: () => cancelMigrationNotification(),
  });
}
