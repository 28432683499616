import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Logo } from '../../Logo';
import DesktopMenu from './components/DesktopMenu';
import HamburgerMenu from './components/HamburgerMenu';

import './topmenu.scss';
import { mainMenu } from './constants';
import MenuContext from './MenuContext';
import { USER_SECURITY_LEVELS } from '../../pages/SystemProfile/components/SecurityTab/costants';
import { getIotStoreRedirectUrl } from './actions';
import { selectDevicesFirmwareId } from '../../../utils/system';

const MenuItem = ({ className, children }) => {
  return (
    <li>
      <div className={className}>{children}</div>
    </li>
  );
};

MenuItem.propTypes = {
  path: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.any,
};

class TopMenu extends Component {
  static propTypes = {
    userInfo: PropTypes.object,
    systems: PropTypes.array,
    selectedSystem: PropTypes.object,
    menuType: PropTypes.string.isRequired,
    getIotStoreRedirectUrl: PropTypes.func.isRequired,
    menu: PropTypes.object,
    deviceUpdateAvailable: PropTypes.bool.isRequired,
  };

  redirectToIotStore = () => {
    this.props.getIotStoreRedirectUrl();
  };

  render() {
    const { userInfo, systems, selectedSystem, menuType, menu, deviceUpdateAvailable } = this.props;
    const userCountryName =
      userInfo && userInfo.address && userInfo.address.country ? userInfo.address.country.name : '';
    const isUserCountrySweden = userCountryName === 'Sweden';
    const hasSystems = systems && systems.length > 0;
    const { brandId, devices } = selectedSystem || {};
    const firmwareId = selectDevicesFirmwareId(devices);
    const isSystemAdmin = selectedSystem && selectedSystem.role === USER_SECURITY_LEVELS.admin;
    const filteredMainMenu = mainMenu.filter(menu => {
      return menu.hidden ? !menu.hidden(selectedSystem) : menu;
    });

    return (
      <header className="header-container">
        <div className="header-container-content">
          <MenuContext.Provider
            value={{
              userCountryName,
              hasSystems,
              brandId,
              firmwareId,
              isSystemAdmin,
              isUserCountrySweden,
              menuType,
              menu,
              deviceUpdateAvailable,
            }}
          >
            <Logo />
            <DesktopMenu menuItems={filteredMainMenu} redirectIotStore={this.redirectToIotStore} />
            <div className="mobileMenu">
              <HamburgerMenu menuItems={filteredMainMenu} redirectIotStore={this.redirectToIotStore} />
            </div>
          </MenuContext.Provider>
        </div>
      </header>
    );
  }
}

export default connect(
  ({ app: { userInfo, systems, selectedSystem, deviceUpdateAvailable }, features: { deviceFeatures } }) => ({
    userInfo,
    systems,
    selectedSystem,
    menuType: deviceFeatures && deviceFeatures.menuSystem,
    menu: deviceFeatures && deviceFeatures.menu,
    deviceUpdateAvailable,
  }),
  {
    getIotStoreRedirectUrl,
  },
)(TopMenu);
