export const status = {
  PENDING: 'pending',
  PAID: 'paid',
};

export const orderTypeMessageMap = {
  Voucher_Yearly_Subscription: 'shop.page.subscriptionDetails.orderType.voucher',
  Yearly_Subscription: 'shop.page.subscriptionDetails.orderType.yearly',
};

export const orderTypeDefaultMessageMap = {
  Voucher_Yearly_Subscription: 'Voucher Subscription',
  Yearly_Subscription: 'Yearly Subscription',
};
