import React, { useState, useEffect } from 'react';
import { FormattedMessage, FormattedHTMLMessage, injectIntl } from 'react-intl';
import { Link } from '../../Link';
import { Formik } from 'formik';
import * as yup from 'yup';
import { ModalBody } from 'react-bootstrap';
import { Button, Input, Checkbox } from 'jpi-cloud-web-ui-components';
import Modal from '../../layout/Modal';
import { InputDropdown } from '../../inputs/Dropdown';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  createUser,
  checkUserEmail,
  checkOldUserEmail,
  validateOldUserCredentials,
  migrateOldUser,
  setUpUserAgreements,
} from './actions';
import { login } from '../Login/actions';
import { setUserDataState } from '../../AppInitializer/actions';
import { UserDataState } from '../../AppInitializer/user-data-state';
import PasswordConfirmation from './components/PasswordConfirmation';
import MigrationConfirmation from './components/MigrationConfirmation';

import {
  address,
  emailAddress,
  defaultMaxLength,
  userNameRegex,
  noRegion,
  isAzureB2CEnabled,
} from '../../constants/constants';
import { formatErrorMessage } from '../../../localization/message-formatting';
import { withRouter } from 'react-router-dom';

const schemaStepOne = yup.object().shape({
  username: yup
    .string()
    .email('username.email')
    .matches(emailAddress.antiXssRegex, 'username.email')
    .required('username.required')
    .max(emailAddress.maxLength, 'username.maxlength'),
  password: yup
    .string()
    .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,}$/, 'password.regexp')
    .test('consecutiveSymbols', 'password.consecutive', v => !/(.)\1\1/.test(v))
    .max(128, 'password.maxlength')
    .required('password.required'),
  passwordConfirm: yup
    .string()
    .oneOf([yup.ref('password'), null], 'password.match')
    .required('password.confirm.required'),
  acceptedPrivacyPolicy: yup.boolean().required('privacy-policy.required').oneOf([true], 'privacy-policy.required'),
  acceptedAgreement: yup.boolean().required('tos.required').oneOf([true], 'tos.required'),
});

const errorMessagesStepOne = {
  'username.email': {
    id: 'email.error.validation.email',
    defaultMessage: 'Email is not valid',
  },
  'username.required': {
    id: 'email.error.validation.required',
    defaultMessage: 'Email is mandatory field',
  },
  'username.maxlength': {
    id: 'email.error.validation.maxlength',
    defaultMessage: 'Email address cannot be longer than 255 characters',
  },
  'password.match': {
    id: 'password.error.validation.match',
    defaultMessage: 'Passwords must match',
  },
  'password.regexp': {
    id: 'password.error.validation.regexp',
    defaultMessage: 'Password must be at least 8 characters long with uppercase letters, numbers and symbols',
  },
  'password.consecutive': {
    id: 'password.error.validation.consecutive',
    defaultMessage: 'Password cannot contain more than 2 identical characters',
  },
  'password.maxlength': {
    id: 'password.error.validation.maxlength',
    defaultMessage: 'Password must be no longer than 128 characters',
  },
  'password.required': {
    id: 'password.error.validation.required',
    defaultMessage: 'Password is mandatory field',
  },
  'password.confirm.required': {
    id: 'password.error.validation.confirm.required',
    defaultMessage: 'Password confirm is mandatory field',
  },
  'privacy-policy.required': {
    id: 'agreements.error.validation.privacy-policy.required',
    defaultMessage: 'Must accept Privacy Policy',
  },
  'tos.required': {
    id: 'agreements.error.validation.tos.required',
    defaultMessage: 'Must accept Terms and Conditions',
  },
  invalid_username_or_password: {
    id: 'register.error.request.invalid',
    defaultMessage: 'Invalid username or password',
  },
  unknown: {
    id: 'generic.error.request.unknown',
    defaultMessage: 'An error has occurred. Try again later.',
  },
  user_exists: {
    id: 'register.error.request.user-exists',
    defaultMessage: 'An account for the specified E-Mail address already exists. Try another E-Mail address.',
  },
};

const RegisterFormStepOneInner = ({ onSubmit, values, requestError, toggleLegalModal, intl, latestAgreements }) => {
  useEffect(() => {
    document.title = intl.formatMessage({
      id: 'register.page-title',
      defaultMessage: 'Register a new account - myUplink',
    });
  });

  return (
    <Formik
      initialValues={{
        username: values.username || '',
        password: '',
        passwordConfirm: '',
        acceptedPrivacyPolicy: values.acceptedPrivacyPolicy === latestAgreements.privacyPolicy,
        acceptedAgreement: values.acceptedAgreement === latestAgreements.termsOfService,
      }}
      validationSchema={schemaStepOne}
      onSubmit={onSubmit}
      enableReinitialized={true}
    >
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
        <form onSubmit={handleSubmit} className="form">
          <h1 id="create-account" className="form__heading">
            <FormattedMessage id="register.heading" defaultMessage="Create your account" />
          </h1>
          <FormattedMessage id="register.input-email" defaultMessage="E-mail">
            {placeholder => (
              <Input
                placeholder={placeholder}
                type="text"
                name="username"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.username}
                aria-required="true"
                error={
                  errors.username && touched.username && formatErrorMessage(intl, errorMessagesStepOne, errors.username)
                }
              />
            )}
          </FormattedMessage>
          <FormattedMessage id="register.input-password" defaultMessage="Password">
            {placeholder => (
              <Input
                placeholder={placeholder}
                type="password"
                name="password"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
                aria-required="true"
                error={
                  errors.password && touched.password && formatErrorMessage(intl, errorMessagesStepOne, errors.password)
                }
              />
            )}
          </FormattedMessage>
          <FormattedMessage id="register.input-password-confirm" defaultMessage="Password (confirm)">
            {placeholder => (
              <Input
                placeholder={placeholder}
                type="password"
                name="passwordConfirm"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.passwordConfirm}
                aria-required="true"
                error={
                  errors.passwordConfirm &&
                  touched.passwordConfirm &&
                  formatErrorMessage(intl, errorMessagesStepOne, errors.passwordConfirm)
                }
              />
            )}
          </FormattedMessage>
          <FormattedMessage id="register.user-terms" defaultMessage="I accept the Terms of Service">
            {title => (
              <Checkbox
                id="acceptedAgreement"
                name="acceptedAgreement"
                className="underline"
                checked={values.acceptedAgreement}
                aria-required="true"
                onChange={handleChange}
                label={title}
                onLabelClick={() => toggleLegalModal('terms-of-service')}
                tabIndex={0}
                error={
                  errors.acceptedAgreement && touched.acceptedAgreement
                    ? formatErrorMessage(intl, errorMessagesStepOne, errors.acceptedAgreement)
                    : ''
                }
              />
            )}
          </FormattedMessage>
          <FormattedMessage id="register.privacy-policy" defaultMessage="I have read and understood the Privacy Policy">
            {title => (
              <Checkbox
                id="acceptedPrivacyPolicy"
                name="acceptedPrivacyPolicy"
                className="underline"
                checked={values.acceptedPrivacyPolicy}
                aria-required="true"
                onChange={handleChange}
                label={title}
                onLabelClick={() => toggleLegalModal('privacy-policy')}
                tabIndex={0}
                error={
                  errors.acceptedPrivacyPolicy && touched.acceptedPrivacyPolicy
                    ? formatErrorMessage(intl, errorMessagesStepOne, errors.acceptedPrivacyPolicy)
                    : ''
                }
              />
            )}
          </FormattedMessage>
          <div className="button-wrapper--large">
            {requestError && (
              <p className="text-danger">{formatErrorMessage(intl, errorMessagesStepOne, requestError)}</p>
            )}
            <Button className="button--secondary" type="submit" disabled={isSubmitting}>
              <FormattedMessage id="register.next" defaultMessage="Next" />
            </Button>
            <div className="form__paragraph">
              <FormattedMessage id="register.or" defaultMessage="or" />
            </div>
            <Link tagName="button" className="button button--primary login" to="/login">
              <FormattedMessage id="login.login" defaultMessage="Login" />
            </Link>
          </div>
        </form>
      )}
    </Formik>
  );
};

RegisterFormStepOneInner.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  values: PropTypes.object,
  requestError: PropTypes.string,
  toggleLegalModal: PropTypes.func.isRequired,
  intl: PropTypes.object,
  latestAgreements: PropTypes.object.isRequired,
};

const RegisterFormStepOne = injectIntl(RegisterFormStepOneInner);

const schemaStepTwo = yup.object().shape({
  fullName: yup
    .string()
    .trim()
    .required('fullname.required')
    .matches(userNameRegex, 'fullname.invalid-format')
    .max(defaultMaxLength, 'fullname.max-length'),
  lineOne: yup
    .string()
    .trim()
    .required('address.required')
    .matches(address.pattern, 'address.line-one.invalid-format')
    .max(defaultMaxLength, 'address.line-one.max-length'),
  lineTwo: yup
    .string()
    .trim()
    .matches(address.pattern, 'address.line-two.invalid-format')
    .max(defaultMaxLength, 'address.line-two.max-length'),
  city: yup
    .string()
    .trim()
    .required('city.required')
    .matches(address.pattern, 'city.invalid-format')
    .max(address.cityNameMaxLength, 'city.max-length'),
  postalCode: yup
    .string()
    .trim()
    .required('postal-code.required')
    .matches(address.postalCode.regex, 'postal-code.regexp')
    .max(address.postalCode.maxLength, 'postal-code.max-length'),
  country: yup.object().nullable().required('country.required'),
  region: yup
    .string()
    .trim()
    .when('country', {
      is: country => country && country.regions && country.regions.length > 0,
      then: yup
        .string()
        .required('region.required')
        .max(address.regionNameMaxLength, 'region.maxlength')
        .matches(address.pattern, 'region.invalid-format'),
      otherwise: yup
        .string()
        .max(address.regionNameMaxLength, 'region.maxlength')
        .matches(address.pattern, 'region.invalid-format'),
    }),
});

const errorMessagesStepTwo = {
  'fullname.required': {
    id: 'full-name.error.validation.required',
    defaultMessage: 'Full name is mandatory field',
  },
  'fullname.invalid-format': {
    id: 'full-name.error.validation.invalid-format',
    defaultMessage: 'Full name has invalid format',
  },
  'fullname.max-length': {
    id: 'full-name.error.validation.maxlength',
    defaultMessage: 'Full name cannot be longer than 255 characters',
  },
  'address.required': {
    id: 'address.error.validation.line-one.required',
    defaultMessage: 'First address line is mandatory field',
  },
  'address.line-one.invalid-format': {
    id: 'address.error.validation.line-one.invalid-format',
    defaultMessage: 'Address line 1 has invalid format',
  },
  'address.line-two.invalid-format': {
    id: 'address.error.validation.line-two.invalid-format',
    defaultMessage: 'Address line 2 has invalid format',
  },
  'address.line-one.max-length': {
    id: 'address.error.validation.line-one.maxlength',
    defaultMessage: 'Address line 1 cannot be longer than 255 characters',
  },
  'address.line-two.max-length': {
    id: 'address.error.validation.line-two.maxlength',
    defaultMessage: 'Address line 2 cannot be longer than 255 characters',
  },
  'city.required': {
    id: 'city.error.validation.required',
    defaultMessage: 'City is mandatory field',
  },
  'city.invalid-format': {
    id: 'city.error.validation.invalid-format',
    defaultMessage: 'City has invalid format',
  },
  'city.max-length': {
    id: 'city.error.validation.maxlength',
    defaultMessage: 'City cannot be longer than 100 characters',
  },
  'postal-code.required': {
    id: 'postal-code.error.validation.required',
    defaultMessage: 'Postal code is mandatory field',
  },
  'postal-code.regexp': {
    id: 'postal-code.error.validation.regexp',
    defaultMessage: 'Postal code has an invalid format',
  },
  'postal-code.max-length': {
    id: 'postal-code.error.validation.maxlength',
    defaultMessage: 'Postal code cannot be longer than 20 characters',
  },
  'region.required': {
    id: 'region.error.validation.required',
    defaultMessage: 'Region is mandatory field',
  },
  'region.invalid-format': {
    id: 'region.error.validation.invalid-format',
    defaultMessage: 'Region has invalid format',
  },
  'region.max-length': {
    id: 'region.error.validation.maxlength',
    defaultMessage: 'Region cannot be longer than 100 characters',
  },
  'country.required': {
    id: 'country.error.validation.required',
    defaultMessage: 'Country is mandatory field',
  },
  unknown: {
    id: 'generic.error.request.unknown',
    defaultMessage: 'An error has occurred. Try again later.',
  },
};

const RegisterFormStepTwoInner = ({ countries, onSubmit, values, stepBack, requestError, intl }) => {
  const [countryRegion, setCountryRegion] = useState([]);

  useEffect(() => {
    document.title = intl.formatMessage({
      id: 'register.page-title.step2',
      defaultMessage: 'Enter your address - myUplink',
    });
  });

  return (
    <Formik
      initialValues={{
        fullName: values.fullName || '',
        lineOne: values.lineOne || '',
        lineTwo: values.lineTwo || '',
        city: values.city || '',
        region: values.region || '',
        postalCode: values.postalCode || '',
        country: values.country || null,
      }}
      validationSchema={schemaStepTwo}
      onSubmit={onSubmit}
      enableReinitialized={true}
      countries={countries}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        status,
        isSubmitting,
        setFieldValue,
        setFieldTouched,
        /* and other goodies */
      }) => (
        <form onSubmit={handleSubmit} className="form">
          <h1 className="form__heading">
            <FormattedMessage id="register.heading" defaultMessage="Create your account" />
          </h1>
          {status && status.msg && <p className="form__error-message">{status.msg}</p>}
          <div className="form__inputWrapper">
            <FormattedMessage id="register.input-fullName" defaultMessage="Full name">
              {placeholder => (
                <Input
                  placeholder={placeholder}
                  type="text"
                  name="fullName"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.fullName}
                  aria-required="true"
                  error={
                    errors.fullName &&
                    touched.fullName &&
                    formatErrorMessage(intl, errorMessagesStepTwo, errors.fullName)
                  }
                />
              )}
            </FormattedMessage>
            <FormattedMessage id="register.input-addressLineOne" defaultMessage="Address Line 1">
              {placeholder => (
                <Input
                  placeholder={placeholder}
                  type="text"
                  name="lineOne"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.lineOne}
                  aria-required="true"
                  error={
                    errors.lineOne && touched.lineOne && formatErrorMessage(intl, errorMessagesStepTwo, errors.lineOne)
                  }
                />
              )}
            </FormattedMessage>
            <FormattedMessage id="register.input-addressLineTwo" defaultMessage="Address Line 2 (Optional)">
              {placeholder => (
                <Input
                  placeholder={placeholder}
                  type="text"
                  name="lineTwo"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.lineTwo}
                  error={
                    errors.lineTwo && touched.lineTwo && formatErrorMessage(intl, errorMessagesStepTwo, errors.lineTwo)
                  }
                />
              )}
            </FormattedMessage>
            <FormattedMessage id="register.input-city" defaultMessage="City">
              {placeholder => (
                <Input
                  placeholder={placeholder}
                  type="text"
                  name="city"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.city}
                  aria-required="true"
                  error={errors.city && touched.city && formatErrorMessage(intl, errorMessagesStepTwo, errors.city)}
                />
              )}
            </FormattedMessage>
            <FormattedMessage id="register.input-postalCode" defaultMessage="Postal Code">
              {placeholder => (
                <Input
                  placeholder={placeholder}
                  type="text"
                  name="postalCode"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.postalCode}
                  aria-required="true"
                  error={
                    errors.postalCode &&
                    touched.postalCode &&
                    formatErrorMessage(intl, errorMessagesStepTwo, errors.postalCode)
                  }
                />
              )}
            </FormattedMessage>
            <FormattedMessage id="register.input-country" defaultMessage="Country">
              {placeholder => (
                <InputDropdown
                  placeholder={placeholder}
                  id={Date.now().toString(36) + Math.random().toString(36).substr(2)}
                  name="country"
                  items={countries}
                  aria-required="true"
                  error={
                    errors.country && touched.country && formatErrorMessage(intl, errorMessagesStepTwo, errors.country)
                  }
                  labelGetter={e => e.name}
                  onSelect={c => {
                    setFieldTouched('region', false);
                    setFieldValue('region', '');
                    if (typeof c !== 'string') {
                      c.regions.length > 0 ? setCountryRegion([noRegion, ...c.regions]) : setCountryRegion(c.regions);
                      setFieldValue('country', c);
                      return;
                    }

                    const matchedCountries =
                      (c &&
                        countries.filter(country => {
                          return country.name.toLowerCase() === c.toLowerCase().trim();
                        })) ||
                      [];
                    if (matchedCountries.length > 0) {
                      const country = matchedCountries.pop();
                      country.regions.length > 0
                        ? setCountryRegion([noRegion, ...country.regions])
                        : setCountryRegion(country.regions);
                      setFieldValue('country', country);
                    } else {
                      setCountryRegion([]);
                      setFieldValue('country', null);
                    }
                  }}
                  itemFilter={(country, input) => {
                    return !input || country.name.toLowerCase().startsWith(input.toLowerCase().trim());
                  }}
                />
              )}
            </FormattedMessage>
            <FormattedMessage
              id={countryRegion && countryRegion.length > 0 ? 'label.region' : 'register.input-region'}
              defaultMessage="Region (Optional)"
            >
              {placeholder =>
                countryRegion && countryRegion.length > 0 ? (
                  <InputDropdown
                    placeholder={placeholder}
                    id={Date.now().toString(36) + Math.random().toString(36).substr(2)}
                    name="region"
                    items={countryRegion}
                    error={
                      errors.region && touched.region && formatErrorMessage(intl, errorMessagesStepTwo, errors.region)
                    }
                    labelGetter={e => e.name}
                    onSelect={c => {
                      if (typeof c !== 'string') {
                        setFieldValue('region', c.name);
                        return;
                      }
                      const matchedRegion =
                        (c &&
                          countryRegion.filter(region => {
                            return region.name.toLowerCase() === c.toLowerCase().trim();
                          })) ||
                        [];
                      matchedRegion.length > 0
                        ? setFieldValue('region', matchedRegion.pop().name)
                        : setFieldValue('region', '');
                    }}
                    itemFilter={(region, input) => {
                      return !input || region.name.toLowerCase().startsWith(input.toLowerCase().trim());
                    }}
                  />
                ) : (
                  <Input
                    placeholder={placeholder}
                    type="text"
                    name="region"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.region}
                    error={
                      errors.region && touched.region && formatErrorMessage(intl, errorMessagesStepTwo, errors.region)
                    }
                  />
                )
              }
            </FormattedMessage>
            {requestError && (
              <p className="text-danger">{formatErrorMessage(intl, errorMessagesStepTwo, requestError)}</p>
            )}
            <div className="button-wrapper--large">
              <Button className="button--secondary" type="submit" disabled={isSubmitting}>
                <FormattedMessage id="register.next" defaultMessage="Next" />
              </Button>

              <div className="form__paragraph">
                <FormattedMessage id="register.or" defaultMessage="or" />
              </div>
              <Button className="button--primary" type="button" onClick={() => stepBack(values)}>
                <FormattedMessage id="register.step_back" defaultMessage="Back to the previous step" />
              </Button>
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
};

RegisterFormStepTwoInner.propTypes = {
  values: PropTypes.object,
  stepBack: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  requestError: PropTypes.string,
  countries: PropTypes.arrayOf(PropTypes.object),
  intl: PropTypes.object,
};

const RegisterFormStepTwo = injectIntl(RegisterFormStepTwoInner);

class Register extends React.Component {
  state = {
    step: 1,
    username: '',
    password: '',
    lineOne: '',
    lineTwo: '',
    city: '',
    region: '',
    postalCode: '',
    country: null,
    showPasswordConfirmation: false,
    showMigrationConfirmation: false,
    showError: false,
  };

  componentDidMount() {
    if (isAzureB2CEnabled) {
      this.props.history.push('/sign-up');
    }
  }

  static propTypes = {
    createUser: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    error: PropTypes.string,
    migrateUserError: PropTypes.any,
    validateOldUserCredentials: PropTypes.func.isRequired,
    checkOldUserEmail: PropTypes.func.isRequired,
    migrateOldUser: PropTypes.func.isRequired,
    setUpUserAgreements: PropTypes.func.isRequired,
    checkUserEmail: PropTypes.func.isRequired,
    user: PropTypes.any,
    oldUserId: PropTypes.any,
    countries: PropTypes.arrayOf(PropTypes.object),
    lastUsedLanguage: PropTypes.string,
    login: PropTypes.func.isRequired,
    setUserDataState: PropTypes.func.isRequired,
    latestAgreements: PropTypes.object.isRequired,
  };

  onNext = async (values, { setSubmitting }) => {
    setSubmitting(true);
    this.setState({ ...this.state, showError: true });
    if (await this.props.checkUserEmail(values.username.trim())) {
      setSubmitting(false);
      return;
    }
    if (await this.props.checkOldUserEmail(values.username.trim())) {
      this.setState({
        showMigrationConfirmation: true,
        username: values.username.trim(),
        acceptedPrivacyPolicy: values.acceptedPrivacyPolicy,
        acceptedAgreement: values.acceptedAgreement,
        showPasswordConfirmation: false,
        step: 1,
        password: values.password,
      });
      setSubmitting(false);
      return;
    }

    const { username, password, acceptedPrivacyPolicy, acceptedAgreement } = values;
    this.setState({
      ...this.state,
      step: 2,
      username: username,
      password: password,
      acceptedPrivacyPolicy: acceptedPrivacyPolicy,
      acceptedAgreement: acceptedAgreement,
    });
    setSubmitting(false);
  };

  stepBack = values => {
    this.setState({
      ...values,
      step: 1,
    });
  };

  onPasswordConfirmationSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);
    const email = values.email.trim();
    const password = values.password.trim();
    const newPassword = this.state.password.trim(); // password that was entered on Register form

    if (
      (await this.props.validateOldUserCredentials(email, password)) &&
      (await this.props.migrateOldUser(
        this.props.oldUserId,
        this.props.latestAgreements.termsOfService,
        this.props.latestAgreements.privacyPolicy,
        newPassword,
      ))
    ) {
      this.setState({ ...this.state, showPasswordConfirmation: false });

      await this.props.setUserDataState(UserDataState.REQUESTED);
      if (await this.props.login(email, newPassword)) {
        this.props.history.push('/');
      } else {
        await this.props.setUserDataState(UserDataState.EMPTY);
      }
    }
    setSubmitting(false);
  };

  togglePasswordConfirmationModal = () => {
    this.setState({ ...this.state, showPasswordConfirmation: !this.state.showPasswordConfirmation });
  };

  onSubmit = async (values, { setSubmitting, setStatus }) => {
    setSubmitting(true);
    const { username, password } = this.state;
    const { fullName, ...address } = values;
    const currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const data = {
      address: address,
      name: fullName,
      currentTimeZone: currentTimeZone,
      email: username,
      password: password,
      acceptedAgreement: this.props.latestAgreements.termsOfService,
      acceptedPrivacyPolicy: this.props.latestAgreements.privacyPolicy,
      preferredLanguage: this.props.lastUsedLanguage,
    };
    const res = await this.props.createUser(data);
    if (res) {
      this.props.history.push('/confirmation-link');
      return;
    }

    this.setState({
      password: '',
      step: 1,
    });
    setStatus({ msg: res });
    setSubmitting(false);
  };

  onMigrationConfirm = async () => {
    this.props.history.push('/login');
    // this.setState({ ...this.state, showMigrationConfirmation: false, showPasswordConfirmation: true });
  };

  onMigrationDiscard = async () => {
    this.setState({ showMigrationConfirmation: false, showPasswordConfirmation: false });
  };

  toggleLegalModal = legalType => {
    this.setState({
      showLegalInfoModal: !this.state.showLegalInfoModal,
      legalInfoType: legalType,
    });
  };

  render() {
    const { step } = this.state;
    let form = null;
    const error = this.state.showError ? this.props.error : '';
    if (step === 1) {
      form = (
        <RegisterFormStepOne
          values={this.state}
          onSubmit={this.onNext}
          requestError={error}
          toggleLegalModal={this.toggleLegalModal}
          latestAgreements={this.props.latestAgreements}
        />
      );
    } else if (step === 2) {
      form = (
        <RegisterFormStepTwo
          countries={this.props.countries}
          values={this.state}
          onSubmit={this.onSubmit}
          stepBack={this.stepBack}
          requestError={error}
        />
      );
    }
    return (
      <>
        {this.state.showPasswordConfirmation && this.state.username && (
          <PasswordConfirmation
            email={this.state.username}
            onSubmit={this.onPasswordConfirmationSubmit}
            requestError={this.props.migrateUserError}
            toggleModal={this.togglePasswordConfirmationModal}
            showModal={this.state.showPasswordConfirmation}
          />
        )}
        {this.state.showMigrationConfirmation && this.state.username && (
          <MigrationConfirmation
            isShown={this.state.showMigrationConfirmation}
            onDiscard={this.onMigrationDiscard}
            onConfirm={this.onMigrationConfirm}
          ></MigrationConfirmation>
        )}
        {form}
        {this.state.showLegalInfoModal && this.state.legalInfoType && (
          <Modal
            show={this.state.showLegalInfoModal}
            backdrop={true}
            onHide={() => this.setState({ showLegalInfoModal: !this.state.showLegalInfoModal })}
          >
            <ModalBody>
              <FormattedHTMLMessage id={this.state.legalInfoType} />
            </ModalBody>
          </Modal>
        )}
      </>
    );
  }
}

const RoutedRegister = withRouter(Register);

export default connect(
  ({ register, app: { countries, lastUsedLanguage, latestAgreements } }) => ({
    ...register,
    countries,
    lastUsedLanguage,
    latestAgreements,
  }),
  {
    createUser,
    validateOldUserCredentials,
    checkOldUserEmail,
    migrateOldUser,
    setUpUserAgreements,
    checkUserEmail,
    login,
    setUserDataState,
  },
)(RoutedRegister);
