import React from 'react';
import PropTypes from 'prop-types';
import { ModalBody, ModalFooter } from 'react-bootstrap';
import { FormattedMessage, FormattedHTMLMessage, injectIntl } from 'react-intl';
import { Formik } from 'formik';
import * as yup from 'yup';
import Modal from '../../../layout/Modal';
import { Button, Checkbox } from 'jpi-cloud-web-ui-components';
import { formatErrorMessage } from '../../../../localization/message-formatting';

const schemaStepOne = yup.object().shape({
  acceptedAgreement: yup.boolean().required('tos.required').oneOf([true], 'tos.required'),
  acceptedPrivacyPolicy: yup.boolean().required('privacy-policy.required').oneOf([true], 'privacy-policy.required'),
});

const errorMessages = {
  'tos.required': {
    id: 'agreements.error.validation.tos.required',
    defaultMessage: 'Must accept Terms and Conditions',
  },
  'privacy-policy.required': {
    id: 'agreements.error.validation.privacy-policy.required',
    defaultMessage: 'Must accept Privacy Policy',
  },
  unknown: {
    id: 'generic.error.request.unknown',
    defaultMessage: 'An error has occurred. Try again later.',
  },
};

class AcceptAgreementConfirmationInner extends React.Component {
  static propTypes = {
    acceptedAgreements: PropTypes.object.isRequired,
    latestAgreements: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    showModal: PropTypes.any.isRequired,
    toggleModal: PropTypes.func.isRequired,
    requestError: PropTypes.object,
    intl: PropTypes.object,
  };

  state = {
    showLegalInfoModal: false,
    legalInfoType: null,
  };

  toggleLegalModal = legalType => {
    this.setState({
      showLegalInfoModal: !this.state.showLegalInfoModal,
      legalInfoType: legalType,
    });
  };

  render() {
    const { acceptedAgreements, latestAgreements, onSubmit, showModal, toggleModal, intl } = this.props;

    return (
      <div>
        <Formik
          initialValues={{
            acceptedAgreement: acceptedAgreements.acceptedAgreement === latestAgreements.termsOfService,
            acceptedPrivacyPolicy: acceptedAgreements.acceptedPrivacyPolicy === latestAgreements.privacyPolicy,
          }}
          validationSchema={schemaStepOne}
          onSubmit={onSubmit}
          enableReinitialized={true}
        >
          {({ values, errors, isSubmitting, handleChange, handleSubmit }) => (
            <Modal show={showModal} backdrop={true} onHide={toggleModal}>
              <form onSubmit={handleSubmit}>
                <ModalBody className="acceptedAgreementsForm">
                  <h2>
                    <FormattedMessage
                      id="acceptedAgreements.title"
                      defaultMessage="You should accept the following policy and agreements"
                    />
                  </h2>
                  <Checkbox
                    id="acceptedAgreement"
                    name="acceptedAgreement"
                    className="underline"
                    onChange={handleChange}
                    checked={values.acceptedAgreement}
                    label={<FormattedMessage id="register.user-terms" defaultMessage="I accept the Terms of Service" />}
                    onLabelClick={() => this.toggleLegalModal('terms-of-service')}
                    error={
                      errors.acceptedAgreement ? formatErrorMessage(intl, errorMessages, errors.acceptedAgreement) : ''
                    }
                  />
                  <Checkbox
                    id="acceptedPrivacyPolicy"
                    name="acceptedPrivacyPolicy"
                    className="underline"
                    onChange={handleChange}
                    checked={values.acceptedPrivacyPolicy}
                    label={
                      <FormattedMessage
                        id="register.privacy-policy"
                        defaultMessage="I have read and understood the Privacy Policy"
                      />
                    }
                    onLabelClick={() => this.toggleLegalModal('privacy-policy')}
                    error={
                      errors.acceptedPrivacyPolicy
                        ? formatErrorMessage(intl, errorMessages, errors.acceptedPrivacyPolicy)
                        : ''
                    }
                  />
                </ModalBody>
                <ModalFooter>
                  <Button className="button--default" type="button" onClick={toggleModal}>
                    <FormattedMessage id="acceptAgreements.buttons.cancel" defaultMessage="Cancel" />
                  </Button>
                  <Button className="button--secondary" type="submit" disabled={isSubmitting}>
                    <FormattedMessage
                      id="acceptAgreements.buttons.accept"
                      defaultMessage="Accept"
                      description="Accept agreements button"
                    />
                  </Button>
                </ModalFooter>
              </form>
            </Modal>
          )}
        </Formik>
        {this.state.showLegalInfoModal && this.state.legalInfoType && (
          <Modal
            show={this.state.showLegalInfoModal}
            backdrop={true}
            onHide={() => this.setState({ showLegalInfoModal: !this.state.showLegalInfoModal })}
          >
            <ModalBody>
              <FormattedHTMLMessage id={this.state.legalInfoType} />
            </ModalBody>
          </Modal>
        )}
      </div>
    );
  }
}

const AcceptAgreementConfirmation = injectIntl(AcceptAgreementConfirmationInner);

export default AcceptAgreementConfirmation;
