const daysOfWeek = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

export const MONDAY = 0;
export const TUESDAY = 1;
export const WEDNESDAY = 2;
export const THURSDAY = 3;
export const FRIDAY = 4;
export const SATURDAY = 5;
export const SUNDAY = 6;

export const dayIndexToDeviceDay = dayIndex => {
  const normDay = humanStringToDayIndex(dayIndex);

  return daysOfWeek[normDay].replace(/^\w/, c => c.toUpperCase());
};

export const humanStringToDayIndex = day => {
  if (day === 'Monday-Sunday') {
    return MONDAY;
  }
  if (day === 'Monday-Friday') {
    return MONDAY;
  }
  if (day === 'Saturday-Sunday') {
    return SATURDAY;
  }
  return typeof day === 'string' ? daysOfWeek.indexOf(day.toLowerCase()) : day;
};

export const isDayOfWeek = (day, stringDay, index) => {
  return (typeof day === 'string' && day.toLowerCase() === stringDay) || day === index;
};

export const getPrevDay = dayOfWeek => {
  return humanStringToDayIndex(dayOfWeek) == MONDAY ? SUNDAY : humanStringToDayIndex(dayOfWeek) - 1;
};

export const getNextDay = dayOfWeek => {
  return humanStringToDayIndex(dayOfWeek) >= SUNDAY ? MONDAY : humanStringToDayIndex(dayOfWeek) + 1;
};

export const EVENT_INTERSECTION = {
  DELETE: 'delete',
  CUT: 'cut',
  REDUCE_STOP_TIME: 'reduceStopTime',
  INCREASE_START_TIME: 'increaseStartTime',
  OTHER_PART_DELETE: 'other_part_delete',
};

export const DEFAULT_WEEK_FORMAT_ID = 'mon,tue,wed,thu,fri,sat,sun';

export const WeekFormats = [
  {
    id: DEFAULT_WEEK_FORMAT_ID,
    days: [
      {
        name: 'Monday',
        comparer: day => isDayOfWeek(day, 'monday', MONDAY),
      },
      {
        name: 'Tuesday',
        comparer: day => isDayOfWeek(day, 'tuesday', TUESDAY),
      },
      {
        name: 'Wednesday',
        comparer: day => isDayOfWeek(day, 'wednesday', WEDNESDAY),
      },
      {
        name: 'Thursday',
        comparer: day => isDayOfWeek(day, 'thursday', THURSDAY),
      },
      {
        name: 'Friday',
        comparer: day => isDayOfWeek(day, 'friday', FRIDAY),
      },
      {
        name: 'Saturday',
        comparer: day => isDayOfWeek(day, 'saturday', SATURDAY),
      },
      {
        name: 'Sunday',
        comparer: day => isDayOfWeek(day, 'sunday', SUNDAY),
      },
    ],
  },
  {
    id: 'mon-fri,sat,sun',
    days: [
      {
        name: 'Monday-Friday',
        comparer: day =>
          isDayOfWeek(day, 'monday', MONDAY) ||
          isDayOfWeek(day, 'tuesday', TUESDAY) ||
          isDayOfWeek(day, 'wednesday', WEDNESDAY) ||
          isDayOfWeek(day, 'thursday', THURSDAY) ||
          isDayOfWeek(day, 'friday', FRIDAY),
      },
      {
        name: 'Saturday',
        comparer: day => isDayOfWeek(day, 'saturday', SATURDAY),
      },
      {
        name: 'Sunday',
        comparer: day => isDayOfWeek(day, 'sunday', SUNDAY),
      },
    ],
  },
  {
    id: 'mon-sun',
    days: [
      {
        name: 'Monday-Sunday',
        comparer: () => true,
      },
    ],
  },
  {
    id: 'mon-fri,sat-sun',
    days: [
      {
        name: 'Monday-Friday',
        comparer: day =>
          isDayOfWeek(day, 'monday', MONDAY) ||
          isDayOfWeek(day, 'tuesday', TUESDAY) ||
          isDayOfWeek(day, 'wednesday', WEDNESDAY) ||
          isDayOfWeek(day, 'thursday', THURSDAY) ||
          isDayOfWeek(day, 'friday', FRIDAY),
      },
      {
        name: 'Saturday-Sunday',
        comparer: day => isDayOfWeek(day, 'saturday', SATURDAY) || isDayOfWeek(day, 'sunday', SUNDAY),
      },
    ],
  },
];
