import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './pagination.scss';

class Pagination extends React.Component {
  static propTypes = {
    totalPages: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
  };

  state = {
    currentPage: 0,
  };

  onPageChange = pageIndex => {
    this.setState({ currentPage: pageIndex });
    this.props.onPageChange(pageIndex);
  };

  render() {
    const { totalPages } = this.props;
    const { currentPage } = this.state;

    return (
      <div className="pagination">
        {[...Array(totalPages)].map((_, i) => (
          <div
            className={classNames({ page: true, 'selected-page': i === currentPage })}
            key={`page#${i}`}
            onClick={() => this.onPageChange(i)}
          >
            {i + 1}
          </div>
        ))}
      </div>
    );
  }
}

export default Pagination;
