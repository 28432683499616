import React from 'react';

const supportedLanguages = [
  'en',
  'sv',
  'de',
  'cs',
  'da',
  'es',
  'et',
  'fi',
  'fr',
  'hu',
  'it',
  'nb',
  'nl',
  'pl',
  'ro',
  'ru',
  'sk',
  'sl',
];
const buttonTextMap = new Map([
  ['en', 'Get instant help!'],
  ['sv', 'Få hjälp direkt!'],
  ['de', 'Holen Sie sich sofortige Hilfe!'],
  ['cs', 'Získejte okamžitou pomoc!'],
  ['da', 'Få øjeblikkelig hjælp!'],
  ['es', '¡Obtenga ayuda instantánea!'],
  ['et', 'Hankige kohest abi!'],
  ['fi', 'Hanki välitöntä apua!'],
  ['fr', "Obtenez de l'aide instantanée !"],
  ['hu', 'Kérjen azonnali segítséget!'],
  ['it', 'Ottieni assistenza immediata!'],
  ['nb', 'Få øyeblikkelig hjelp!'],
  ['nl', 'Krijg direct hulp!'],
  ['pl', 'Uzyskaj natychmiastową pomoc!'],
  ['ro', 'Obțineți ajutor imediat!'],
  ['ru', 'Получите мгновенную помощь!'],
  ['sk', 'Získajte okamžitú pomoc!'],
  ['sl', 'Poiščite takojšnjo pomoč!'],
]);

const brandKey = null;
const nibeClientId = '65d02be6fb0a6276adc536fa7b7d30c619387bae';

const useMavenoid = () => {
  React.useEffect(() => {
    // --- begin mavenoid embedded troubleshooter ---
    (function (m, a, v, e, n, o, i, d) {
      d = m.createElement(e);
      d.async = true;
      d.src = 'https://app.mavenoid.com' + v;
      i = m.getElementsByTagName(e)[0];
      i.parentNode.insertBefore(d, i);
      n[o] = n[o] || [];
    })(document, 0, '/embedded/embedded.js', 'script', window, 'mavenoid');
    // --- end mavenoid embedded troubleshooter ---

    window.mavenoid.config = {
      noIframe: false,
      clientId: nibeClientId,
    };
  }, []);

  const mount = (email, language) => {
    const lang = supportedLanguages.includes(language) ? language : 'en';
    window.mavenoid &&
      window.mavenoid.push({
        event: 'troubleshooter-open',
        productId: 2218190,
        orgName: 'NIBE',
        position: 'right',
        defaultLang: lang,
        buttonText: buttonTextMap.get(lang) ?? 'Get instant help!',
        themeKey: brandKey ?? 1,
        initFormData: { ...(email ? { user_email: email } : {}) },
      });
  };
  const unmount = () => window.mavenoid && window.mavenoid.push({ event: 'troubleshooter-unmount' });

  return { mount, unmount };
};

export default useMavenoid;
