import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { ModalBody, ModalFooter } from 'react-bootstrap';
import Modal from '../../../layout/Modal';
import { Button } from 'jpi-cloud-web-ui-components';

const ChangeLogModal = props => {
  return (
    <Modal show={props.showModal} backdrop={true} onHide={props.toggleModal}>
      <ModalBody align="center">
        <p>
          <FormattedMessage
            id={'firmwares.ModalWindowText'}
            defaultMessage="Change log for this firmware could not be found."
          />
        </p>
      </ModalBody>
      <ModalFooter>
        <Button className="button--default" onClick={props.toggleModal} type="button">
          <FormattedMessage id="firmwares.ModalWindowButton" defaultMessage="Cancel" />
        </Button>
      </ModalFooter>
    </Modal>
  );
};

ChangeLogModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
};

export default ChangeLogModal;
