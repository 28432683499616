import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Modal from '../../../../layout/Modal';

const AccountMigrationModal = ({ isOpen, onClose, children, action }) => {
  if (!isOpen) return null;

  return (
    <Modal show={isOpen} backdrop="static" onHide={onClose}>
      <div className="account-migration-modal-wrapper">
        <button className="account-migration-modal-close-button">
          <i className="fas fa-times" onClick={onClose} />
        </button>
        <h4 className="account-migration-modal-header">
          <FormattedMessage id="accountMigrationSection.modalTitle" defaultMessage="Account Migration" />
        </h4>
        <div className="account-migration-modal-content">{children}</div>
        {action && <div className="account-migration-modal-footer">{action}</div>}
      </div>
    </Modal>
  );
};

AccountMigrationModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  children: PropTypes.node,
  action: PropTypes.node,
};

export default AccountMigrationModal;
