export const sortByPrio = parameters => {
  var sortedParameters = [...parameters];

  sortedParameters.sort((paramA, paramB) => {
    if (paramA.prio && !paramB.prio) {
      return -1;
    }

    if (!paramA.prio && paramB.prio) {
      return 1;
    }

    if (paramA.prio && paramB.prio) {
      if (+paramA.prio < +paramB.prio) {
        return -1;
      }
      if (+paramA.prio > +paramB.prio) {
        return 1;
      }
    }
  });

  return sortedParameters;
};
