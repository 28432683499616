import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Formik } from 'formik';
import * as yup from 'yup';

import { Button, Input, Title } from 'jpi-cloud-web-ui-components';
import ItemDate from './ItemDate';
import { formatErrorMessage } from '../../../localization/message-formatting';

const RegisteredProducts = ({ productList, locale }) => (
  <table className="table table-bordered registered-products">
    <thead className="thead-light">
      <tr>
        <th scope="col">
          <FormattedMessage id="productRegistration.serial-number" defaultMessage="Serial Number" />
        </th>
        <th scope="col">
          <FormattedMessage id="productRegistration.installation-date" defaultMessage="Installation Date" />
        </th>
        <th scope="col">
          <FormattedMessage id="productRegistration.warranty-end-date" defaultMessage="Warranty End Date" />
        </th>
      </tr>
    </thead>
    <tbody>
      {productList &&
        productList.length > 0 &&
        productList.map(p => (
          <tr key={p.serialNumber}>
            <td>
              <Link to={`/product-registration-info/${p.serialNumber}`}>{p.serialNumber}</Link>
            </td>
            <td>
              <ItemDate timestamp={p.installationDate} locale={locale} />
            </td>
            <td>
              <ItemDate timestamp={p.warrantyEndDate} locale={locale} />
            </td>
          </tr>
        ))}
    </tbody>
  </table>
);

RegisteredProducts.propTypes = {
  productList: PropTypes.arrayOf(PropTypes.object),
  locale: PropTypes.string.isRequired,
};

const serialNumberSearchSchema = yup.object().shape({
  serialNumber: yup
    .string()
    .trim()
    .max(20, 'serial-number.maxlength')
    .required('serial-number.error.validation.required'),
});

const errorMessages = {
  'serial-number.maxlength': {
    id: 'serial-number.error.validation.maxlength',
    defaultMessage: 'Serial number cannot be longer than 20 characters',
  },
  'serial-number.required': {
    id: 'serial-number.error.validation.required',
    defaultMessage: 'Serial number is required',
  },
};

const FindProductFormInner = ({ initialValues, onSubmit, intl }) => (
  <Formik initialValues={initialValues} validationSchema={serialNumberSearchSchema} onSubmit={onSubmit}>
    {({
      values,
      errors,
      handleChange,
      handleSubmit,
      isSubmitting,
      /* and other goodies */
    }) => (
      <form onSubmit={handleSubmit} className="form--half">
        <FormattedMessage id="productRegistration.serialNumber">
          {placeholder => (
            <Input
              name="serialNumber"
              type="text"
              placeholder={placeholder}
              value={values.serialNumber || ''}
              error={errors.serialNumber ? formatErrorMessage(intl, errorMessages, errors.serialNumber) : ''}
              onChange={handleChange}
            />
          )}
        </FormattedMessage>
        <div className="button-wrapper">
          <Button className="button--secondary" type="submit" disabled={isSubmitting}>
            <FormattedMessage id="productRegistration.findProduct" defaultMessage="Find Product" />
          </Button>
        </div>
      </form>
    )}
  </Formik>
);

FindProductFormInner.propTypes = {
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func,
  intl: PropTypes.object,
};

const FindProductForm = injectIntl(FindProductFormInner);

export const SerialNumberSearch = ({ onSubmit, productList, initialValues, locale }) => (
  <div className="product-registration step-1">
    <div className="product-registration__wrapper row">
      <div className="col-lg-12">
        <Title titleTranslationId="productRegistration.title" defaultMessage="Product Registration" />
      </div>
    </div>
    <div className="row">
      <div className="col-lg-12">
        {/*<p><FormattedMessage id="productRegistration.intro.1" defaultMessage="We are so confident in the quality and performance of our products that you can get a 5-year manufacturer's warranty for each heat pump. Registering your equipment is one of several requirements for obtaining the warranty. The manufacturer's warranty applies exclusively in compliance with the warranty conditions, professional commissioning and regular maintenance." /></p>*/}
        <p>
          <FormattedMessage
            id="productRegistration.intro.2"
            defaultMessage="On this page you will get an overview of all already registered products and you can register more. Only heat pumps can be registered as the main products of a system. Registration of accessories is not required."
          />
        </p>
      </div>
    </div>
    <div className="row">
      <div className="col-lg-12">
        <RegisteredProducts productList={productList} locale={locale} />
      </div>
    </div>
    <div className="row">
      <div className="col-lg-12">
        <h3>
          <FormattedMessage id="productRegistration.subtitle" defaultMessage="Register Your Product" />
        </h3>
      </div>
    </div>
    <div className="row">
      <div className="col-lg-12">
        <FindProductForm onSubmit={onSubmit} initialValues={initialValues} />
      </div>
    </div>
  </div>
);

SerialNumberSearch.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  productList: PropTypes.arrayOf(PropTypes.object),
  initialValues: PropTypes.object,
  locale: PropTypes.string.isRequired,
};
