import { Button } from 'jpi-cloud-web-ui-components';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import Dropdown from '../../../../inputs/Dropdown';
import NumberInput from '../../../../inputs/NumberInput';
import ToggleSwitch from '../../../../inputs/ToggleSwitch';

const SettingPresentationTypes = {
  slider: 'slider',
  plusminus: 'plusminus',
  enum: 'enum',
  onoff: 'onoff',
};

const SettingsInput = ({ setting, onChange }) => {
  const selectedValue = setting.value ?? setting.defaultValue;

  switch (setting.type.toLowerCase()) {
    case SettingPresentationTypes.plusminus:
    case SettingPresentationTypes.slider:
      return (
        <NumberInput
          placeholder=""
          type="number"
          className="shortText"
          maxVal={Number(setting.maxValue * Number(setting.scaleValue)).toFixed(Number(setting.decimalsNumber))}
          minVal={Number(setting.minValue * Number(setting.scaleValue)).toFixed(Number(setting.decimalsNumber))}
          stepVal={Number(setting.stepValue * Number(setting.scaleValue)) || 1}
          value={Number((selectedValue * Number(setting.scaleValue)).toFixed(Number(setting.decimalsNumber)))}
          onChange={value => onChange(setting, Number(value / setting.scaleValue).toFixed())}
          replaceValues={setting.enumValues.map(v => ({
            ...v,
            value: v.value * Number(setting.scaleValue),
          }))}
          unit={setting.unit}
        />
      );

    case SettingPresentationTypes.enum:
      return (
        <Dropdown
          id={setting.settingId}
          tooltipGetter={item => {
            item.value;
          }}
          items={setting.enumValues}
          selectedItem={setting.enumValues.find(elem => elem.value === selectedValue)}
          onSelect={dropItem => onChange(setting, dropItem.value)}
          labelGetter={e => e.text}
          placeholder="Auto"
        />
      );

    case SettingPresentationTypes.onoff:
      return (
        <ToggleSwitch
          isChecked={Boolean(setting.value)}
          onChange={value => onChange(setting, Number(value))}
          label={setting.name}
        />
      );

    default:
      return null;
  }
};
SettingsInput.propTypes = {
  setting: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

class ModePropertyEditor extends React.Component {
  static propTypes = {
    modeSettings: PropTypes.array,

    changeSettingsValue: PropTypes.func.isRequired,
    goNext: PropTypes.func.isRequired,
    goPrevious: PropTypes.func.isRequired,
  };

  onChange = (setting, value) => {
    this.props.changeSettingsValue(setting, value);
  };

  render() {
    const { modeSettings, goNext, goPrevious } = this.props;

    return (
      <Fragment>
        <div className="popup-body">
          <div className="popup-header">
            <p className="text">
              <FormattedMessage id="scheduling.edit-mode.title.step3.text" defaultMessage="Settings" />
            </p>
          </div>
          <div className="popup-main">
            {modeSettings.map(item => (
              <div key={item.settingId} className="edit-mode-item">
                <Button type="button" key={item.settingId} className="button--info">
                  {item.name}
                </Button>
                <div className="control-panel">
                  <SettingsInput setting={item} onChange={this.onChange} />
                </div>
              </div>
            ))}
          </div>
          <p className="text--small">
            <FormattedMessage
              id="scheduling.edit-mode.title.step3.text.info"
              defaultMessage="Changes to mode settings affect all scheduled items using this mode."
            />
          </p>
        </div>
        <div className="popup-bottom-panel">
          <Button className="button--secondary" type="submit" onClick={goNext}>
            <FormattedMessage id="scheduling.title.save" defaultMessage="Save" />
          </Button>
          <Button onClick={goPrevious} className="button--default" type="button">
            <FormattedMessage id="scheduling.title.back" defaultValue="Back" />
          </Button>
        </div>
      </Fragment>
    );
  }
}

export default ModePropertyEditor;
