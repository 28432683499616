import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import SystemSelector from '../SystemSelector';
import './hero.scss';

const Hero = ({ isHeadingVisible }) => {
  return (
    <section className="hero">
      <div className="hero__tint">
        <div className="hero__logo-wrapper">
          <span className="hero__logo" />
        </div>
        {isHeadingVisible && (
          <div className="hero__systemSelector">
            <div className="hero__systemSelector-wrapper">
              <div className="hero__small-heading-wrapper">
                <h1 className="hero__small-heading">
                  <FormattedMessage id="home.hero-heading" defaultMessage="Select Your Installation" />
                </h1>
              </div>
              <SystemSelector />
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

Hero.propTypes = {
  isHeadingVisible: PropTypes.bool,
};

export default Hero;
