import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

const CartError = ({ cartErrors, error }) => {
  const hasErrors = cartErrors.length > 0;
  return (
    <p className="Product__cartError">
      {hasErrors ? (
        cartErrors.map(error => (
          <p key={error.key}>
            <FormattedMessage id={`shop.page.${error.key}`} defaultMessage={error.message} />
          </p>
        ))
      ) : (
        <p>
          <FormattedMessage
            id="shop.page.cartError"
            defaultMessage="Due to technical problems the store could not be loaded. Please try to reload the page, if the problem persists contact support."
          />
        </p>
      )}
      {(error.messages || []).map(errorMessage => (
        <p key={`error__${errorMessage}`}>
          <FormattedMessage id={'shop.page.error.' + errorMessage} defaultMessage={errorMessage} />
        </p>
      ))}
    </p>
  );
};

CartError.propTypes = {
  cartErrors: PropTypes.array,
  error: PropTypes.shape({
    message: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired,
  }),
};
export default CartError;
