import { EventEmitter } from 'events';

import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';

import { createBrowserHistory } from 'history';
import { useEffect } from 'react';

/** Documentaiton: 
  https://learn.microsoft.com/en-us/azure/azure-monitor/app/javascript-react-plugin
  https://github.com/microsoft/ApplicationInsights-JS#readme
*/
const browserHistory = createBrowserHistory({ basename: '' });
const reactPlugin = new ReactPlugin();

const appInsights = new ApplicationInsights({
  config: {
    // TODO: Setup debug mode
    instrumentationKey: process.env.APPINSIGHTS_KEY || '',
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory },
    },
  },
});

appInsights.loadAppInsights();

export const events = {
  track: 'app_insights_track_event',
  pageView: 'app_insights_page_view',
  logError: 'app_insights_log_error',
};
export const eventEmitter = new EventEmitter();

const AppInsights = ({ children = null }) => {
  const logEvent = name => {
    appInsights.trackEvent({ name });
  };

  const logView = () => {
    appInsights.pageView();
  };

  const logError = (trace = {}) => {
    appInsights.trackException(trace);
  };

  useEffect(() => {
    appInsights.trackPageView();
    // eslint-disable-next-line no-console
    if (process.env.NODE_ENV === 'development') console.log('AppInsights setup');

    eventEmitter.on(events.track, logEvent);
    eventEmitter.on(events.pageView, logView);
    eventEmitter.on(events.logError, logError);

    return () => {
      eventEmitter.off(events.track, logEvent);
      eventEmitter.off(events.pageView, logView);
      eventEmitter.off(events.logError, logError);
    };
  }, []);

  return children;
};

// TODO: Review usage for page views
export const withAppInsights = Component => withAITracking(reactPlugin, Component);

export default AppInsights;
