import React from 'react';
import PropTypes from 'prop-types';

import { ChartType, getColor } from '../../chart-utils';
import './chart-parameters.scss';
import { Button } from 'jpi-cloud-web-ui-components';

const VerticalBar = ({ color, barType, isActive }) => (
  <div>
    <span
      className={`chart-type-wrapper__bar-chart__${barType}-bar`}
      style={{ backgroundColor: getColor(color, isActive) }}
    ></span>
    <span
      className={`chart-type-wrapper__bar-chart__${barType === 'small' ? 'large' : 'small'}-bar`}
      style={{ backgroundColor: getColor(color, isActive) }}
    ></span>
  </div>
);

VerticalBar.propTypes = {
  color: PropTypes.string.isRequired,
  barType: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
};

export default function ChartParameters({ parameter, changeChartType, deleteChartParameter }) {
  return (
    <div className="chart-list-container">
      <div className="chart-type-wrapper">
        <div
          className="chart-type-wrapper__horizontal-bar"
          style={{ backgroundColor: getColor(parameter.color, parameter.chartType === ChartType.line) }}
          onClick={() => {
            changeChartType(parameter, ChartType.line);
          }}
        ></div>
        <div
          className="chart-type-wrapper__bar-chart"
          onClick={() => {
            changeChartType(parameter, ChartType.column);
          }}
        >
          {['small', 'large'].map(c => (
            <VerticalBar
              key={c}
              color={parameter.color}
              barType={c}
              isActive={parameter.chartType === ChartType.column}
            />
          ))}
        </div>
        <div
          className="chart-type-wrapper__area-chart"
          onClick={() => {
            changeChartType(parameter, ChartType.area);
          }}
        >
          <div
            style={{
              borderBottom: `1.25rem solid ${getColor(parameter.color, parameter.chartType === ChartType.area)}`,
            }}
          ></div>
          <div
            style={{
              borderBottom: `0.938rem solid ${getColor(parameter.color, parameter.chartType === ChartType.area)}`,
            }}
          ></div>
        </div>
      </div>
      <p style={{ color: getColor(parameter.color) }}>{parameter.parameter}</p>
      <Button className="button--delete" type="button" onClick={deleteChartParameter}>
        <i className="fas fa-times"></i>
      </Button>
    </div>
  );
}

ChartParameters.propTypes = {
  parameter: PropTypes.object.isRequired,
  changeChartType: PropTypes.func,
  deleteChartParameter: PropTypes.func,
};
