import { push } from 'connected-react-router';
import {
  getProductsAction,
  getProductsSucceededAction,
  getProductsFailedAction,
  getEComSupportedCountriesAction,
  getEComSupportedCountriesSucceededAction,
  getEComSupportedCountriesFailedAction,
  initCartAction,
  initCartSucceededAction,
  initCartFailedAction,
  getPaymentProvidersAction,
  getPaymentProvidersSucceededAction,
  getPaymentProvidersFailedAction,
  getOrderPaymentProvidersAction,
  getOrderPaymentProvidersSucceededAction,
  getOrderPaymentProvidersFailedAction,
  selectPaymentProviderAction,
  getCartAction,
  getCartSucceededAction,
  getCartFailedAction,
  addToCartAction,
  addToCartSucceededAction,
  addToCartFailedAction,
  removeFromCartAction,
  removeFromCartSucceededAction,
  removeFromCartFailedAction,
  updateProductInCartAction,
  updateProductInCartSucceededAction,
  updateProductInCartFailedAction,
  addDiscountAction,
  addDiscountSucceededAction,
  addDiscountFailedAction,
  removeDiscountAction,
  removeDiscountSucceededAction,
  removeDiscountFailedAction,
  createOrderAction,
  createOrderSucceededAction,
  createOrderFailedAction,
  getOrderAction,
  getOrderSucceededAction,
  getOrderFailedAction,
  cancelOrderAction,
  cancelOrderSucceededAction,
  cancelOrderFailedAction,
  abortCreatedOrderAction,
  closeCreatedOrderAction,
  setShopIdAction,
  getSubscriptionsAction,
  getSubscriptionsSucceededAction,
  getSubscriptionsFailedAction,
  getOrdersAction,
  getOrdersSucceededAction,
  getOrdersFailedAction,
  getOrderStatusSucceededAction,
  getUsersEcomSubscriptionsFailedAction,
  getUsersEcomSubscriptionsSucceededAction,
  getUsersEcomSubscriptionsAction,
  getUserPaymentProviderSettingsSucceededAction,
  getUserPaymentProviderSettingsFailedAction,
  getUserPaymentProviderSettingsAction,
  updatePaymentProviderSettingsAction,
  updatePaymentProviderSettingsSucceededAction,
  updatePaymentProviderSettingsFailedAction,
  cancelSubscriptionAction,
  cancelSubscriptionSucceededAction,
  cancelSubscriptionFailedAction,
  resumeSubscriptionAction,
  resumeSubscriptionSucceededAction,
  resumeSubscriptionFailedAction,
  payPendingOrderAction,
  payPendingOrderSucceededAction,
  payPendingOrderFailedAction,
  paidBrands,
  paidBrandsSucceeded,
  paidBrandsFailed,
} from './actions';

import { getUserSubscriptions } from '../FeaturesFlags/actions';

import * as api from '../../api';
import { removeCart, getCart as getCartFromLocalStorage } from '../AppInitializer/utils';

export const getProducts = () => dispatch => {
  dispatch(getProductsAction());
  api
    .getProducts()
    .then(res => {
      dispatch(getProductsSucceededAction(res));
    })
    .catch(err => {
      dispatch(getProductsFailedAction(err));
    });
};

export const getEComSupportedCountries = () => async dispatch => {
  dispatch(getEComSupportedCountriesAction());
  const r = api.getEComSupportedCountries();
  await r
    .then(res => {
      const action = getEComSupportedCountriesSucceededAction(res);
      dispatch(action);
    })
    .catch(err => {
      dispatch(getEComSupportedCountriesFailedAction(err));
    });
};

export const getProductsInCollection = handle => dispatch => {
  dispatch(getProductsAction());
  api
    .getProductsInCollection(handle)
    .then(res => {
      dispatch(getProductsSucceededAction(res));
    })
    .catch(err => {
      dispatch(getProductsFailedAction(err));
    });
};

export const createCart = (email, address, customAttributes) => dispatch => {
  dispatch(initCartAction());
  const cart = getCartFromLocalStorage();
  if (cart?.id) {
    dispatch(setShopIdAction(cart.id));
    dispatch(initCartSucceededAction(cart));
    return cart;
  }

  return api
    .initCart(email, address, customAttributes)
    .then(({ cart, shopId }) => {
      if (shopId) {
        dispatch(setShopIdAction(shopId));
      }

      if (cart) {
        dispatch(initCartSucceededAction(cart));
        return Promise.resolve(cart);
      }
      return Promise.reject({ message: 'Could not initialize shopping cart' });
    })
    .catch(err => {
      // TODO: Add notification toast with appropriate message
      const errorMessage = err.response ? err.response.data : { messages: [err.message] };
      dispatch(initCartFailedAction(errorMessage));
      return Promise.reject(errorMessage);
    });
};

export const getCart = cart => dispatch => {
  dispatch(getCartAction());
  return api
    .getCart(cart)
    .then(cart => {
      dispatch(getCartSucceededAction(cart));
      return cart;
    })
    .catch(err => {
      dispatch(getCartFailedAction(err));
    });
};

export const getPaymentProviders = (cart, deviceId, activeTab) => dispatch => {
  dispatch(getPaymentProvidersAction());
  return api
    .getPaymentProviders(cart, deviceId, activeTab)
    .then(cart => {
      dispatch(getPaymentProvidersSucceededAction(cart));
      return cart;
    })
    .catch(err => {
      dispatch(getPaymentProvidersFailedAction(err));
    });
};

export const getUserPaymentProviderSettings = () => dispatch => {
  dispatch(getUserPaymentProviderSettingsAction());
  return api
    .getUserPaymentProviderSettings()
    .then(({ paymentProviders, paymentProviderSettings }) => {
      dispatch(getUserPaymentProviderSettingsSucceededAction(paymentProviders, paymentProviderSettings));
      return {
        paymentProviders,
        paymentProviderSettings,
      };
    })
    .catch(err => {
      const errorMessage = err.response ? err.response.data : { messages: [err.message] };
      dispatch(getUserPaymentProviderSettingsFailedAction(errorMessage));
    });
};

export const updatePaymentProviderSettings = paymentProviderSettings => dispatch => {
  dispatch(updatePaymentProviderSettingsAction(paymentProviderSettings));
  return api
    .updatePaymentProviderSettings(paymentProviderSettings)
    .then(paymentProviderSettings => {
      dispatch(updatePaymentProviderSettingsSucceededAction(paymentProviderSettings));
      return paymentProviderSettings;
    })
    .catch(err => {
      dispatch(updatePaymentProviderSettingsFailedAction(err));
    });
};

export const getOrderPaymentProviders = orderId => dispatch => {
  dispatch(getOrderPaymentProvidersAction());
  return api
    .getOrderPaymentProviders(orderId)
    .then(paymentProviders => {
      dispatch(getOrderPaymentProvidersSucceededAction(paymentProviders));
      return paymentProviders;
    })
    .catch(err => {
      dispatch(getOrderPaymentProvidersFailedAction(err));
    });
};

export const selectPaymentProvider = paymentProvider => dispatch => {
  dispatch(selectPaymentProviderAction(paymentProvider));
};

export const addToCart = (cart, product, variant) => dispatch => {
  dispatch(addToCartAction(cart, product, variant));
  api
    .addProductToCart(cart, product, variant)
    .then(res => {
      dispatch(addToCartSucceededAction(res));
    })
    .catch(err => {
      dispatch(addToCartFailedAction(err));
    });
};

export const removeFromCart = (cart, cartItem) => dispatch => {
  dispatch(removeFromCartAction(cart, cartItem));
  api
    .removeProductInCart(cart, cartItem)
    .then(res => {
      dispatch(removeFromCartSucceededAction(res));
    })
    .catch(err => {
      dispatch(removeFromCartFailedAction(err));
    });
};

export const updateProductInCart = (cart, cartItem, quantity) => dispatch => {
  dispatch(updateProductInCartAction(cart, cartItem, quantity));
  api
    .updateProductInCart(cart, cartItem)
    .then(res => {
      dispatch(updateProductInCartSucceededAction(res));
    })
    .catch(err => {
      dispatch(updateProductInCartFailedAction(err));
    });
};

export const addDiscount = (cart, discount) => dispatch => {
  dispatch(addDiscountAction(cart, discount));
  addDiscountAction(cart, discount)
    .then(res => {
      dispatch(addDiscountSucceededAction(res));
    })
    .catch(err => {
      dispatch(addDiscountFailedAction(err));
    });
};

export const removeDiscount = cart => dispatch => {
  dispatch(removeDiscountAction(cart));
  removeDiscount(cart)
    .then(res => {
      dispatch(removeDiscountSucceededAction(res));
    })
    .catch(err => {
      dispatch(removeDiscountFailedAction(err));
    });
};

export const delay = (t, v) => {
  return new Promise(function (resolve) {
    setTimeout(resolve.bind(null, v), t);
  });
};

/*
  TODO: Check why not rejecting whole promise from recursive call,
  bad code to resolve error
*/
export const hasOrderBeenCreated = (cart, iteration) => dispatch => {
  dispatch(getCartAction());
  return getCartAction(cart)
    .then(cart => {
      dispatch(getCartSucceededAction(cart));
      if (cart.order) {
        return cart;
      }

      if (iteration > 1) {
        return Promise.reject(new Error({ msg: 'Could not fetch completed order within 5 minutes' }));
      }
      return this.delay(15000).then(() => {
        return this.hasOrderBeenCreated(cart, iteration + 1);
      });
    })
    .catch(err => {
      dispatch(getCartFailedAction(err));
    });
};

export const createOrder = (cart, paymentProvider, paymentProviderData) => dispatch => {
  dispatch(createOrderAction());
  return api
    .createOrder(cart, paymentProvider, paymentProviderData)
    .then(order => {
      dispatch(createOrderSucceededAction(order));
      removeCart();
      return order;
    })
    .catch(error => {
      dispatch(createOrderFailedAction(error.response.data));
    });
};

export const getOrder = orderId => dispatch => {
  dispatch(getOrderAction(orderId));
  return api
    .getOrder(orderId)
    .then(order => {
      dispatch(getOrderSucceededAction(order));
      return order;
    })
    .catch(err => {
      dispatch(getOrderFailedAction(err));
    });
};

export const getOrderStatus = orderId => dispatch => {
  return api
    .getOrder(orderId)
    .then(order => {
      dispatch(getOrderStatusSucceededAction(order));
      return order;
    })
    .catch(() => {
      return false;
    });
};

export const payPendingOrder = orderId => async dispatch => {
  dispatch(payPendingOrderAction(orderId));
  return await api
    .payPendingOrder(orderId)
    .then(() => {
      dispatch(payPendingOrderSucceededAction());
      dispatch(getOrderStatus(orderId));
    })
    .catch(err => {
      dispatch(payPendingOrderFailedAction(err));
      dispatch(getOrderStatus(orderId));
    });
};

export const cancelOrder = orderId => dispatch => {
  dispatch(cancelOrderAction(orderId));
  return api
    .cancelOrder(orderId)
    .then(order => {
      dispatch(cancelOrderSucceededAction(order));
      dispatch(push('/store/cancel'));
      return order;
    })
    .catch(err => {
      dispatch(cancelOrderFailedAction(err));
    });
};

export const pollCreateOrder = cart => dispatch => {
  dispatch(createOrderAction());
  this.hasOrderBeenCreated(cart, 0).then(res => {
    if (!res) {
      dispatch(createOrderFailedAction({ msg: 'Could not fetch created order' }));
    } else {
      dispatch(createOrderSucceededAction(res.order));
    }
  });
};

export const abortCreateOrder = () => dispatch => {
  dispatch(abortCreatedOrderAction());
};

export const closeCreatedOrder = () => dispatch => {
  dispatch(closeCreatedOrderAction());
};

export const getSubscriptions = () => dispatch => {
  dispatch(getSubscriptionsAction());
  return api
    .getSubscriptions()
    .then(subscriptions => {
      dispatch(getSubscriptionsSucceededAction(subscriptions));
      return subscriptions;
    })
    .catch(err => {
      dispatch(getSubscriptionsFailedAction(err));
    });
};

export const getUserEcomSubscriptions = (deviceId, brandId) => dispatch => {
  dispatch(getUsersEcomSubscriptionsAction());
  return api
    .getUserSubscriptionsFromEcom(deviceId, brandId)
    .then(subscriptions => {
      dispatch(getUsersEcomSubscriptionsSucceededAction(subscriptions.data));
      return subscriptions;
    })
    .catch(err => {
      dispatch(getUsersEcomSubscriptionsFailedAction(err.response?.data));
    });
};

export const getOrderHistory = () => dispatch => {
  dispatch(getOrdersAction());
  return api
    .getOrderHistory()
    .then(orders => {
      dispatch(getOrdersSucceededAction(orders));
      return orders;
    })
    .catch(err => {
      dispatch(getOrdersFailedAction(err));
    });
};

export const cancelSubscription = (id, deviceId) => dispatch => {
  dispatch(cancelSubscriptionAction(id));
  return api
    .cancelSubscription(id)
    .then(subscription => {
      dispatch(cancelSubscriptionSucceededAction(subscription));
      dispatch(getUserSubscriptions(deviceId));
      return subscription;
    })
    .catch(err => {
      dispatch(cancelSubscriptionFailedAction(err));
    });
};

export const resumeSubscription = (id, deviceId) => dispatch => {
  dispatch(resumeSubscriptionAction(id));
  return api
    .resumeSubscription(id)
    .then(subscription => {
      dispatch(resumeSubscriptionSucceededAction(subscription));
      dispatch(getUserSubscriptions(deviceId));
      return subscription;
    })
    .catch(err => {
      dispatch(resumeSubscriptionFailedAction(err));
    });
};

export const getPaidBrands = () => dispatch => {
  dispatch(paidBrands());
  return api
    .getPaidBrands()
    .then(brandList => {
      const stripeSpecificBrands = brandList
        .filter(list => list.paymentProvider === 'stripe-recurring')
        .map(list => list?.brandId?.toLowerCase());
      const allPaidBrands = brandList.map(brand => brand?.brandId?.toLowerCase());
      dispatch(paidBrandsSucceeded(stripeSpecificBrands, allPaidBrands));
      return stripeSpecificBrands;
    })
    .catch(err => {
      dispatch(paidBrandsFailed(err));
    });
};
