import * as api from '../../../api';

export const GET_SMART_GUIDE_QUESTIONS_REQUEST = 'smart-guide/GET_SMART_GUIDE_QUESTIONS_REQUEST';
export const GET_SMART_GUIDE_QUESTIONS_RESPONSE = 'smart-guide/GET_SMART_GUIDE_QUESTIONS_RESPONSE';
export const getSmartGuideQuestions = (deviceId, guideNumber) => async dispatch => {
  dispatch({ type: GET_SMART_GUIDE_QUESTIONS_REQUEST });

  const res = await api.getSmartGuideQuestions(deviceId, guideNumber);

  dispatch({ type: GET_SMART_GUIDE_QUESTIONS_RESPONSE, deviceId, guideNumber, questions: res.data });
};

export const SET_SMART_GUIDE_ANSWER_OPTION = 'smart-guide/SET_SMART_GUIDE_ANSWER_OPTION';
export const setSmartGuideAnswerOption = (deviceId, guideNumber, answer, setting, value) => async dispatch => {
  const res = await api.getSmartGuideQuestions(deviceId, guideNumber);
  const data = res.data;
  data.payload.answers.find(ans => ans.guideNumber === answer.guideNumber).setting.value.integerValue = value;
  dispatch({ type: SET_SMART_GUIDE_ANSWER_OPTION, deviceId, guideNumber, questions: data });
};
