import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

class YourTechnicianInfo extends Component {
  state = {
    isPhoneDisplayed: false,
    isEmailDisplayed: false,
    isPhoneCopied: false,
    isEmailCopied: false,
  };

  handleMouseHoverOnPhone = () => {
    this.setState({ ...this.state, isPhoneDisplayed: !this.state.isPhoneDisplayed, isCopySuceeded: false });
  };

  handleMouseHoverOnEmail = () => {
    this.setState({ ...this.state, isEmailDisplayed: !this.state.isEmailDisplayed, isCopySuceeded: false });
  };

  handleCopyPhone = () => {
    this.copyToClipboard(this.props.contactPhone);
    this.setState({ ...this.state, isPhoneCopied: true, isEmailCopied: false });
    setTimeout(
      function () {
        this.setState({ ...this.state, isPhoneCopied: false });
      }.bind(this),
      2000,
    );
  };

  handleCopyEmail = () => {
    this.copyToClipboard(this.props.contactEmail);
    this.setState({ ...this.state, isPhoneCopied: false, isEmailCopied: true });
    setTimeout(
      function () {
        this.setState({ ...this.state, isEmailCopied: false });
      }.bind(this),
      2000,
    );
  };

  copyToClipboard(value) {
    const element = document.createElement('textarea');
    element.value = value;
    document.body.appendChild(element);
    element.select();
    document.execCommand('copy');
    document.body.removeChild(element);
    this.setState({ ...this.state, isCopySuceeded: true });
  }

  render() {
    return (
      <div className="partner-info-wrapper">
        <div className="name-wrapper">
          <div className="partner-label">
            {' '}
            <FormattedMessage id="alarms.your-technician-title" defaultMessage="Your Technician: " />{' '}
          </div>
          <div className="name">{this.props.name}</div>
        </div>
        <div
          className="phone-icon"
          onMouseEnter={this.handleMouseHoverOnPhone}
          onMouseLeave={this.handleMouseHoverOnPhone}
        >
          <i className="fa fa-phone-square"></i>
          {this.state.isPhoneDisplayed && (
            <div className="info">
              <a href={'tel:' + this.props.contactPhone}>{this.props.contactPhone}</a>
              <div className="copy-link" onClick={this.handleCopyEmail}>
                {' '}
                <i className={this.state.isEmailCopied ? 'fa fa-check' : 'fa fa-copy'} />{' '}
              </div>
            </div>
          )}
        </div>
        <div
          className="email-icon"
          onMouseEnter={this.handleMouseHoverOnEmail}
          onMouseLeave={this.handleMouseHoverOnEmail}
        >
          <i className="fa fa-envelope-o"></i>
          {this.state.isEmailDisplayed && (
            <div className="info">
              <a href={'mailto:' + this.props.contactEmail}>{this.props.contactEmail}</a>
              <div className="copy-link" onClick={this.handleCopyEmail}>
                {' '}
                <i className={this.state.isEmailCopied ? 'fa fa-check' : 'fa fa-copy'} />{' '}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

YourTechnicianInfo.propTypes = {
  name: PropTypes.string.isRequired,
  contactPhone: PropTypes.string.isRequired,
  contactEmail: PropTypes.string.isRequired,
};

export default YourTechnicianInfo;
