import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Button } from 'jpi-cloud-web-ui-components';
import { USER_SECURITY_LEVELS } from '../../../../../../pages/SystemProfile/components/SecurityTab/costants';
import { setSelectedDevice } from '../../../../actions';
import PremiumFeatureTooltip from '../../../../../../layout/PremiumFeatureTooltip';
import ReleaseNotesLink from '../../../../../../ReleaseNotesLink';

import './available.scss';

export const Available = ({ selectedDevice, setSelectedDevice, selectedSystem, updateInfo }) => {
  const triggerFwDownload = () => {
    const { id: mainDeviceId, slaveDeviceId } = selectedDevice;
    const downloadLink = mainDeviceId
      ? updateInfo?.fwPackageUri
      : updateInfo?.slaves?.find(s => s.slaveDeviceId === slaveDeviceId)?.fwPackageUri;

    location.href = downloadLink;
  };

  const getDeviceVersionPreview = () => {
    if (selectedDevice.id) return selectedDevice.desiredFwVersion || updateInfo.desiredFwVersion;
    if (selectedDevice.slaveDeviceId)
      return updateInfo.slaves.find(s => s.slaveDeviceId === selectedDevice.slaveDeviceId)?.desiredFwVersion;

    return 'N/A';
  };

  const isDownloadDisabled =
    selectedSystem &&
    (selectedSystem.userRole === USER_SECURITY_LEVELS.viewer ||
      selectedSystem.userRole === USER_SECURITY_LEVELS.manager);

  return (
    <div className="update-available">
      <div className="row">
        <div className="col-sm-12">
          <span onClick={() => setSelectedDevice()} className="close-button">
            <i className="fa fa-times"></i>
          </span>
          <div className="device-name">{selectedDevice ? selectedDevice.name : ''}</div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12 update-info">
          <div className="confirm-message">
            <FormattedMessage
              id="devices.update-available-to-download"
              defaultMessage="A firmware update is available to download to your computer."
            />
          </div>

          <div className="version-info">
            <FormattedMessage id="devices.version" defaultMessage="Version" />
            :&nbsp;
            {getDeviceVersionPreview()}
            &nbsp;
            <ReleaseNotesLink
              firmwareTypeId={selectedDevice?.firmwareId || selectedDevice?.firmware?.firmwareId}
              className="release-link"
            />
          </div>

          <div className="small-text">
            <FormattedMessage
              id="devices.system-supports-remote-update"
              defaultMessage="If your system supports remote update you can start it on the product directly."
            />
          </div>
        </div>
        <div className="col-sm-12 text-center action-buttons">
          <Button
            type="button"
            className={`button--primary button-download ${isDownloadDisabled ? 'disable-button' : ''}`}
            disabled={isDownloadDisabled}
            onClick={triggerFwDownload}
          >
            <FormattedMessage id="devices.download" defaultMessage="Download" />
          </Button>
          {isDownloadDisabled ? <PremiumFeatureTooltip translationId="premiumFeatures.permissionAccess" /> : null}
        </div>
      </div>
    </div>
  );
};

Available.propTypes = {
  selectedDevice: PropTypes.object.isRequired,
  updateInfo: PropTypes.object,
  setSelectedDevice: PropTypes.func,
  selectedSystem: PropTypes.object,
};

const mapStateToProps = ({ devices, app: { selectedSystem } }) => ({ ...devices, selectedSystem });

const mapDispatchToProps = {
  setSelectedDevice,
};

export default connect(mapStateToProps, mapDispatchToProps)(Available);
