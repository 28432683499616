import { useMutation } from '@tanstack/react-query';

import { migrateUser, validateUser } from '../../api/accountMigration';

export function getMutationKey() {
  return ['migrateAccount'];
}

const getOldUserIdAndMigrateProfileData = async ({ email, password }) => {
  const oldUserId = await validateUser({ email, password });
  const migrationResponse = await migrateUser({ oldUserId });
  return migrationResponse;
};

export function useAccountMigration() {
  return useMutation({
    queryKey: getMutationKey,
    mutationFn: ({ email, password }) => getOldUserIdAndMigrateProfileData({ email, password }),
  });
}
