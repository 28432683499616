import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import uuid from 'uuid';

import Modal from '../../../../layout/Modal';
import { Wizard, WizardSteps } from '../../../../layout/Wizard';
import { Spinner, Title } from 'jpi-cloud-web-ui-components';

import SelectEventMode from './SelectEventMode';
import SelectTimeEvent from './SelectTimeEvent';
import ConfirmCreateEventPopup from './ConfirmCreateEventPopup';
import ConfirmEventExceededPopup from './ConfirmEventExceededPopup';

import { getScheduleConfig, setWeeklySchedules } from '../../actions';

import '../../device-scheduling.scss';

import {
  humanStringToDayIndex,
  EVENT_INTERSECTION,
  getNextDay,
  getPrevDay,
  dayIndexToDeviceDay,
  SUNDAY,
  SATURDAY,
  MONDAY,
  TUESDAY,
  WEDNESDAY,
  THURSDAY,
  FRIDAY,
} from '../../weekFormats';

const EVENT_PART = {
  Rigth: 'right',
  Left: 'left',
};

const EVENTS_CONFIRMATION_TYPE = {
  isAnyToDelete: 'isAnyToDelete',
  eventsCountExceeded: 'eventsCountExceeded',
};

const add24hours = (time, dayShift = 1) => {
  const splitTime = time.split(':');
  splitTime[0] = parseInt(splitTime[0]) + 24 * dayShift;

  if (splitTime[0] < 10) {
    splitTime[0] = '0' + splitTime[0];
  }

  return splitTime.join(':');
};

const isCollapsedDaysFormat = weekFormat =>
  weekFormat === 'mon-fri,sat,sun' || weekFormat === 'mon-sun' || weekFormat === 'mon-fri,sat-sun';

class CreateEventPopup extends React.Component {
  static propTypes = {
    isEventPopupOpen: PropTypes.bool,
    closePopup: PropTypes.func.isRequired,
    modes: PropTypes.array.isRequired,
    schedule: PropTypes.object,
    currentEditableDay: PropTypes.object.isRequired,
    scheduleConfig: PropTypes.object,
    devices: PropTypes.array.isRequired,
    getScheduleConfig: PropTypes.func.isRequired,
    setWeeklySchedules: PropTypes.func.isRequired,
    getEventsCount: PropTypes.func.isRequired,
    weeklySchedules: PropTypes.array,
    selectedSystem: PropTypes.object,
    eventToEdit: PropTypes.object,
  };

  state = {
    selectedMode: null,
    loading: false,
    eventConfirmationStatus: false,
    eventConfirmingTimes: false,
  };

  async componentDidMount() {
    this.setState({ loading: true });
    await this.props.getScheduleConfig(this.props.devices[0].id);

    const { eventToEdit } = this.props;
    if (eventToEdit) {
      const selectedMode = this.props.modes.find(mode => mode.modeId === eventToEdit.modeId);
      this.setState({ selectedMode });
    }

    this.setState({ loading: false });
  }

  deleteEvent = (eventId, source = undefined) => {
    const { weeklySchedules, schedule } = this.props;
    const weeklyScheduleId = schedule.weeklyScheduleId;

    const wsSource = source ? source : weeklySchedules;
    const newWeeklySchedules = wsSource.map(ws => {
      if (ws.weeklyScheduleId === weeklyScheduleId) {
        return {
          ...ws,
          events: ws.events.filter(event => event.phantom_id !== eventId && event.id !== eventId),
        };
      }
      return ws;
    });

    return newWeeklySchedules;
  };

  onDeleteEvent = () => {
    const newWeeklySchedules = this.deleteEvent(this.props.eventToEdit.phantom_id);

    this.storeSchedule(newWeeklySchedules);
  };

  toggleEnabledEvent = (eventId, isEnabled) => {
    const { weeklySchedules, schedule } = this.props;
    const weeklyScheduleId = schedule.weeklyScheduleId;

    const newWeeklySchedules = weeklySchedules.map(ws => {
      if (ws.weeklyScheduleId === weeklyScheduleId) {
        const updatedEvents = [...ws.events];
        const eventIndex = updatedEvents.findIndex(ev => ev.phantom_id === eventId);
        const eventToEdit = { ...updatedEvents[eventIndex] };
        eventToEdit.enabled = isEnabled;
        updatedEvents.splice(eventIndex, 1, eventToEdit);
        return {
          ...ws,
          events: updatedEvents,
        };
      }
      return ws;
    });

    return newWeeklySchedules;
  };

  onDisableEvent = () => {
    const newWeeklySchedules = this.toggleEnabledEvent(this.props.eventToEdit.phantom_id, false);
    this.storeSchedule(newWeeklySchedules);
  };

  onEnableEvent = () => {
    const newWeeklySchedules = this.toggleEnabledEvent(this.props.eventToEdit.phantom_id, true);
    this.storeSchedule(newWeeklySchedules);
  };

  storeSchedule = async newWeeklySchedules => {
    const { devices, setWeeklySchedules, closePopup } = this.props;

    this.setState({ loading: true });
    await setWeeklySchedules(devices[0].id, newWeeklySchedules);
    this.setState({ loading: false });

    closePopup();
  };

  selectedModeChanged = mode => {
    this.setState({
      selectedMode: mode,
    });
  };

  getStartDay = () => {
    const { currentEditableDay, scheduleConfig, eventToEdit } = this.props;

    if (eventToEdit && !scheduleConfig.stopTimeAvailable) {
      return eventToEdit.startDay;
    }

    switch (currentEditableDay.name.toLowerCase()) {
      case 'saturday-sunday':
        return SATURDAY;
      case 'monday-sunday':
        return MONDAY;
      case 'monday-friday':
        return MONDAY;
      case 'sunday':
        return SUNDAY;
      case 'monday':
        return MONDAY;
      case 'tuesday':
        return TUESDAY;
      case 'wednesday':
        return WEDNESDAY;
      case 'thursday':
        return THURSDAY;
      case 'friday':
        return FRIDAY;
      case 'saturday':
        return SATURDAY;
    }

    // fall back
    for (let dayOfWeek = 0; dayOfWeek <= 6; ++dayOfWeek) {
      if (currentEditableDay.comparer(dayOfWeek)) {
        return dayOfWeek;
      }
    }

    return 0;
  };

  getSameTimeEvent = (startTime, dayIndex = null) => {
    const { weeklySchedules, schedule, scheduleConfig } = this.props;
    if (scheduleConfig.stopTimeAvailable && scheduleConfig.allowUnscheduled) {
      return null;
    }

    const currentWeeklySchedule = weeklySchedules.find(ws => ws.weeklyScheduleId === schedule.weeklyScheduleId);

    return currentWeeklySchedule.events.find(
      e =>
        e.startTime === startTime &&
        (dayIndex === null ? true : humanStringToDayIndex(e.startDay) === humanStringToDayIndex(dayIndex)),
    );
  };

  getEventsByDay = dayIndex => {
    const { weeklySchedules, schedule } = this.props;
    const currentWeeklySchedule = weeklySchedules.find(ws => ws.weeklyScheduleId === schedule.weeklyScheduleId);

    return currentWeeklySchedule.events.filter(
      e => humanStringToDayIndex(e.startDay) === humanStringToDayIndex(dayIndex),
    );
  };

  getCurrentScheduleEventsCount = () => {
    const { weeklySchedules, schedule, getEventsCount } = this.props;
    const currentWeeklySchedule = weeklySchedules.find(ws => ws.weeklyScheduleId === schedule.weeklyScheduleId);

    return getEventsCount(currentWeeklySchedule);
  };

  dayPreparedEvents = (dayEvents, dayShift) => {
    dayEvents = dayEvents.map(event => {
      const eventOverlapsTheDay = event.stopTime.localeCompare(event.startTime) <= 0;

      const addedTime = add24hours(event.stopTime, eventOverlapsTheDay ? dayShift + 1 : dayShift);
      const newEvent = {
        ...event,
        startTime: add24hours(event.startTime, dayShift),
        stopTime: eventOverlapsTheDay ? addedTime : add24hours(event.stopTime, dayShift),
      };
      return newEvent;
    });

    return dayEvents;
  };

  getNormalizedEventSequenceWithStopTimes = (startTime, stopTime) => {
    const { schedule } = this.props;
    const startDay = this.getStartDay();

    let eventsSequence = this.getEventsByDay(schedule.weekFormat === 'mon-sun' ? MONDAY : startDay);

    let stopTimeNormal = stopTime;

    if (
      schedule.weekFormat !== 'mon-fri,sat,sun' &&
      schedule.weekFormat !== 'mon-sun' &&
      schedule.weekFormat !== 'mon-fri,sat-sun'
    ) {
      let dayShift = 0;
      const timeCompare = startTime.localeCompare(stopTime);
      const prevDay = getPrevDay(startDay);
      let prevDayEvents = this.getEventsByDay(prevDay);

      let nextDaysPrepared = [];
      if (prevDayEvents.length) {
        prevDayEvents = this.dayPreparedEvents(prevDayEvents, dayShift);
        dayShift++;
      }
      const currentDayEvents = this.dayPreparedEvents(eventsSequence, dayShift);

      if (timeCompare >= 0) {
        dayShift++;
        const nextDay = getNextDay(startDay);
        const nextDayEvents = this.getEventsByDay(nextDay);
        nextDaysPrepared = this.dayPreparedEvents(nextDayEvents, dayShift);
      }

      eventsSequence = [...prevDayEvents, ...currentDayEvents, ...nextDaysPrepared];

      if (dayShift === 0 && timeCompare < 0) {
        stopTimeNormal = add24hours(stopTime, 0);
      } else if (!prevDayEvents.length && dayShift === 1) {
        stopTimeNormal = add24hours(stopTime);
      } else {
        stopTimeNormal = add24hours(stopTime, timeCompare >= 0 ? 2 : 1);
      }

      if (prevDayEvents.length) {
        startTime = add24hours(startTime);
      }
    } else {
      // mon-fri,sat,sun
      eventsSequence = this.splitEventsBy24Hours(eventsSequence);
    }

    eventsSequence = eventsSequence.map(e => ({ ...e })); // deep clone to avoid mutation

    if (this.props.eventToEdit) {
      eventsSequence = eventsSequence.filter(
        e => e.id !== this.props.eventToEdit.phantom_id && e.phantom_id !== this.props.eventToEdit.phantom_id,
      );
    }

    const reducedStopTime = stopTime === '00:00:00' ? '23:59:59' : stopTime;
    const dayIsOverlaps = reducedStopTime.localeCompare(startTime) <= 0;
    if (isCollapsedDaysFormat(schedule.weekFormat) && stopTime === startTime) {
      this.markSequenceToDelete(eventsSequence);
    } else if (isCollapsedDaysFormat(schedule.weekFormat) && dayIsOverlaps) {
      this.markSequenceWithIntersections(eventsSequence, '00:00:00', stopTime, stopTime);
      this.markSequenceWithIntersections(eventsSequence, startTime, '23:59:59', '23:59:59');
    } else {
      this.markSequenceWithIntersections(eventsSequence, startTime, stopTime, stopTimeNormal);
    }

    eventsSequence = eventsSequence.filter(event => event.intersection);

    return eventsSequence;
  };

  markSequenceToDelete = eventsSequence => {
    eventsSequence.forEach(eventReal => {
      eventReal.intersection = EVENT_INTERSECTION.DELETE; // delete event
      eventReal.isPart = false;
    });
  };

  markSequenceWithIntersections = (eventsSequence, startTime, stopTime, stopTimeNormal) => {
    const { schedule } = this.props;

    eventsSequence.forEach(eventReal => {
      const event = {
        ...eventReal,
        stopTime: eventReal.stopTime === '00:00:00' ? '23:59:59' : eventReal.stopTime,
      };
      const startTimeCompare = event.startTime.localeCompare(startTime);
      let stopTimeCompare = event.stopTime.localeCompare(stopTimeNormal);

      if (isCollapsedDaysFormat(schedule.weekFormat)) {
        stopTimeCompare = event.stopTime.localeCompare(stopTime !== '00:00:00' ? stopTime : '23:59:59');
      }

      if (startTimeCompare >= 0 && stopTimeCompare <= 0) {
        eventReal.intersection = EVENT_INTERSECTION.DELETE; // delete event
        if (isCollapsedDaysFormat(schedule.weekFormat)) {
          const otherPart = event.isPart === EVENT_PART.Left ? EVENT_PART.Rigth : EVENT_PART.Left;
          const otherEventPart = eventsSequence.find(
            e => (e.phantom_id === event.phantom_id || e.id === event.phantom_id) && e.isPart === otherPart,
          );
          if (otherEventPart) {
            if (otherEventPart.intersection === EVENT_INTERSECTION.DELETE) {
              eventReal.isFakeDelete = false;
              otherEventPart.isFakeDelete = false;
            } else if (otherEventPart.intersection === EVENT_INTERSECTION.CUT) {
              eventReal.isFakeDelete = true;
              otherEventPart.isFakeDelete = true;
              otherEventPart.intersection =
                event.isPart === EVENT_PART.Left
                  ? otherEventPart.intersection === EVENT_INTERSECTION.REDUCE_STOP_TIME
                  : EVENT_INTERSECTION.INCREASE_START_TIME;
            } else {
              eventReal.isFakeDelete = true;
              otherEventPart.intersection = EVENT_INTERSECTION.OTHER_PART_DELETE;
            }
          }
        }
      } else if (startTimeCompare < 0 && stopTimeCompare > 0) {
        eventReal.intersection = EVENT_INTERSECTION.CUT; // split events by 2 side left right and left parts
        if (eventReal.isPart) {
          const otherPartOfEvent = this.getOtherPart(eventsSequence, eventReal);
          otherPartOfEvent.intersection = EVENT_INTERSECTION.CUT;
        }
      } else if (startTimeCompare < 0 && event.stopTime.localeCompare(startTime) > 0) {
        if (
          eventReal.isPart &&
          eventReal.intersection !== EVENT_INTERSECTION.OTHER_PART_DELETE &&
          isCollapsedDaysFormat &&
          eventReal.stopTime === '00:00:00'
        ) {
          this.markBothParts(eventReal, EVENT_INTERSECTION.CUT, eventsSequence);
        } else {
          eventReal.intersection = EVENT_INTERSECTION.REDUCE_STOP_TIME; // reduce stop time
          const otherPartOfEvent = this.getOtherPart(eventsSequence, eventReal);
          if (otherPartOfEvent && otherPartOfEvent.intersection === EVENT_INTERSECTION.DELETE) {
            eventReal.isFakeDelete = true;
          }
        }
      } else if (event.startTime.localeCompare(stopTimeNormal) < 0 && stopTimeCompare > 0) {
        if (
          eventReal.isPart &&
          eventReal.intersection !== EVENT_INTERSECTION.OTHER_PART_DELETE &&
          isCollapsedDaysFormat &&
          eventReal.startTime === '00:00:00'
        ) {
          this.markBothParts(eventReal, EVENT_INTERSECTION.CUT, eventsSequence);
        } else {
          eventReal.intersection = EVENT_INTERSECTION.INCREASE_START_TIME; // increase start time
          const otherPartOfEvent = this.getOtherPart(eventsSequence, eventReal);
          if (otherPartOfEvent && otherPartOfEvent.intersection === EVENT_INTERSECTION.DELETE) {
            eventReal.isFakeDelete = true;
          }
        }
      }
    });
  };

  markBothParts = (eventReal, intersection, eventsSequence) => {
    eventReal.intersection = intersection;
    const otherPartOfEvent = this.getOtherPart(eventsSequence, eventReal);
    if (otherPartOfEvent) {
      otherPartOfEvent.intersection = intersection;
    }
  };

  splitEventsBy24Hours = events => {
    const result = [];

    events.forEach(eventReal => {
      const event = {
        ...eventReal,
        stopTime: eventReal.stopTime === '00:00:00' ? '23:59:59' : eventReal.stopTime,
      };

      const eventOverlapsTheDay = event.stopTime.localeCompare(event.startTime) <= 0;
      if (!eventOverlapsTheDay) {
        result.push(event);
      } else {
        result.push({
          ...event,
          startTime: '00:00:00',
          stopTime: event.stopTime,
          isPart: EVENT_PART.Left,
        });
        result.push({
          ...event,
          startTime: event.startTime,
          stopTime: '00:00:00',
          isPart: EVENT_PART.Rigth,
        });
      }
    });

    return result.sort((eventLeft, eventRight) => eventLeft.startTime.localeCompare(eventRight.startTime));
  };

  restructureEventsForStopTime = (startTime, stopTime, eventsSequence) => {
    const { schedule, weeklySchedules } = this.props;
    const weeklySchedulesEvents = weeklySchedules;

    if (!eventsSequence.length) {
      return weeklySchedulesEvents;
    }

    const weeklyScheduleId = schedule.weeklyScheduleId;
    const newWeeklySchedules = weeklySchedulesEvents.map(ws => {
      if (ws.weeklyScheduleId === weeklyScheduleId) {
        return {
          ...ws,
          events: this.processStopTimedEvents(ws.events, eventsSequence, startTime, stopTime),
        };
      }
      return ws;
    });

    return newWeeklySchedules;
  };

  deleteEventById = (newEvents, eventId) => {
    return newEvents.filter(scheduledEvent => scheduledEvent.id !== eventId && scheduledEvent.phantom_id !== eventId);
  };

  cutEvent = (newEvents, eventId, startTime, stopTime, event) => {
    const eventToProcess = newEvents.find(
      scheduledEvent => scheduledEvent.id === eventId || scheduledEvent.phantom_id === eventId,
    );
    const timeCompare = startTime.localeCompare(stopTime);
    const nextDayForLeftPart = eventToProcess.startTime.localeCompare(startTime) >= 0;

    const eventStartDay = humanStringToDayIndex(eventToProcess.startDay);
    newEvents.push({
      ...this.createEvent(eventToProcess.startTime, startTime),
      modeId: event.modeId,
      startDay: dayIndexToDeviceDay(eventStartDay),
      stopDay: dayIndexToDeviceDay(nextDayForLeftPart ? getNextDay(eventStartDay) : eventStartDay),
    });
    newEvents.push({
      ...this.createEvent(stopTime, eventToProcess.stopTime),
      modeId: event.modeId,
      startDay: dayIndexToDeviceDay(timeCompare >= 0 ? getNextDay(eventStartDay) : eventStartDay),
      stopDay: dayIndexToDeviceDay(eventToProcess.stopDay),
    });

    return newEvents.filter(scheduledEvent => scheduledEvent.id !== eventId && scheduledEvent.phantom_id !== eventId);
  };

  cutSplitEvent = (newEvents, eventId, startTime, stopTime, leftEvent, rightEvent) => {
    const eventToProcess = newEvents.find(
      scheduledEvent => scheduledEvent.id === eventId || scheduledEvent.phantom_id === eventId,
    );
    const nextDayForLeftPart = eventToProcess.startTime.localeCompare(startTime) >= 0;
    const eventStartDay = humanStringToDayIndex(eventToProcess.startDay);
    const splitsByDay = leftEvent.startTime === '00:00:00' && rightEvent.stopTime === '00:00:00';

    if (splitsByDay) {
      newEvents.push({
        ...this.createEvent(stopTime, eventToProcess.stopTime),
        modeId: leftEvent.modeId,
        startDay: dayIndexToDeviceDay(eventStartDay),
        stopDay: dayIndexToDeviceDay(nextDayForLeftPart ? getNextDay(eventStartDay) : eventStartDay),
      });

      newEvents.push({
        ...this.createEvent(eventToProcess.startTime, startTime),
        modeId: leftEvent.modeId,
        startDay: dayIndexToDeviceDay(eventStartDay),
        stopDay: dayIndexToDeviceDay(nextDayForLeftPart ? getNextDay(eventStartDay) : eventStartDay),
      });
    } else {
      console.error('Unexpected event structure...');
    }

    return newEvents.filter(scheduledEvent => scheduledEvent.id !== eventId && scheduledEvent.phantom_id !== eventId);
  };

  reduceStopTimeEvent = (newEvents, eventId, startTime) => {
    const eventToProcess = newEvents.find(
      scheduledEvent => scheduledEvent.id === eventId || scheduledEvent.phantom_id === eventId,
    );
    if (!eventToProcess) {
      return newEvents;
    }
    newEvents = newEvents.filter(
      scheduledEvent => scheduledEvent.id !== eventId && scheduledEvent.phantom_id !== eventId,
    );
    newEvents.push({
      ...eventToProcess,
      stopTime: startTime,
    });

    return newEvents;
  };

  increaseStartTimeEvent = (newEvents, eventId, stopTime, toTheSameDay = false) => {
    const eventToProcess = newEvents.find(
      scheduledEvent => scheduledEvent.id === eventId || scheduledEvent.phantom_id === eventId,
    );
    if (!eventToProcess) {
      return newEvents;
    }

    newEvents = newEvents.filter(
      scheduledEvent => scheduledEvent.id !== eventId && scheduledEvent.phantom_id !== eventId,
    );
    const timeCompare = eventToProcess.startTime.localeCompare(stopTime);

    newEvents.push({
      ...eventToProcess,
      startTime: stopTime,
      stopDay: toTheSameDay ? 'Monday' : eventToProcess.stopDay,
      startDay: toTheSameDay
        ? 'Monday'
        : timeCompare >= 0
        ? getNextDay(eventToProcess.startDay)
        : eventToProcess.startDay,
    });

    return newEvents;
  };

  processStopTimedEvents = (eventsFromSchedule, eventsSequence, startTime, stopTime) => {
    let newEventsFromSchedule = [...eventsFromSchedule];
    const newEventOverlapsTheDay = stopTime.localeCompare(startTime) <= 0;

    eventsSequence.forEach(event => {
      const eventId = event.id ? event.id : event.phantom_id;
      if (event.intersection === EVENT_INTERSECTION.DELETE) {
        if (event.isPart) {
          const otherPartOfEvent = this.getOtherPart(eventsSequence, event);
          if (otherPartOfEvent) {
            if (
              otherPartOfEvent.isFakeDelete &&
              otherPartOfEvent.intersection === EVENT_INTERSECTION.INCREASE_START_TIME
            ) {
              newEventsFromSchedule = this.increaseStartTimeEvent(newEventsFromSchedule, eventId, stopTime, true);
            } else if (
              otherPartOfEvent.isFakeDelete &&
              otherPartOfEvent.intersection === EVENT_INTERSECTION.REDUCE_STOP_TIME
            ) {
              newEventsFromSchedule = this.reduceStopTimeEvent(newEventsFromSchedule, eventId, startTime, true);
            } else if (otherPartOfEvent.isFakeDelete || event.isFakeDelete) {
              newEventsFromSchedule = this.deleteEventById(newEventsFromSchedule, eventId);
              const eventToPush = {
                startTime: event.isPart === EVENT_PART.Left ? otherPartOfEvent.startTime : '00:00:00',
                stopTime:
                  event.isPart === EVENT_PART.Left
                    ? newEventOverlapsTheDay && otherPartOfEvent.startTime.localeCompare(startTime) < 0
                      ? startTime
                      : '00:00:00'
                    : otherPartOfEvent.stopTime,
                modeId: otherPartOfEvent.modeId,
                id: eventId,
                phantom_id: eventId,
                startDay: otherPartOfEvent.startDay,
                stopDay: otherPartOfEvent.stopDay,
                enabled: otherPartOfEvent.enabled,
              };
              newEventsFromSchedule.push(eventToPush);
            } else {
              newEventsFromSchedule = this.deleteEventById(newEventsFromSchedule, eventId);
            }
          } else {
            newEventsFromSchedule = this.deleteEventById(newEventsFromSchedule, eventId);
          }
        } else {
          newEventsFromSchedule = this.deleteEventById(newEventsFromSchedule, eventId);
        }
      } else if (event.intersection === EVENT_INTERSECTION.CUT) {
        if (event.isPart) {
          const otherPartOfEvent = this.getOtherPart(eventsSequence, event);
          if (otherPartOfEvent) {
            newEventsFromSchedule = this.cutSplitEvent(
              newEventsFromSchedule,
              eventId,
              startTime,
              stopTime,
              event,
              otherPartOfEvent,
            );
            otherPartOfEvent.intersection = '';
            otherPartOfEvent.isPart = false; // unmark other part to avoid cyclick logic
            return;
          }
        }
        newEventsFromSchedule = this.cutEvent(newEventsFromSchedule, eventId, startTime, stopTime, event);
      } else if (event.intersection === EVENT_INTERSECTION.REDUCE_STOP_TIME) {
        newEventsFromSchedule = this.reduceStopTimeEvent(newEventsFromSchedule, eventId, startTime);
      } else if (event.intersection === EVENT_INTERSECTION.INCREASE_START_TIME) {
        newEventsFromSchedule = this.increaseStartTimeEvent(newEventsFromSchedule, eventId, stopTime);
      }
    });

    return newEventsFromSchedule;
  };

  getOtherPart = (eventsSequence, firstEvent) => {
    const eventId = firstEvent.id ? firstEvent.id : firstEvent.phantom_id;
    return eventsSequence.find(
      e =>
        e.isPart === (firstEvent.isPart === EVENT_PART.Left ? EVENT_PART.Rigth : EVENT_PART.Left) &&
        (e.id === eventId || e.phantom_id === eventId),
    );
  };

  confirmCreateEvent = isConfirmed => {
    const times = this.state.eventConfirmingTimes;
    this.setState({
      eventConfirmationStatus: false,
      eventConfirmingTimes: false,
    });

    if (isConfirmed) {
      this.processCreateEvent(times.startTime, times.stopTime);
    } else {
      const { closePopup } = this.props;
      closePopup();
    }
  };

  confirmExceededEvents = () => {
    const { closePopup } = this.props;
    closePopup();
  };

  onCreateEvent = (startTime, stopTime) => {
    const { scheduleConfig, eventToEdit } = this.props;
    if (scheduleConfig.stopTimeAvailable && scheduleConfig.allowUnscheduled) {
      const intersectedEventsSequence = this.getNormalizedEventSequenceWithStopTimes(startTime, stopTime);

      const isAnyToDelete = intersectedEventsSequence.some(
        e => e.intersection === EVENT_INTERSECTION.DELETE && !e.isFakeDelete,
      );

      if (isAnyToDelete) {
        // is confirmation have to be shown
        this.setState({
          eventConfirmationStatus: EVENTS_CONFIRMATION_TYPE.isAnyToDelete,
          eventConfirmingTimes: { startTime, stopTime },
        });

        return;
      }

      if (scheduleConfig.maxEventsNumber) {
        const cutCount = intersectedEventsSequence.filter(e => e.intersection === EVENT_INTERSECTION.CUT).length;

        //TO DO: Adjust the cutCount depending on the week format
        const eventsCountAfterOperation = this.getCurrentScheduleEventsCount() + cutCount * 2;

        if (scheduleConfig.maxEventsNumber < eventsCountAfterOperation) {
          this.setState({
            eventConfirmationStatus: EVENTS_CONFIRMATION_TYPE.eventsCountExceeded,
          });
          return;
        }
      }
    } else {
      const startDay = this.getStartDay();

      const eventsSequence = this.getEventsByDay(startDay);

      const eventWithSameTimeEvent = eventsSequence.find(e => e.startTime === startTime);
      if (!eventToEdit) {
        if (eventWithSameTimeEvent) {
          this.setState({
            eventConfirmationStatus: EVENTS_CONFIRMATION_TYPE.isAnyToDelete,
            eventConfirmingTimes: { startTime, stopTime },
          });
          return;
        } // is confirmation have to be shown
      } else if (eventWithSameTimeEvent) {
        if (eventToEdit.phantom_id !== eventWithSameTimeEvent.phantom_id) {
          this.setState({
            eventConfirmationStatus: EVENTS_CONFIRMATION_TYPE.isAnyToDelete,
            eventConfirmingTimes: { startTime, stopTime },
          });
          return;
        }
      }
    }

    this.processCreateEvent(startTime, stopTime);
  };

  processCreateEvent = (startTime, stopTime) => {
    const { schedule, eventToEdit, weeklySchedules, scheduleConfig, getEventsCount } = this.props;

    const weeklyScheduleId = schedule.weeklyScheduleId;
    let weeklySchedulesEvents = weeklySchedules;
    const startDay = this.getStartDay();
    const sameTimeEvent = this.getSameTimeEvent(startTime, startDay);

    if (sameTimeEvent && !scheduleConfig.stopTimeAvailable) {
      weeklySchedulesEvents = this.deleteEvent(sameTimeEvent.phantom_id);
      if (eventToEdit) {
        weeklySchedulesEvents = this.deleteEvent(eventToEdit.phantom_id, weeklySchedulesEvents);
      }
    } else if (scheduleConfig.stopTimeAvailable && scheduleConfig.allowUnscheduled) {
      const intersectedEventsSequence = this.getNormalizedEventSequenceWithStopTimes(startTime, stopTime);

      weeklySchedulesEvents = this.restructureEventsForStopTime(startTime, stopTime, intersectedEventsSequence);
    }

    const newWeeklySchedules = weeklySchedulesEvents.map(ws => {
      if (ws.weeklyScheduleId === weeklyScheduleId) {
        return {
          ...ws,
          events:
            eventToEdit && !sameTimeEvent
              ? ws.events.map(event =>
                  event.phantom_id !== eventToEdit.phantom_id ? event : this.createEvent(startTime, stopTime),
                )
              : [...ws.events, this.createEvent(startTime, stopTime)],
        };
      }
      return ws;
    });

    const currentWeeklySchedule = newWeeklySchedules.find(ws => ws.weeklyScheduleId === weeklyScheduleId);
    const totalEventsCount = getEventsCount(currentWeeklySchedule);

    if (scheduleConfig.maxEventsNumber < totalEventsCount) {
      this.setState({
        eventConfirmationStatus: EVENTS_CONFIRMATION_TYPE.eventsCountExceeded,
      });
      return;
    }

    this.storeSchedule(newWeeklySchedules);
  };

  createEventTemplate = startTime => ({
    enabled: true,
    phantom_id: uuid(),
    modeId: this.state.selectedMode.modeId,
    startDay: dayIndexToDeviceDay(this.getStartDay()),
    startTime,
  });

  createEvent = (startTime, stopTime) => {
    let event;

    if (!this.props.scheduleConfig.stopTimeAvailable) {
      event = {
        ...this.createEventTemplate(startTime),
      };
    } else {
      event = {
        ...this.createEventTemplate(startTime),
        stopTime: stopTime,
      };

      if (startTime.localeCompare(stopTime) >= 0) {
        event.stopDay = dayIndexToDeviceDay(getNextDay(this.getStartDay()));
      } else {
        event.stopDay = dayIndexToDeviceDay(event.startDay);
      }
    }

    return event;
  };

  render() {
    const { isEventPopupOpen, modes, scheduleConfig, selectedSystem, eventToEdit, schedule } = this.props;
    const { selectedMode, loading, eventConfirmationStatus } = this.state;

    return (
      <Modal show={isEventPopupOpen} backdrop="static">
        <div className="mode-popup">
          {eventToEdit ? (
            <Title titleTranslationId="scheduling.title.edit-event" defaultMessage="Edit Event" />
          ) : (
            <Title titleTranslationId="scheduling.title.create-event" defaultMessage="Create Event" />
          )}

          {loading && <Spinner dark />}
          {!loading && !eventConfirmationStatus && (
            <Wizard stepsCount={2}>
              {({ currentStep, goNext, goPrevious }) => (
                <WizardSteps currentStep={currentStep}>
                  <SelectEventMode
                    modes={modes}
                    selectedMode={selectedMode}
                    onSelectedChanged={this.selectedModeChanged}
                    goNext={goNext}
                    scheduleConfig={scheduleConfig}
                    goPrevious={this.props.closePopup}
                    eventToEdit={eventToEdit}
                    eventCount={schedule.events.length}
                    onDeleteEvent={this.props.eventToEdit ? this.onDeleteEvent : null}
                    onDisableEvent={
                      this.props.eventToEdit && this.props.eventToEdit.enabled ? this.onDisableEvent : null
                    }
                    onEnableEvent={
                      this.props.eventToEdit && !this.props.eventToEdit.enabled ? this.onEnableEvent : null
                    }
                  />
                  <SelectTimeEvent
                    onCreateEvent={this.onCreateEvent}
                    goPrevious={goPrevious}
                    selectedSystem={selectedSystem}
                    eventToEdit={eventToEdit}
                    showStopTime={scheduleConfig.stopTimeAvailable && scheduleConfig.allowUnscheduled}
                    timeToStart={eventToEdit && eventToEdit.startTime}
                    timeToStop={eventToEdit && eventToEdit.stopTime}
                    onDeleteEvent={this.props.eventToEdit ? this.onDeleteEvent : null}
                  />
                </WizardSteps>
              )}
            </Wizard>
          )}
          {!loading && eventConfirmationStatus == EVENTS_CONFIRMATION_TYPE.isAnyToDelete && (
            <ConfirmCreateEventPopup confirmCreateEvent={this.confirmCreateEvent} />
          )}
          {!loading && eventConfirmationStatus == EVENTS_CONFIRMATION_TYPE.eventsCountExceeded && (
            <ConfirmEventExceededPopup proceedConfirm={this.confirmExceededEvents} />
          )}
        </div>
      </Modal>
    );
  }
}

export default connect(
  ({
    app: { selectedSystem },
    devices: { devices },
    deviceScheduling: { modes, scheduleConfig, weeklySchedules },
  }) => ({
    selectedSystem,
    modes,
    devices,
    scheduleConfig,
    weeklySchedules,
  }),
  {
    getScheduleConfig,
    setWeeklySchedules,
  },
)(CreateEventPopup);
