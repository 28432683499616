import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { NotificationManager } from 'react-notifications';
import uuid from 'uuid';

import { Button, Spinner } from 'jpi-cloud-web-ui-components';

import EditAddressInfo from './EditAddressInfo';
import SystemProfileInfoItem from '../SystemProfileInfoItem';

import { eventEmitter, events } from '../../../../tracking/AppInsights';
import { getAddressInfo, createAddressInfo, changeAddressInfo } from './actions';
import { getUserSubscriptions } from '../../../../FeaturesFlags/actions';
import { activateTrialExists } from '../../../../../api';

const AddressInfo = ({ address }) => (
  <div>
    {address && (address.country || address.city) ? (
      <SystemProfileInfoItem className="address" itemName="address">
        <div className="line-one">{address.lineOne}</div>
        {address.lineTwo && <div className="line-two">{address.lineTwo}</div>}
        <div className="city">
          {address.postalCode} {address.city}
        </div>
        <div className="country">
          {address.region} {address.country && address.country.name}
        </div>
      </SystemProfileInfoItem>
    ) : (
      <div className="profile-info">
        <div className="value">
          {' '}
          <FormattedMessage id="address.no-info" defaultMessage="There is no info about address" />
        </div>
      </div>
    )}
  </div>
);

AddressInfo.propTypes = {
  address: PropTypes.object,
};

class AddressTab extends React.Component {
  state = {
    isLoaded: false,
    isAddressEditShown: false,
    addressValidationError: null,
    hasSubscriptions: false,
  };

  async componentDidMount() {
    this.setState({ ...this.state, isLoaded: true });
  }

  onAddressEditing = async () => {
    this.setState({ ...this.state, isLoaded: false });
    try {
      const [mainDevice = {}] = this.props.selectedSystem.devices || [];

      const { subscriptions } = await this.props.getUserSubscriptions(mainDevice.id);

      this.setState({
        ...this.state,
        isAddressEditShown: true,
        hasSubscriptions: subscriptions?.length > 0,
        isLoaded: true,
      });
    } catch (error) {
      NotificationManager.error(
        <FormattedMessage id="generic.error.request.unknown" defaultMessage="Unknown error occurred." />,
      );
    }
  };

  onCancelEditing = () => {
    this.setState({ ...this.state, isAddressEditShown: false, addressValidationError: null });
  };

  activateTrial = async payload => {
    const { userInfo, selectedSystem } = this.props;

    try {
      const body = {
        user: userInfo,
        AddressData: {
          id: payload.addressId,
          city: payload.city,
          country: {
            name: payload.country.name,
          },
          lineOne: payload.lineOne,
          lineTwo: payload.lineTwo,
          postalCode: payload.postalCode,
          region: payload.region,
        },
        GroupId: selectedSystem.id,
      };

      const [mainDevice = {}] = selectedSystem.devices || [];

      await activateTrialExists(selectedSystem.brandId, mainDevice.id, body);
    } catch (err) {
      const { error } = JSON.parse(err.response?.data?.internal_error);

      if (error !== 'TrialHasAlreadyBeenActivated')
        NotificationManager.error(
          <FormattedMessage id="generic.error.request.unknown" defaultMessage="Unknown error occurred." />,
        );

      eventEmitter.emit(events.logError, err);
    }
  };

  onAddressSubmit = async (values, { setSubmitting }) => {
    this.setState({ ...this.state, addressValidationError: null });
    setSubmitting(true);

    const newValues = {
      id: values.id,
      city: values.city.trim(),
      country: values.country,
      lineOne: values.lineOne.trim(),
      lineTwo: values.lineTwo.trim(),
      postalCode: values.postalCode.trim(),
      region: values.region.trim(),
    };

    const { paidBrands, selectedSystem, changeAddressInfo, createAddressInfo } = this.props;

    const isStripeSpecificPaidBrand = paidBrands.stripeSpecificPaidBrands.includes(
      selectedSystem.brandId.toLowerCase(),
    );

    const addressId = selectedSystem.addressId || uuid();

    /* 
      This is a temporary solution to provide a trial subscription to existing CLM users 
      who were unable to get trial due to wrong address provided.
      It should be removed after we give a stable release to activate payment for CLM. 

      Stated by @bhavesh-JPI
    */
    if (isStripeSpecificPaidBrand /* && selectedSystem.brandId === 'CLIMATEMASTER' */ && !this.state.hasSubscriptions)
      await this.activateTrial({ ...newValues, addressId });

    try {
      if (selectedSystem.addressId) {
        await changeAddressInfo(newValues, selectedSystem.id, false);
      } else {
        await createAddressInfo(addressId, newValues, selectedSystem.id, false);
      }
    } catch (error) {
      if (error.response?.data?.error === 'createCartFailed') {
        const [errorMessage = {}] = error.response.data.err || [];
        this.setState({
          ...this.state,
          addressValidationError: errorMessage.key || 'generic.error.request.unknown',
        });

        return setSubmitting(false);
      }

      if (error?.response?.status === 400) {
        const { internal_error: errDescription } = error?.response?.data || {};

        if (errDescription === 'Invalid Address')
          return NotificationManager.error(
            <FormattedMessage
              id="system-profile.form.error.address"
              defaultMessage="Unable to update system profile: Address is not valid."
            />,
          );
      }
    }

    this.setState({ ...this.state, isAddressEditShown: false });

    setSubmitting(false);
  };

  render() {
    const isDemo = this.props.userInfo && this.props.userInfo.isDemo;

    if (!this.state.isLoaded) {
      return <Spinner dark />;
    }

    return (
      <div className="system-profile-address-section">
        {this.state.isAddressEditShown ? (
          <div className="tabContainer">
            <EditAddressInfo
              addressId={this.props.selectedSystem.addressId}
              address={this.props.selectedSystem.address}
              countries={this.props.countries}
              requestError={this.props.addressTab.addressError}
              addressValidationError={this.state.addressValidationError}
              onCancel={this.onCancelEditing}
              onSubmit={this.onAddressSubmit}
            />
          </div>
        ) : (
          <div>
            <AddressInfo address={this.props.selectedSystem.address} />
            {this.props.userIsAdmin && (
              <Button className="button--secondary" onClick={this.onAddressEditing} type="button" disabled={isDemo}>
                <FormattedMessage id="button.edit" defaultMessage="Edit" />
              </Button>
            )}
          </div>
        )}
      </div>
    );
  }
}

AddressTab.propTypes = {
  selectedSystem: PropTypes.object.isRequired,
  countries: PropTypes.arrayOf(PropTypes.object),
  createAddressInfo: PropTypes.func.isRequired,
  getAddressInfo: PropTypes.func.isRequired,
  changeAddressInfo: PropTypes.func.isRequired,
  getUserSubscriptions: PropTypes.func.isRequired,
  addressTab: PropTypes.object,
  userInfo: PropTypes.object,
  userIsAdmin: PropTypes.bool.isRequired,
  paidBrands: PropTypes.shape({
    stripeSpecificPaidBrands: PropTypes.array,
  }),
};

export default connect(
  ({ app: { countries, userInfo }, addressTab, shop }) => ({
    countries,
    addressTab,
    userInfo,
    paidBrands: shop.paidBrands,
  }),
  {
    getAddressInfo,
    createAddressInfo,
    changeAddressInfo,
    getUserSubscriptions,
  },
)(AddressTab);
