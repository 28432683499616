import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { Button, Spinner } from 'jpi-cloud-web-ui-components';
import SystemProfileInfoItem from '../SystemProfileInfoItem';
import { resetProfileError, renameSystemProfile } from './actions';
import { renameSystem } from '../../../../AppInitializer/actions';
import EditProfileForm from './EditProfileForm';
import './profile.scss';

const SystemInfo = ({ selectedSystem }) => (
  <div className="system-info">
    <SystemProfileInfoItem className="name" itemName="name">
      {selectedSystem.name}
    </SystemProfileInfoItem>
  </div>
);

SystemInfo.propTypes = {
  selectedSystem: PropTypes.object.isRequired,
};

class ProfileTab extends React.Component {
  state = {
    isProfileEditShown: false,
    isLoaded: false,
  };

  componentDidMount() {
    this.setState({ ...this.state, isLoaded: true });
  }

  onProfileEditing = () => {
    this.setState({ ...this.state, isProfileEditShown: true });
  };

  onCancelProfileEditing = () => {
    this.props.resetProfileError();
    this.setState({ ...this.state, isProfileEditShown: false });
  };

  onProfileSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);
    const { renameSystemProfile, renameSystem, selectedSystem } = this.props;

    const name = values.newName;

    if (await renameSystemProfile(selectedSystem, name)) {
      renameSystem(selectedSystem, name);
      this.setState({ ...this.state, isProfileEditShown: false });
    }

    setSubmitting(false);
  };

  render() {
    const isDemo = this.props.userInfo && this.props.userInfo.isDemo;

    if (!this.state.isLoaded) {
      return <Spinner />;
    }

    return (
      <div className="system-profile-profile-section">
        {this.state.isProfileEditShown ? (
          <EditProfileForm
            selectedSystem={this.props.selectedSystem}
            requestError={this.props.systemProfileProfileTab.profileError}
            onCancel={this.onCancelProfileEditing}
            onSubmit={this.onProfileSubmit}
          />
        ) : (
          <div>
            <SystemInfo className="profile-system-info" selectedSystem={this.props.selectedSystem} />
            <Button
              className={classNames('button--secondary', isDemo ? 'disabled' : '')}
              type="button"
              onClick={this.onProfileEditing}
              disabled={isDemo}
            >
              <FormattedMessage id="button.edit" defaultMessage="Edit" />
            </Button>
          </div>
        )}
      </div>
    );
  }
}

ProfileTab.propTypes = {
  selectedSystem: PropTypes.object.isRequired,
  userInfo: PropTypes.object.isRequired,
  renameSystem: PropTypes.func.isRequired,
  renameSystemProfile: PropTypes.func.isRequired,
  resetProfileError: PropTypes.func.isRequired,
  systemProfileProfileTab: PropTypes.object,
};

export default connect(
  ({ systemProfileProfileTab, app: { userInfo } }) => ({
    systemProfileProfileTab,
    userInfo,
  }),
  {
    renameSystemProfile,
    resetProfileError,
    renameSystem,
  },
)(ProfileTab);
