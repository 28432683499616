import * as api from '../../../api';
import { ALARM_TYPES } from './constants';
import _ from 'lodash';

export const GET_ALARMS_REQUEST = 'alarms/GET_ALARMS_REQUEST';
export const GET_ALARMS_RESPONSE_SUCCEEDED = 'alarms/GET_ALARMS_RESPONSE_SUCCEEDED';

export const getInboxAlarms =
  (deviceId, page = 1, resetAlarms = false, pageSize = 10) =>
  async dispatch => {
    dispatch({ type: GET_ALARMS_REQUEST, deviceId, alarmsType: 'inbox' });

    const response = await api.getAlarms(deviceId, ALARM_TYPES.ACTIVE, page, pageSize);
    if (response.status === 200) {
      const devices = Object.keys(_.groupBy(response.data.page, 'deviceId'));

      const aidModeResponses = await Promise.all(devices.map(deviceId => api.getActiveAidMode(deviceId)));
      const devicesInAidMode = aidModeResponses.map((el, i) => ({
        deviceId: devices[i],
        aidMode: el.status === 200 ? el.data.aidMode : 'off',
      }));

      const alarms = response.data.page
        .map(alarm =>
          devicesInAidMode.find(el => el.deviceId == alarm.deviceId && el.aidMode === 'on')
            ? { ...alarm, activeAidMode: 'on' }
            : { ...alarm, activeAidMode: 'off' },
        )
        .sort((a, b) => (a.datetime < b.datetime ? 1 : -1));

      dispatch({
        type: GET_ALARMS_RESPONSE_SUCCEEDED,
        deviceId,
        alarmsType: 'inbox',
        alarms,
        page,
        totalCount: response.data.totalCount,
        resetAlarms,
      });
    }
  };

export const getArchivedAlarms =
  (deviceId, page = 1, resetAlarms = false, pageSize = 10) =>
  async dispatch => {
    dispatch({ type: GET_ALARMS_REQUEST, deviceId, alarmsType: 'archived' });

    const response = await api.getArchivedAlarms(deviceId, page, pageSize);
    if (response.status === 200) {
      const alarms = response.data.page.sort((a, b) => (a.datetime < b.datetime ? 1 : -1));

      dispatch({
        type: GET_ALARMS_RESPONSE_SUCCEEDED,
        deviceId,
        alarmsType: 'archived',
        alarms,
        page,
        totalCount: response.data.totalCount,
        resetAlarms,
      });
    }
  };
