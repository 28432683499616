import React, { useState, useEffect } from 'react';
import PropTypes, { string } from 'prop-types';
import { getUserPaymentProviderSettings, getPaymentProviders } from '../../../../Shop/client';
import { connect } from 'react-redux';
import AddCard from './components/AddCard';
import UpdateCard from './components/UpdateCard';
import { Spinner, Title } from 'jpi-cloud-web-ui-components';
import { FormattedMessage } from 'react-intl';

import './paymentsettings.scss';

const PaymentSettings = ({
  cart,
  language,
  userInfo,
  currencyCode,
  paymentDetails,
  getPaymentProviders,
  paymentProviderSettings,
  getUserPaymentProviderSettings,
  isStripeSpecificBrand,
  selectTab,
  devices,
  stripeSpecificBrandList,
  isAddCardButtonDisabled,
}) => {
  const [isPaymentProviderAvailable, setIsPaymentProviderAvailable] = useState(false);
  const [agreementAccepted, setAgreementAccepted] = useState(false);
  const [error, setError] = useState(false);
  const [settings, setSettings] = useState(null);
  const { id } = userInfo;
  const { paymentProviderSettings: paymentProviderSettingsDetails = [] } = paymentProviderSettings;
  const [{ id: deviceId }] = devices;

  if (!isStripeSpecificBrand && stripeSpecificBrandList?.length > 0) {
    selectTab('subscriptions');
  }

  useEffect(() => {
    if (cart.id && userInfo && !isPaymentProviderAvailable) {
      getUserPaymentProviderSettings(id);
      getPaymentProviders(cart, deviceId, 'payment_settings');
      setIsPaymentProviderAvailable(true);
    }
  }, [cart]);

  useEffect(() => {
    if (!paymentProviderSettings.loading && paymentProviderSettingsDetails?.length > 0) {
      try {
        setSettings(JSON.parse(paymentProviderSettings?.paymentProviderSettings[0]?.settings));
      } catch (err) {
        console.warn('Could not parse payment provider setting JSON');
      }
    }
  }, [paymentProviderSettings.paymentProviderSettings]);

  useEffect(() => {
    try {
      if (paymentProviderSettings && paymentProviderSettings.error && cart.error?.internal_error) {
        const internalError = JSON.parse(paymentProviderSettings.error?.internal_error);
        setError(internalError.err);
        if (!error) {
          setError([
            {
              key: internalError.error,
              message: internalError.messages[0],
            },
          ]);
        }
      }
    } catch (err) {
      setError([]);
    }
  }, [paymentProviderSettings.error]);

  return (
    <>
      <div className="paymentSettings">
        <div className="paymentSettings__Title">
          <Title
            className="paymentSettingsTitle"
            titleTranslationId="menu.items.payment-provider-settings"
            defaultMessage="Payment Settings"
          />
        </div>
        {paymentProviderSettings && paymentProviderSettings.error && paymentProviderSettings.error?.internal_error ? (
          <>
            {error && error.length ? (
              error.map((error, index) => (
                <p className="products__errorMessage" key={index}>
                  <FormattedMessage id={`shop.page.${error.key}`} defaultMessage={error.message} />
                </p>
              ))
            ) : (
              <p className="products__errorMessage">
                <FormattedMessage
                  id="shop.page.paymentSettingsError"
                  defaultMessage="Due to technical problems the payment settings could not be loaded."
                />
              </p>
            )}
          </>
        ) : (
          <>
            {!settings || paymentProviderSettings.loading || paymentDetails.loading ? (
              <Spinner dark />
            ) : settings && settings.savedCards?.length > 0 && settings.savedCards[0]?.cardNoMask ? (
              <UpdateCard cart={cart} language={language} cardDetails={settings} />
            ) : (
              <AddCard
                currencyCode={currencyCode}
                agreementAccepted={agreementAccepted}
                setAgreementAccepted={setAgreementAccepted}
                isAddCardButtonDisabled={isAddCardButtonDisabled}
              />
            )}
          </>
        )}
      </div>
    </>
  );
};

PaymentSettings.propTypes = {
  cart: PropTypes.shape({
    error: PropTypes.shape({
      key: PropTypes.string.isRequired,
      internal_error: PropTypes.shape({
        err: PropTypes.arrayOf(
          PropTypes.shape({
            key: PropTypes.string.isRequired,
            messages: PropTypes.string.isRequired,
          }),
        ),
      }),
      messages: PropTypes.arrayOf(string),
    }),
    id: PropTypes.string.isRequired,
  }),
  paymentProviderSettings: PropTypes.shape({
    paymentProviderSettings: PropTypes.arrayOf(
      PropTypes.shape({
        settings: PropTypes.string.isRequired,
      }),
    ),
  }),
  paymentDetails: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
  }),
  language: PropTypes.shape({
    languageTag: PropTypes.string.isRequired,
  }),
  dispatch: PropTypes.func.isRequired,
  currencyCode: PropTypes.string.isRequired,
  getUserPaymentProviderSettings: PropTypes.func.isRequired,
  getPaymentProviders: PropTypes.func.isRequired,
  isStripeSpecificBrand: PropTypes.bool,
  selectTab: PropTypes.func,
  devices: PropTypes.arrayOf({
    deviceId: PropTypes.string,
  }),
  stripeSpecificBrandList: PropTypes.array,
  isAddCardButtonDisabled: PropTypes.bool,
};

const mapStateToProps = state => ({
  userInfo: state.app.userInfo,
  vatDetails: state.shop.vatDetails,
  paymentProviderSettings: state.shop.paymentProviderSettings,
  paymentDetails: state.shop.paymentDetails,
  language: state.language,
  devices: state.app.selectedSystem.devices,
});

const mapDispatchToProps = {
  getUserPaymentProviderSettings,
  getPaymentProviders,
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentSettings);
