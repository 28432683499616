import { push } from 'connected-react-router';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { ModalBody } from 'react-bootstrap';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { Button, Title } from 'jpi-cloud-web-ui-components';
import Modal from '../../layout/Modal';
import {
  findProduct,
  getRegisteredProducts,
  getYourTechnicianByNameHint,
  getYourTechniciansList,
  registerProduct,
} from './actions';
import { RegistrationForm } from './RegistrationForm';
import { SerialNumberSearch } from './SerialNumberSearch';

import './product-registration.scss';
import { NotificationManager } from 'react-notifications';
import { BRANDS_WITH_HIDDEN_PRODUCT_REGISTRATION } from '../../constants/constants';

const SerialNumberSearchError = () => (
  <div className="product-registration step-1">
    <div className="col-lg-12">
      <h3>
        <FormattedMessage id="productRegistration.error" defaultMessage="Error" />
      </h3>
    </div>
    <div className="col-lg-12">
      <p>
        <FormattedMessage
          id="productRegistration.findProductErrorMessage"
          defaultMessage="Product registration is not possible for your product."
        />
      </p>
    </div>
  </div>
);

const RegistrationFormError = () => (
  <div className="product-registration step-2">
    <div className="col-lg-12">
      <h3>
        <FormattedMessage id="productRegistration.error" defaultMessage="Error" />
      </h3>
    </div>
    <div className="col-lg-12">
      <p>
        <FormattedMessage id="productRegistration.errorMessage" defaultMessage="The product can't be registered." />
      </p>
    </div>
  </div>
);

const RegistrationFormSuccess = ({ goToPage }) => (
  <div className="product-registration step-2">
    <Title titleTranslationId="productRegistration.title" defaultMessage="Product Registration" />
    <div className="col-lg-12">
      <h3>
        <FormattedMessage id="productRegistration.success" defaultMessage="Success" />
      </h3>
    </div>
    <div className="col-lg-12">
      <p>
        <FormattedMessage
          id="productRegistration.successMessage"
          defaultMessage="Thank you for registering your product on myUplink. With your registration, you will have access to the many opportunities to control, improve and monitor your living environment online. Please do not forget to connect your heat pump to the internet and add it to your account. When connecting your heat pump to myUplink you'll be able to monitor your system on any PC, smartphone or tablet using the basic version, free of charge. For a small additional fee you will get access to our premium services which allows you to change settings on your system and monitor your system's operation historically in more detail."
        />
      </p>
    </div>
    <div className="ok-button-wrapper">
      <Button className="button--secondary" onClick={() => goToPage('/')} type="button">
        <FormattedMessage id="button.ok" defaultMessage="OK" />
      </Button>
    </div>
  </div>
);

RegistrationFormSuccess.propTypes = {
  goToPage: PropTypes.func.isRequired,
};

class ProductRegistration extends React.Component {
  static propTypes = {
    userInfo: PropTypes.object,
    language: PropTypes.object.isRequired,
    productList: PropTypes.arrayOf(PropTypes.object),
    productFound: PropTypes.object,
    registrationSucceeded: PropTypes.bool,
    serialNumberSearchSubmitted: PropTypes.bool,
    registrationFormSubmitted: PropTypes.bool,
    getRegisteredProducts: PropTypes.func.isRequired,
    findProduct: PropTypes.func.isRequired,
    registerProduct: PropTypes.func.isRequired,
    goToPage: PropTypes.func.isRequired,
    countries: PropTypes.arrayOf(PropTypes.object),
    history: PropTypes.object,
    getYourTechniciansList: PropTypes.func.isRequired,
    yourTechnician: PropTypes.object.isRequired,
    getYourTechnicianByNameHint: PropTypes.func.isRequired,
    yourTechnicianResult: PropTypes.array.isRequired,
    selectedSystem: PropTypes.object,
  };

  state = {
    values: {
      serialNumber: '',
      productName: '',
      name: '',
      email: '',
      phone: '',
      installationDate: moment().format('YYYY-MM-DD'),
      registrationDate: moment().format('YYYY-MM-DD'),
      warrantyEndDate: moment().add(5, 'years').format('YYYY-MM-DD'),
      installerName: '',
      operatingHours: '0',
      address1: '',
      address2: '',
      postalCode: '',
      city: '',
      region: '',
      country: '',
      warrantyConditions: false,
      dataConsent: false,
    },

    showWarrantyInfoModal: false,
    warrantyInfoTranslationId: '',
  };

  async componentDidMount() {
    if (this.props.userInfo) {
      await this.props.getRegisteredProducts(this.props.userInfo.id);
      await this.props.getYourTechniciansList();
    }
  }

  onSubmitSerialNumberSearch = async (values, { setSubmitting }) => {
    setSubmitting(true);
    const userInfoData = this.props.userInfo
      ? {
          name: this.props.userInfo.fullName.trim(),
          email: this.props.userInfo.email.trim(),
          address1: this.props.userInfo.address.lineOne.trim(),
          address2: (this.props.userInfo.address.lineTwo && this.props.userInfo.address.lineTwo.trim()) || '',
          postalCode: this.props.userInfo.address.postalCode.trim(),
          city: this.props.userInfo.address.city.trim(),
          region: (this.props.userInfo.address.region && this.props.userInfo.address.region.trim()) || '',
          country: this.props.userInfo.address.country,
        }
      : {};

    const newValues = { ...this.state.values, ...userInfoData, serialNumber: values.serialNumber.trim() };
    this.setState({ values: newValues });

    await this.props.findProduct(newValues.serialNumber);
    setSubmitting(false);
  };

  onSubmitRegistrationForm = async (values, { setSubmitting }) => {
    setSubmitting(true);

    const newValues = {
      ...this.state.values,
      warrantyEndDate: moment(values.installationDate).add(5, 'years').format('YYYY-MM-DD'),
      productName: values.productName.trim(),
      phone: values.phone.trim(),
      operatingHours: values.operatingHours.trim(),
      installerName: values.installerName.trim(),
      address1: values.address1.trim(),
      address2: values.address2.trim(),
      city: values.city.trim(),
      country: values.country,
      postalCode: values.postalCode.trim(),
      region: values.region.trim(),
      installationDate: moment(values.installationDate).format('YYYY-MM-DD'),
    };

    this.setState({ ...this.state, values: newValues });

    try {
      await this.props.registerProduct(newValues, this.props.userInfo);
    } catch (error) {
      if (error?.response?.status === 400) {
        const { internal_error: errDescription } = error?.response?.data || {};

        if (errDescription === 'Invalid Address')
          return NotificationManager.error(
            <FormattedMessage
              id="product-registration.form.error.address"
              defaultMessage="Unable to register product: Address is not valid."
            />,
          );
      }

      NotificationManager.error(
        <FormattedMessage
          id="generic.error.request.unknown"
          defaultMessage="An error has occurred. Try again later."
        />,
      );
    } finally {
      setSubmitting(false);
    }
  };

  toggleWarrantyInfoModal = warrantyInfoTranslationId => {
    this.setState({
      ...this.state,
      showWarrantyInfoModal: true,
      warrantyInfoTranslationId,
    });
  };

  hideWarrantyInfoModal = () => {
    this.setState({
      ...this.state,
      showWarrantyInfoModal: false,
    });
  };

  render() {
    if (!this.props.userInfo) {
      return null;
    }
    if (
      this.props.userInfo.address.country.name !== 'Germany' ||
      BRANDS_WITH_HIDDEN_PRODUCT_REGISTRATION.includes(this.props.selectedSystem.brandId?.toLowerCase())
    ) {
      //TODO: rework redirect mechanism post-release
      this.props.history.push('/');
      return null;
    }

    return (
      <div>
        {((this.props.productFound && !this.props.productFound.canBeRegistered) ||
          !this.props.serialNumberSearchSubmitted) && (
          <SerialNumberSearch
            onSubmit={this.onSubmitSerialNumberSearch}
            productList={this.props.productList}
            initialValues={this.state.values}
            locale={this.props.language.selectedLanguage}
          />
        )}
        {this.props.productFound &&
          !this.props.productFound.canBeRegistered &&
          this.props.serialNumberSearchSubmitted && <SerialNumberSearchError />}
        {this.props.productFound &&
          this.props.productFound.canBeRegistered &&
          this.props.serialNumberSearchSubmitted &&
          !this.props.registrationSucceeded && (
            <RegistrationForm
              onSubmit={this.onSubmitRegistrationForm}
              countries={this.props.countries}
              initialValues={this.state.values}
              goToPage={this.props.goToPage}
              toggleWarrantyInfoModal={this.toggleWarrantyInfoModal}
              yourTechnician={this.props.yourTechnician.data}
              getYourTechnicianByNameHint={this.props.getYourTechnicianByNameHint}
              yourTechnicianResult={this.props.yourTechnicianResult}
            />
          )}
        {this.state.showWarrantyInfoModal && this.state.warrantyInfoTranslationId && (
          <Modal show={this.state.showWarrantyInfoModal} backdrop={true} onHide={() => this.hideWarrantyInfoModal()}>
            <ModalBody>
              <FormattedHTMLMessage id={this.state.warrantyInfoTranslationId} />
            </ModalBody>
          </Modal>
        )}
        {this.props.registrationFormSubmitted && !this.props.registrationSucceeded && <RegistrationFormError />}
        {this.props.registrationFormSubmitted && this.props.registrationSucceeded && (
          <RegistrationFormSuccess goToPage={this.props.goToPage} />
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ app: { userInfo, countries, selectedSystem }, language, productRegistration }) => ({
  userInfo,
  countries,
  language,
  selectedSystem,
  ...productRegistration,
});

const mapDispatchToProps = {
  getRegisteredProducts,
  findProduct,
  registerProduct,
  goToPage: push,
  getYourTechniciansList,
  getYourTechnicianByNameHint,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProductRegistration));
