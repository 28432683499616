import React from 'react';
import { connect } from 'react-redux';
import { IntlProvider } from 'react-intl';
import { getCultureByLanguage } from './cultures';
import PropTypes from 'prop-types';

const Wrapper = ({ selectedLanguage, children }) => {
  const culture = getCultureByLanguage(selectedLanguage);

  return (
    <IntlProvider locale={culture.language} key={culture.language} messages={culture.messages}>
      {React.Children.only(children)}
    </IntlProvider>
  );
};

Wrapper.propTypes = {
  selectedLanguage: PropTypes.string,
  children: PropTypes.any,
};

const mapStateToProps = state => {
  return {
    selectedLanguage: state.language.selectedLanguage,
  };
};

export default connect(mapStateToProps, undefined)(Wrapper);
