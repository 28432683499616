import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import AddMode from './AddMode';
import DeleteMode from './DeleteMode';
import RenameMode from './RenameMode';
import ModeList from './ModeList';

import { POPUP_STATES } from './constants';
import { weeklySchedulesType } from '../../types';

// TODO: Move to func & add TS
class SelectMode extends React.PureComponent {
  state = {
    modeSettings: {},
    viewState: POPUP_STATES.EDIT,
  };

  componentDidMount() {
    this.resetModeSettings();
  }

  resetModeSettings = () => {
    const { modes, scheduleConfig } = this.props;
    const modeSettings = modes.reduce(
      (modeSettings, mode) => ({
        ...modeSettings,
        [mode.modeId]: mode.settings.map(ms => ({
          ...ms,
          ...scheduleConfig.modeSettings.find(cms => cms.settingId === ms.settingId),
        })),
      }),
      {},
    );

    this.setState({ modeSettings });
  };

  toggleMode = mode => {
    if (this.props.selectedMode === mode) return;
    this.props.onSelectedChanged(mode);
  };

  toggleView = state => () => this.changeView(state);

  changeView = (viewState, reload) => {
    this.setState({ viewState });

    if (!this.props.onViewStateChanged) return;
    this.props.onViewStateChanged(viewState, reload);
  };

  onAddModeSubmit = async newModeData => {
    const { scheduleConfig, modes } = this.props;

    const newMode = {
      name: newModeData.modeName,
      settings: scheduleConfig.modeSettings.map(ms => ({ settingId: ms.settingId, value: ms.defaultValue })),
    };

    await this.props.saveModes([...modes, newMode], true);
    this.changeView(POPUP_STATES.EDIT);
  };

  onDeleteModeConfirm = async () => {
    await this.props.resetSelectedMode();
    this.changeView(POPUP_STATES.EDIT, true);
  };

  onRenameModeConfirm = async newModeData => {
    const { modes, selectedMode, resetSelectedMode } = this.props;

    const newMode = { ...selectedMode, name: newModeData.modeName };
    const newModes = [...modes].filter(value => value != selectedMode);
    await this.props.saveModes(
      [...newModes, newMode].sort((a, b) => {
        if (a.modeId < b.modeId) return -1;
        if (a.modeId > b.modeId) return 1;
        return 0;
      }),
      true,
    );

    resetSelectedMode();
    this.changeView(POPUP_STATES.EDIT);
  };

  render() {
    const {
      modes,
      weeklySchedules,
      selectedMode,
      goNext,
      goPrevious,
      nextButtonLabel,
      scheduleConfig,
      modeSelectorEnabled,
      userIsViewer,
      isVacation,
      deviceId,
    } = this.props;

    return (
      <Fragment>
        {this.state.viewState === POPUP_STATES.EDIT && (
          <ModeList
            modes={modes}
            weeklySchedules={weeklySchedules}
            selectedMode={selectedMode}
            scheduleConfig={scheduleConfig}
            goNext={goNext}
            goPrevious={goPrevious}
            nextButtonLabel={nextButtonLabel}
            modeSelectorEnabled={modeSelectorEnabled}
            userIsViewer={userIsViewer}
            canEditMode={!userIsViewer && !isVacation && scheduleConfig?.allowRename}
            modeSettings={this.state.modeSettings}
            onToggle={this.toggleMode}
            onDelete={this.toggleView(POPUP_STATES.DELETE)}
            onRename={this.toggleView(POPUP_STATES.RENAME)}
            onAdd={this.toggleView(POPUP_STATES.ADD)}
          />
        )}
        {this.state.viewState === POPUP_STATES.ADD && (
          <AddMode
            modes={this.props.modes}
            onSubmit={this.onAddModeSubmit}
            onCancel={this.toggleView(POPUP_STATES.EDIT)}
            scheduleConfig={scheduleConfig}
          />
        )}
        {this.state.viewState === POPUP_STATES.DELETE && (
          <DeleteMode
            selectedMode={selectedMode}
            onConfirm={this.onDeleteModeConfirm}
            onCancel={this.toggleView(POPUP_STATES.EDIT)}
            deviceId={deviceId}
          />
        )}
        {this.state.viewState === POPUP_STATES.RENAME && (
          <RenameMode
            modes={modes}
            selectedMode={selectedMode}
            scheduleConfig={scheduleConfig}
            onSubmit={this.onRenameModeConfirm}
            onCancel={this.toggleView(POPUP_STATES.EDIT)}
          />
        )}
      </Fragment>
    );
  }
}

SelectMode.propTypes = {
  modes: PropTypes.array.isRequired,
  weeklySchedules: weeklySchedulesType,
  selectedMode: PropTypes.object,
  scheduleConfig: PropTypes.object.isRequired,
  goNext: PropTypes.func.isRequired,
  goPrevious: PropTypes.func.isRequired,
  onSelectedChanged: PropTypes.func.isRequired,
  resetSelectedMode: PropTypes.func,
  nextButtonLabel: PropTypes.string,
  saveModes: PropTypes.func,
  modeSelectorEnabled: PropTypes.bool,
  userIsViewer: PropTypes.bool.isRequired,
  isVacation: PropTypes.bool.isRequired,
  onViewStateChanged: PropTypes.func,
  deviceId: PropTypes.string,
};

export default SelectMode;
