import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown as ReactDropDown, MenuItem } from 'react-bootstrap';
import classNames from 'classnames';
import { StatelessInput } from 'jpi-cloud-web-ui-components';
import './Dropdown.scss';

class InputDropdown extends React.Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(PropTypes.object).isRequired,
    onSelect: PropTypes.func.isRequired,
    labelGetter: PropTypes.func,
    itemFilter: PropTypes.func,
    selectedItem: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    placeholder: PropTypes.string,
    onBlur: PropTypes.func,
    error: PropTypes.string,
    isReadOnly: PropTypes.bool,
    disabled: PropTypes.bool,
    minimumRequiredCharacters: PropTypes.number,
    onChange: PropTypes.func,
  };
  state = {
    selectedItem: null,
    items: this.props.items,
    isDropdownOpen: false,
  };
  componentDidMount() {
    const { selectedItem, items, minimumRequiredCharacters } = this.props;
    const initialItems = minimumRequiredCharacters && minimumRequiredCharacters > 0 ? [] : items;
    this.setState({ ...this.state, items: initialItems, selectedItem: selectedItem || null });
  }
  selectItem = (i, e) => {
    this.props.onSelect(i, e);
    this.setState({ ...this.state, selectedItem: i });
  };
  filterItems = (textInput, event) => {
    const { items, itemFilter, minimumRequiredCharacters, onSelect } = this.props;

    const filteredItems =
      minimumRequiredCharacters && minimumRequiredCharacters > 0 && textInput.length < minimumRequiredCharacters
        ? []
        : items.filter(i => itemFilter(i, textInput));

    onSelect(textInput, event);
    this.setState({ ...this.state, items: filteredItems, selectedItem: textInput });
  };

  onFocus = () => {
    this.setState({
      isDropdownOpen: true,
    });
  };

  componentDidUpdate(prevProps) {
    if (prevProps.items !== this.props.items) {
      const { selectedItem, items, minimumRequiredCharacters } = this.props;
      const initialItems = minimumRequiredCharacters && minimumRequiredCharacters > 0 ? [] : items;
      this.setState({ ...this.state, items: initialItems, selectedItem: selectedItem || null });
    }
    if (prevProps.selectedItem !== this.props.selectedItem) {
      this.setState({ ...this.state, selectedItem: this.props.selectedItem || null });
    }
  }

  changeItems = e => {
    this.props.onChange && this.props.onChange(e);
    this.props.itemFilter ? this.filterItems(e.target.value, e) : this.selectItem(e.target.value, e);
  };

  blurHandler = () => {
    this.setState({
      isDropdownOpen: false,
    });
    this.props.onBlur && this.props.onBlur();
  };

  render() {
    const { isReadOnly, disabled } = this.props;
    const labelGetter = this.props.labelGetter || (e => e.text);
    const selectedItem = this.props.selectedItem || this.state.selectedItem;

    return (
      <ReactDropDown
        id={this.props.id}
        disabled={disabled}
        className={classNames('dropdown-input', this.state.isDropdownOpen && 'open')}
      >
        <ReactDropDown.Toggle className={disabled ? 'disabled' : ''}>
          <StatelessInput
            className="textWrapper"
            id={this.props.id + '__appendedInputButton'}
            type="text"
            onBlur={this.blurHandler}
            error={this.props.error}
            placeholder={this.props.placeholder}
            autocomplete="off"
            role="presentation"
            onChange={this.changeItems}
            onFocus={this.onFocus}
            isReadOnly={isReadOnly}
            value={selectedItem ? (typeof selectedItem === 'string' ? selectedItem : labelGetter(selectedItem)) : ''}
            disabled={disabled}
          />
        </ReactDropDown.Toggle>
        <ReactDropDown.Menu>
          {this.state.items.map((item, i) => (
            <MenuItem key={i} id={this.props.id} onClick={e => this.selectItem(item, e)}>
              {labelGetter(item)}
            </MenuItem>
          ))}
        </ReactDropDown.Menu>
      </ReactDropDown>
    );
  }
}

export default InputDropdown;
