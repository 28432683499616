import * as api from '../../../../../api';

export const UPDATE_SYSTEM_FOR_EMAIL_NOTIFICATIONS_REQUEST = 'profile/UPDATE_SYSTEM_FOR_EMAIL_NOTIFICATIONS_REQUEST';
export const UPDATE_SYSTEM_FOR_EMAIL_NOTIFICATIONS_RESPONSE_SUCCEDED =
  'profile/UPDATE_SYSTEM_FOR_EMAIL_NOTIFICATIONS_RESPONSE_SUCCEDED';
export const UPDATE_SYSTEM_FOR_EMAIL_NOTIFICATIONS_FAILED =
  'profile/UPDATE_SYSTEM_FOR_EMAIL_NOTIFICATIONS_REQUEST_FAILED';
export const UPDATE_SYSTEM_FOR_PUSH_NOTIFICATIONS_REQUEST = 'profile/UPDATE_SYSTEM_FOR_EMAIL_NOTIFICATIONS_REQUEST';
export const UPDATE_SYSTEM_FOR_PUSH_NOTIFICATIONS_RESPONSE_SUCCEDED =
  'profile/UPDATE_SYSTEM_FOR_EMAIL_NOTIFICATIONS_RESPONSE_SUCCEDED';
export const UPDATE_SYSTEM_FOR_PUSH_NOTIFICATIONS_FAILED =
  'profile/UPDATE_SYSTEM_FOR_EMAIL_NOTIFICATIONS_REQUEST_FAILED';
export const CHANGE_NOTIFICATION_SETTINGS_ACTIVE_TAB = 'profile/CHANGE_NOTIFICATION_SETTINGS_ACTIVE_TAB';
export const UPDATE_USER_PROFILE_REQUEST = 'profile/UPDATE_USER_PROFILE_REQUEST';
export const UPDATE_USER_PROFILE_RESPONSE_SUCCEDED = 'profile/UPDATE_USER_PROFILE_RESPONSE_SUCCEDED';
export const UPDATE_USER_PROFILE_FAILED = 'profile/UPDATE_USER_PROFILE_FAILED';

export const updateSystemForEmailNotifications = (groupId, value, systems) => async dispatch => {
  dispatch({ type: UPDATE_SYSTEM_FOR_EMAIL_NOTIFICATIONS_REQUEST });
  try {
    const response = await api.updateSystemForEmailNotifications(groupId, value);
    if (response.status === 200) {
      dispatch({ type: UPDATE_SYSTEM_FOR_EMAIL_NOTIFICATIONS_RESPONSE_SUCCEDED, systems: systems });
      return true;
    }
  } catch (err) {
    if (err.response) {
      dispatch({ type: UPDATE_SYSTEM_FOR_EMAIL_NOTIFICATIONS_FAILED, error: err.response.data.description });
      return false;
    }
  }

  dispatch({ type: UPDATE_SYSTEM_FOR_EMAIL_NOTIFICATIONS_FAILED, error: 'unknown' });
  return false;
};

export const updateSystemForPushNotifications = (groupId, value, systems) => async dispatch => {
  dispatch({ type: UPDATE_SYSTEM_FOR_PUSH_NOTIFICATIONS_REQUEST });
  try {
    const response = await api.updateSystemForPushNotifications(groupId, value);
    if (response.status === 200) {
      dispatch({ type: UPDATE_SYSTEM_FOR_PUSH_NOTIFICATIONS_RESPONSE_SUCCEDED, systems: systems });
      return true;
    }
  } catch (err) {
    if (err.response) {
      dispatch({ type: UPDATE_SYSTEM_FOR_PUSH_NOTIFICATIONS_FAILED, error: err.response.data.description });
      return false;
    }
  }

  dispatch({ type: UPDATE_SYSTEM_FOR_PUSH_NOTIFICATIONS_FAILED, error: 'unknown' });
  return false;
};

export const updateUserProfile = (userId, userProfile) => async dispatch => {
  dispatch({ type: UPDATE_USER_PROFILE_REQUEST });
  try {
    const response = await api.updateUserProfile(userId, userProfile);
    if (response.status === 204) {
      dispatch({ type: UPDATE_USER_PROFILE_RESPONSE_SUCCEDED, userProfile: userProfile });
      return true;
    }
  } catch (err) {
    if (err.response && err.response.status === 400) {
      dispatch({ type: UPDATE_USER_PROFILE_FAILED, error: err.response.data.description });
      return false;
    }
  }

  dispatch({ type: UPDATE_USER_PROFILE_FAILED, error: 'unknown' });
  return false;
};

export const onChangeActiveTab = activeTab => dispatch => {
  dispatch({ type: CHANGE_NOTIFICATION_SETTINGS_ACTIVE_TAB, activeTab: activeTab });
};
