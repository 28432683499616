import { Spinner, Title } from 'jpi-cloud-web-ui-components';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getFaqItems } from './actions';
import FaqItem from './components/FaqItem';

import './faq.scss';

class FaqPage extends Component {
  static propTypes = {
    faqItems: PropTypes.arrayOf(PropTypes.object).isRequired,
    getFaqItems: PropTypes.func.isRequired,
    selectedSystem: PropTypes.object,
    loading: PropTypes.bool,
    lastUsedLanguage: PropTypes.string,
  };

  async componentDidMount() {
    const { lastUsedLanguage, selectedSystem } = this.props;
    const brandId = (selectedSystem && selectedSystem.brandId) || 'MYUPLINK';
    await this.props.getFaqItems(brandId, lastUsedLanguage);
  }

  async componentDidUpdate(prevProps) {
    if (this.props.selectedSystem !== prevProps.selectedSystem) {
      await this.props.getFaqItems(this.props.selectedSystem.brandId);
    }
  }

  render() {
    const { loading, faqItems } = this.props;

    return (
      <div className="page-content">
        <Title titleTranslationId="faq.title" defaultMessage="FAQs" description="Title on Faq page" />
        {loading && <Spinner dark />}
        <hr />
        {!loading && faqItems.map(item => <FaqItem key={item.faqId} {...item} />)}
      </div>
    );
  }
}

export default connect(
  ({ app: { lastUsedLanguage, selectedSystem }, faq: { faqItems, loading } }) => ({
    lastUsedLanguage,
    selectedSystem,
    faqItems,
    loading,
  }),
  {
    getFaqItems,
  },
)(FaqPage);
