import React, { useState } from 'react';
import { DayPicker } from 'react-day-picker';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import moment from 'moment';
import * as locales from 'react-date-range/dist/locale';
import { FormattedMessage } from 'react-intl';

import 'react-day-picker/dist/style.css';
import './multi-range-calendar.scss';

const MultiRangeCalendar = ({
  setDateOnGraph,
  setShowCalendar,
  dateFrom = new Date(),
  dateTo = new Date(),
  min,
  selectedLanguage,
}) => {
  const [dateRange, setDateRange] = useState({
    from: dateFrom,
    to: dateTo,
  });

  const locale = Object.keys(locales).find(_ => _.includes(selectedLanguage));

  const handleApplyChanges = () => {
    if (dateRange?.from) {
      const formattedDateFrom = moment(format(dateRange.from, "yyyy-MM-dd'T'HH:mm:ssXX"));
      const formattedDateTo = dateRange?.to
        ? moment(format(dateRange.to, "yyyy-MM-dd'T'HH:mm:ssXX"))
        : moment(format(dateRange.from, "yyyy-MM-dd'T'HH:mm:ssXX"));

      const graphArgs = dateRange?.to
        ? [formattedDateFrom, formattedDateTo]
        : [formattedDateFrom.startOf('day'), formattedDateTo.endOf('day')];

      setDateOnGraph(...graphArgs);
      setShowCalendar(false);
    }
  };

  return (
    <div className="multi-range-calendar">
      <DayPicker
        mode="range"
        defaultMonth={dateTo}
        selected={dateRange}
        footer={
          <div className="calendar__footer">
            <p>
              <FormattedMessage
                id="history.calendar.choose"
                defaultMessage="Choose a time period to display parameters on the graph"
              />
            </p>
            <button onClick={handleApplyChanges} disabled={!dateRange?.from}>
              <FormattedMessage id="history.calendar.apply" defaultMessage="Apply" />
            </button>
          </div>
        }
        onSelect={setDateRange}
        disabled={[{ before: min }, { after: new Date() }]}
        locale={locales[locale] || locales.enUS}
      />
    </div>
  );
};

MultiRangeCalendar.propTypes = {
  setDateOnGraph: PropTypes.func.isRequired,
  setShowCalendar: PropTypes.func.isRequired,
  dateFrom: PropTypes.instanceOf(Date),
  dateTo: PropTypes.instanceOf(Date),
  min: PropTypes.instanceOf(Date),
  selectedLanguage: PropTypes.string,
};

export default MultiRangeCalendar;
