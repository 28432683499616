import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import '../account-migration-info-modal.scss';

const AccountMigrationInfoDescription = ({ translationObject }) => (
  <div className="account-migration-info-modal-description">
    <FormattedMessage
      id={`accountMigrationInfoModal${translationObject.key}.description`}
      defaultMessage={`Now, you can migrate your old ${translationObject.value} account with existing one, all of your device data and configuration will be transferred.`}
    />
    <FormattedMessage
      id="accountMigrationInfoModal.description2"
      defaultMessage="Follow {link} if you want to start the process. If not, migration will be available in the Profile Settings."
      values={{
        link: (
          <Link to="/profile-settings">
            <FormattedMessage id="accountMigrationInfoModal.profileSettingsLink" defaultMessage="this link" />
          </Link>
        ),
      }}
    />
  </div>
);

AccountMigrationInfoDescription.propTypes = {
  translationObject: PropTypes.object,
};

export default AccountMigrationInfoDescription;
