import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Modal as BootstrapModal } from 'react-bootstrap';

const Modal = props => {
  const defaultBrandId = 'myuplink';
  const { selectedSystem, className, ...rest } = props;
  const brandId = selectedSystem && selectedSystem.brandId ? selectedSystem.brandId.toLowerCase() : defaultBrandId;

  return <BootstrapModal dialogClassName={classNames(`branded-page--${brandId}`, className)} {...rest} />;
};

Modal.propTypes = {
  brandId: PropTypes.string,
  className: PropTypes.string,
};

export default connect(
  ({ app: { selectedSystem } }) => ({
    selectedSystem,
  }),
  {},
)(Modal);
