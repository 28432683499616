import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import { Button, Spinner, Title } from 'jpi-cloud-web-ui-components';

import * as client from '../../../../Shop/client';
import { getSubscriptionsInGroup } from '../../../../pages/Devices/actions';

import Price from '../../../../Shop/components/Price';
import OrderPage from '../OrderPage';

import { isEmpty } from 'lodash';
import moment from 'moment';
import './orderhistory.scss';

const orderHistory = ({
  order,
  orders: { init, loading, orders },
  dispatch,
  match,
  getOrder,
  getOrderHistory,
  getSubscriptionsInGroup,
  selectedSystem,
}) => {
  const { id } = selectedSystem ?? {};
  const [isLoading, setLoading] = useState(true);
  const newOrderId = match.params.id;

  useEffect(() => {
    if (newOrderId) {
      getOrder(newOrderId);
    }
  }, []);

  useEffect(() => {
    if (window.location.pathname !== '/store') {
      getSubscriptionsInGroup(id);
    }
    setLoading(true);
    if (!init && !loading) {
      getOrderHistory();
    }
    setLoading(false);
  }, [id]);

  if (isLoading || order.loading || loading) {
    return <Spinner dark />;
  }
  return (
    <>
      {!isLoading &&
        (order && order.data && order.data.id ? (
          <OrderPage dispatch={dispatch} match={match} />
        ) : (
          <div className="Orders">
            <div className="Orders__heading">
              <Title className="OrderHistoryTitle" titleTranslationId="orders.heading" defaultMessage="Order History" />
            </div>
            {isEmpty(orders) ? (
              <div className="Orders__isOrderAvailable">
                <FormattedMessage id="orders.page.no-orders-found" defaultMessage="You have not made a payment yet." />
              </div>
            ) : (
              <>
                {loading || order.loading || isEmpty(orders) ? (
                  <Spinner dark />
                ) : (
                  orders.map(({ orderNumber, status, currency, price, createdAt = '' }) => (
                    <div className="Orders__orderWrapper" key={id}>
                      <div className="Orders__contentWrapper">
                        <div className="Orders__OrderDate">
                          {moment.utc(createdAt).format('MM/DD/YYYY')}
                          <div className="SubscriptionListItem__orderStatus">
                            <FormattedMessage id="orders.page.orderStatus" defaultMessage="Status" />
                            &nbsp;:&nbsp;
                            <FormattedMessage id={'orders.page.orderStatus.' + status} defaultMessage={status} />
                          </div>
                        </div>
                        <div className="Orders__orderId">
                          <FormattedMessage id={'orders.page.order'} defaultMessage="Order No:" />
                          &ensp;&#35;{orderNumber}
                        </div>
                        <div className="Orders__orderPrice">
                          <Price currency={currency} price={price} />
                        </div>
                      </div>
                      <div className="Orders__buttonWrapper">
                        <Button className="orders__view-button" onClick={() => getOrder(orderNumber)}>
                          <FormattedMessage id="orders.page.button.view" defaultMessage="View Details" />
                        </Button>
                      </div>
                    </div>
                  ))
                )}
              </>
            )}
          </div>
        ))}
    </>
  );
};

orderHistory.propTypes = {
  order: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    data: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
  orders: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    init: PropTypes.bool.isRequired,
    orders: PropTypes.shape({
      id: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
      currency: PropTypes.string.isRequired,
      price: PropTypes.number.isRequired,
      createdAtL: PropTypes.string.isRequired,
    }),
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }),
  selectedSystem: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
  dispatch: PropTypes.func.isRequired,
  getOrder: PropTypes.func.isRequired,
  getOrderHistory: PropTypes.func.isRequired,
  getSubscriptionsInGroup: PropTypes.func.isRequired,
  lastUsedSystemId: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  ...state.shop,
  lastUsedSystemId: state.app.lastUsedSystemId,
  selectedSystem: state.app.selectedSystem,
});

const mapDispatchToProps = dispatch => ({
  dispatch,
  getSubscriptionsInGroup(systemId) {
    dispatch(getSubscriptionsInGroup(systemId));
  },
  getOrder(orderId) {
    dispatch(client.getOrder(orderId));
  },
  getOrderHistory() {
    dispatch(client.getOrderHistory());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(orderHistory);
