import {
  ADD_DEVICE_REQUEST,
  ADD_DEVICE_RESPONSE_SUCCESS,
  ADD_DEVICE_RESPONSE_FAILED,
  VALIDATE_TOKEN_REQUEST,
  VALIDATE_TOKEN_INIT,
  VALIDATE_TOKEN_RESPONSE_SUCCESS,
  VALIDATE_TOKEN_RESPONSE_FAILED,
  VALIDATE_TOKEN_SUCCESS_REDIRECT_TO_GROUP,
  CLEAR_ERROR_MESSAGE,
} from './actions';

const initialState = {
  loading: false,
  submitting: false,
  isTokenValid: false,
  suggestedInfo: {},
  serialNumber: null,
  connectionString: null,
  error: null,
  newSystemId: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case VALIDATE_TOKEN_INIT:
      return { ...initialState };
    case VALIDATE_TOKEN_REQUEST:
      return { ...state, loading: true, isTokenValid: false, error: null };
    case VALIDATE_TOKEN_RESPONSE_SUCCESS:
      return {
        ...state,
        loading: false,
        isTokenValid: true,
        suggestedInfo: action.suggestedInfo,
        serialNumber: action.serialNumber,
        connectionString: action.connectionString,
      };
    case VALIDATE_TOKEN_SUCCESS_REDIRECT_TO_GROUP:
      return {
        ...state,
        loading: false,
        isTokenValid: false,
        isRedirectToGroupAction: true,
        redirectGroupId: action.groupId,
      };
    case VALIDATE_TOKEN_RESPONSE_FAILED:
      return { ...state, loading: false, isTokenValid: false, error: action.error };
    case ADD_DEVICE_REQUEST:
      return { ...state, submitting: true, newSystemId: null };
    case ADD_DEVICE_RESPONSE_SUCCESS:
      return {
        ...state,
        submitting: true,
        isTokenValid: false,
        suggestedInfo: {},
        serialNumber: null,
        connectionString: null,
        newSystemId: action.newSystemId,
      };
    case ADD_DEVICE_RESPONSE_FAILED:
      return { ...state, submitting: false };
    case CLEAR_ERROR_MESSAGE:
      return { ...state, submitting: false, error: null, newSystemId: null };
    default:
      return state;
  }
};
