import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import TileIcons, { getWeatherIcon } from '../GenericTile/tile-icons';
import './daily-weather-forecast.scss';

const dailySections = [
  { heading: 'tile.weather.morning', section: 'morning' },
  { heading: 'tile.weather.afternoon', section: 'afternoon' },
  { heading: 'tile.weather.evening', section: 'evening' },
  { heading: 'tile.weather.night', section: 'night' },
];

const DailyWeatherSection = ({ sectionHeading, sectionData, brandId }) => {
  return (
    sectionData && (
      <div className="daily-weather-section">
        <span className="weather-section-heading">
          <FormattedMessage id={sectionHeading} />
        </span>
        <img className="weather-section-icon" src={getWeatherIcon(TileIcons, brandId, sectionData.icon)} />
        <span className="weather-section-temperature">{sectionData?.temperature ? sectionData.temperature : '--'}</span>
      </div>
    )
  );
};

DailyWeatherSection.propTypes = {
  sectionHeading: PropTypes.string.isRequired,
  sectionData: PropTypes.object.isRequired,
  brandId: PropTypes.string.isRequired,
};

const DailyWeatherForecast = ({ weatherData, brandId }) => {
  return (
    <>
      <div className="daily-weather__section-wrapper">
        {dailySections.map(s => {
          return (
            weatherData[s.section] && (
              <DailyWeatherSection
                key={s.heading}
                sectionHeading={s.heading}
                sectionData={weatherData[s.section]}
                unit={weatherData.unit}
                brandId={brandId}
              />
            )
          );
        })}
      </div>

      <div className="daily-weather__humidity">
        <FormattedMessage id="tiles.configuration.humidity" defaultMessage="Humidity" />
        <p>{weatherData.current?.humidity ? Math.round(weatherData.current.humidity * 100) + '%' : '--'}</p>
      </div>

      <div className="daily-weather__humidity">
        <FormattedMessage id="tiles.configuration.precipitation" defaultMessage="Precipitation" />
        <p>
          {weatherData.current?.precipProbability
            ? Math.round(weatherData.current.precipProbability * 100) + '%'
            : '--'}
        </p>
      </div>
    </>
  );
};

DailyWeatherForecast.propTypes = {
  weatherData: PropTypes.object.isRequired,
  brandId: PropTypes.string.isRequired,
};

export default DailyWeatherForecast;
