import React from 'react';
import PropTypes from 'prop-types';

const Divider = ({ color = '#000', height = '1px' }) => (
  <div
    style={{
      borderTop: `${height} solid ${color}`,
      width: '100%',
    }}
  />
);

Divider.propTypes = {
  color: PropTypes.string,
  height: PropTypes.string,
};

export default Divider;
