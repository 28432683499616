import _ from 'lodash';

import * as api from '../../api';
import * as tileApi from '../../api/tiles';
import * as staticResources from '../../api/staticResources';

import * as lastUsedLanguage from '../../api/language';
import * as lastUsedSystem from '../../api/systems';

import * as authStorage from '../../localStorage/authentication';

import { logoutUser } from '../pages/Login/actions';
import { WEB_DEV_URL, WEB_PRO_URL, WEB_URL } from '../../api/client';

export const USER_AUTH_STATUS_CHANGED = 'app/USER_AUTH_STATUS_CHANGED';

export const initUser = () => {
  tileApi.resetAllTilesLastRefreshed();
  const tokens = authStorage.getAuthTokens();
  const url = {
    WEB_URL,
    WEB_PRO_URL,
    WEB_DEV_URL,
  };
  return { type: USER_AUTH_STATUS_CHANGED, loggedIn: tokens !== null, url: url };
};

export const GET_USER_INFO_REQUEST = 'app/GET_USER_INFO_REQUEST';
export const GET_USER_INFO_RESPONSE = 'app/GET_USER_INFO_RESPONSE';
export const getUserInfo = () => async dispatch => {
  dispatch({ type: GET_USER_INFO_REQUEST });
  try {
    const response = await api.getUserInfo();
    if (response.status === 200) {
      dispatch({ type: GET_USER_INFO_RESPONSE, userInfo: response.data });
    }
  } catch (e) {
    if (e.response.status === 404) {
      dispatch(logoutUser());
    }
  }
};

export const SELECT_SYSTEM = 'app/SELECT_SYSTEM';
export const selectSystem = system => ({ type: SELECT_SYSTEM, system });

export const selectSystemId = systemId => async dispatch => {
  const response = await api.getUserSystem(systemId);

  if (response.status === 200) {
    dispatch(selectSystem(response.data));
    return response.data;
  }
};

export const GET_USER_SYSTEMS_REQUEST = 'app/GET_USER_SYSTEMS_REQUEST';
export const GET_USER_SYSTEMS_RESPONSE = 'app/GET_USER_SYSTEMS_RESPONSE';
export const getUserSystems = lastUsedSystemId => async dispatch => {
  dispatch({ type: GET_USER_SYSTEMS_REQUEST });

  const response = await api.getUserSystems();
  if (response.status === 200) {
    const systems = _.orderBy(response.data, [g => g.name.toLowerCase()], ['asc']);
    dispatch({ type: GET_USER_SYSTEMS_RESPONSE, systems: systems });

    const selectedSystemId =
      lastUsedSystemId && systems.some(system => system.id === lastUsedSystemId)
        ? lastUsedSystemId
        : systems[0] && systems[0].id;

    let selectedSystem;
    if (selectedSystemId) {
      const selectedSystemResponse = await api.getUserSystem(selectedSystemId);
      selectedSystem = selectedSystemResponse.data;
    }

    lastUsedSystem.set(selectedSystem?.id, selectedSystem?.brandId);
    dispatch(selectSystem(selectedSystem));
    return selectedSystem;
  }
};

export const DISCONNECT_SYSTEM_REQUEST = 'app/DISCONNECT_SYSTEM_REQUEST';
export const DISCONNECT_SYSTEM_RESPONSE_SUCCEEDED = 'app/DISCONNECT_SYSTEM_RESPONSE_SUCCEEDED';
export const DISCONNECT_SYSTEM_RESPONSE_FAILED = 'app/DISCONNECT_SYSTEM_RESPONSE_FAILED';
export const removeSystem = system => ({ type: DISCONNECT_SYSTEM_RESPONSE_SUCCEEDED, system });
export const disconnectSystem = (system, userId) => async dispatch => {
  dispatch({ type: DISCONNECT_SYSTEM_REQUEST, system });
  try {
    const response = await api.disconnectSystem(system.id, userId);

    if (response.status === 200) {
      dispatch({ type: DISCONNECT_SYSTEM_RESPONSE_SUCCEEDED, system });
      return true;
    }

    dispatch({ type: DISCONNECT_SYSTEM_RESPONSE_FAILED, system });
  } catch (e) {
    dispatch({ type: DISCONNECT_SYSTEM_RESPONSE_FAILED, system });
  }

  return false;
};

export const RENAME_SYSTEM_REQUEST = 'app/RENAME_SYSTEM_REQUEST';
export const renameSystem = (system, name) => ({ type: RENAME_SYSTEM_REQUEST, system, name });

export const GET_COUNTRIES_REQUEST = 'app/GET_COUNTRIES_REQUEST';
export const GET_COUNTRIES_RESPONSE = 'app/GET_COUNTRIES_RESPONSE';
export const getCountries = () => async dispatch => {
  dispatch({ type: GET_COUNTRIES_REQUEST });

  const res = await api.getCountriesList();
  const countries = res.data;
  dispatch({ type: GET_COUNTRIES_RESPONSE, countries: countries });
};

export const GET_LATEST_AGREEMENTS_VERSIONS_REQUEST = 'app/GET_LATEST_AGREEMENTS_VERSIONS_REQUEST';
export const GET_LATEST_AGREEMENTS_VERSIONS_RESPONSE = 'app/GET_LATEST_AGREEMENTS_VERSIONS_RESPONSE';
export const getLatestAgreementsVersions = () => async dispatch => {
  dispatch({ type: GET_LATEST_AGREEMENTS_VERSIONS_REQUEST });

  try {
    const res = await api.getLatestAgreementsVersionsList();
    const agreements = res.data;
    dispatch({ type: GET_LATEST_AGREEMENTS_VERSIONS_RESPONSE, agreements: agreements });
  } catch (error) {
    // If remote API is not responding, query local resources.
    // This call should not fail under any circumstances.
    // If it does, the problem is bigger than this try-catch statement.
    const agreements = await staticResources.getLocalLatestAgreementsVersionsList();
    dispatch({ type: GET_LATEST_AGREEMENTS_VERSIONS_RESPONSE, agreements: agreements });
  }
};

export const GET_CLOUD_STATUS_REQUEST = 'app/GET_CLOUD_STATUS_REQUEST';
export const GET_CLOUD_STATUS_RESPONSE = 'app/GET_CLOUD_STATUS_RESPONSE';
export const getCloudStatus = () => async dispatch => {
  dispatch({ type: GET_CLOUD_STATUS_REQUEST });

  const res = await api.getCloudStatus();
  const status = res.data;
  dispatch({ type: GET_CLOUD_STATUS_RESPONSE, status: status });
};

export const GET_LAST_USED_SYSTEM_REQUEST = 'app/GET_LAST_USED_SYSTEM_REQUEST';
export const GET_LAST_USED_SYSTEM_RESPONSE = 'app/GET_LAST_USED_SYSTEM_RESPONSE';
export const getLastUsedSystem = () => async dispatch => {
  dispatch({ type: GET_LAST_USED_SYSTEM_REQUEST });

  const res = await lastUsedSystem.get();
  const systemId = res.data.id;
  const brandId = res.data.brandId;
  dispatch({ type: GET_LAST_USED_SYSTEM_RESPONSE, lastUsedSystemId: systemId, lastUsedSystemBrand: brandId });
};

export const GET_LAST_USED_LANGUAGE_REQUEST = 'app/GET_LAST_USED_LANGUAGE_REQUEST';
export const GET_LAST_USED_LANGUAGE_RESPONSE = 'app/GET_LAST_USED_LANGUAGE_RESPONSE';
export const getLastUsedLanguage = () => async dispatch => {
  dispatch({ type: GET_LAST_USED_LANGUAGE_REQUEST });

  const language = lastUsedLanguage.get();
  dispatch({ type: GET_LAST_USED_LANGUAGE_RESPONSE, lastUsedLanguage: language });
};

export const SET_APP_LOADED_STATUS = 'app/SET_APP_LOADED_STATUS';
export const setAppLoadedStatus = status => ({ type: SET_APP_LOADED_STATUS, status });

export const SET_APP_LOADING_STATUS = 'app/SET_APP_LOADING_STATUS';
export const setAppLoadingStatus = status => ({ type: SET_APP_LOADING_STATUS, status });

export const SET_USER_DATA_STATE = 'app/SET_USER_DATA_STATE';
export const setUserDataState = state => ({ type: SET_USER_DATA_STATE, state });

export const SET_LANGUAGES = 'language/SET_LANGUAGES';
export const setSupportedLanguages = languages => ({ type: SET_LANGUAGES, languages });

export const GET_SUPPORTED_LANGUAGES_REQUEST = 'language/GET_SUPPORTED_LANGUAGES_REQUEST';
export const getSupportedLanguages = () => async dispatch => {
  dispatch({ type: GET_SUPPORTED_LANGUAGES_REQUEST });

  const response = await api.getSupportedLanguages();
  if (response.data.length > 0) {
    dispatch(setSupportedLanguages(response.data));
  }
};

export const GET_ALL_LANGUAGES_REQUEST = 'language/GET_ALL_LANGUAGES_REQUEST';
export const getAllLanguages = () => async dispatch => {
  dispatch({ type: GET_ALL_LANGUAGES_REQUEST });

  const response = await api.getAllLanguages();
  if (response.data.length > 0) {
    dispatch(setSupportedLanguages(response.data));
  }
};
export const SET_DEVICE_UPDATE_AVAILABLE = 'app/SET_DEVICE_UPDATE_AVAILABLE';
export const setDeviceUpdateAvailable = (status = false) => ({ type: SET_DEVICE_UPDATE_AVAILABLE, status });
