import React, { useState } from 'react';
import PropTypes from 'prop-types';

import AccountMigrationSection from './AccountMigrationSection';
import AccountMigrationSuccessModal from '../AccountMigrationModal/AccountMigrationSuccessModal';
import AccountMigrationErrorModal from '../AccountMigrationModal/AccountMigrationErrorModal';
import AccountMigrationModalForm from '../AccountMigrationModal/AccountMigrationModalForm';
import { useAccountMigration } from '../../../../../services/queries/useAccountMigration';
import { getOldUserTypeObject } from './utils';

const AccountMigrationModalType = {
  FORM: 'form',
  SUCCESS: 'success',
  ERROR: 'error',
};

const AccountMigration = ({ email, userSite }) => {
  const [openModal, setOpenModal] = useState(null);
  const mutation = useAccountMigration();

  const openFormModal = () => setOpenModal(AccountMigrationModalType.FORM);
  const openSuccessModal = () => setOpenModal(AccountMigrationModalType.SUCCESS);
  const openErrorModal = () => setOpenModal(AccountMigrationModalType.ERROR);

  const closeModal = () => setOpenModal(null);

  const closeSuccessModal = () => {
    closeModal();
    window.location.reload(true);
  };

  const onSubmit = ({ password }) => {
    mutation.mutate(
      { password, email },
      {
        onError: openErrorModal,
        onSuccess: openSuccessModal,
      },
    );
  };

  const isFormModalOpen = openModal === AccountMigrationModalType.FORM;
  const isSuccessModalOpen = openModal === AccountMigrationModalType.SUCCESS;
  const isErrorModalOpen = openModal === AccountMigrationModalType.ERROR;

  const translationObject = getOldUserTypeObject(userSite);

  return (
    <>
      <AccountMigrationSection openMigrationModal={openFormModal} translationObject={translationObject} />
      <AccountMigrationModalForm
        translationObject={translationObject}
        isOpen={isFormModalOpen}
        onClose={closeModal}
        onSubmit={onSubmit}
        isLoading={mutation.isLoading}
      />
      <AccountMigrationSuccessModal isOpen={isSuccessModalOpen} onClose={closeSuccessModal} />
      <AccountMigrationErrorModal isOpen={isErrorModalOpen} onClose={closeModal} />
    </>
  );
};

AccountMigration.propTypes = {
  email: PropTypes.string,
  userSite: PropTypes.string,
};

export default AccountMigration;
