import React from 'react';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import PropTypes from 'prop-types';

import 'react-notifications/lib/notifications.css';

class NotificationsPopup extends React.Component {
  state = {
    displayedNotifications: [],
    notifications: this.props.notifications || [],
  };

  static getDerivedStateFromProps(props, state) {
    const { displayedNotifications } = state;
    const newNotifications = props.notifications.filter(x => !displayedNotifications.includes(x.id));
    if (newNotifications.length > 0) {
      return {
        ...state,
        notifications: newNotifications,
        displayedNotifications: displayedNotifications.concat(newNotifications.map(n => n.id)),
      };
    }
    return {
      ...state,
      notifications: [],
    };
  }

  createNotification = (type, title, message) => {
    let messageText = message;
    if (message && message.length > 100) {
      messageText = message.substring(0, 100) + '...';
    }

    switch (type) {
      case 1:
        NotificationManager.error(messageText, title, 5000);
        break;
      case 2:
        NotificationManager.warning(messageText, title, 3000);
        break;
      case 3:
        NotificationManager.info(messageText, title);
        break;
    }
  };

  componentDidUpdate() {
    this.state.notifications.forEach(element => {
      this.createNotification(element.severity, element.header, element.description);
    });
  }

  render() {
    return <NotificationContainer />;
  }
}

NotificationsPopup.propTypes = {
  notifications: PropTypes.arrayOf(PropTypes.object).isRequired,
};

NotificationsPopup.defaultProps = {
  notifications: [],
};

export default NotificationsPopup;
