import React, { useState } from 'react';
import { Panel, PanelGroup } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { ExpandIcon } from './ExpandIcon';
import { CollapseIcon } from './CollapseIcon';

const FaqItem = ({ faqId, text, sections }) => {
  const [activeKey, setActiveKey] = useState(null);
  const [open, setOpen] = useState(false);

  const handleSelect = selectedKey => {
    setActiveKey(selectedKey);
    setOpen(!open);
  };

  return (
    <PanelGroup accordion id="faq-accordion" activeKey={activeKey} onSelect={handleSelect}>
      <Panel eventKey={faqId}>
        <Panel.Heading>
          <Panel.Title toggle>
            {text}
            <div className={`expand-icon-cont ${open && 'expanded'}`}>
              <CollapseIcon /> <ExpandIcon />
            </div>
          </Panel.Title>
        </Panel.Heading>
        <Panel.Body collapsible>
          {sections.map(s =>
            s.type === 'text' ? (
              <div key={`${s.order}`} className="command-block" dangerouslySetInnerHTML={{ __html: s.content }} />
            ) : (
              <img key={`${s.order}`} className="step-image" src={`${s.content}`} />
            ),
          )}
        </Panel.Body>
      </Panel>
    </PanelGroup>
  );
};

FaqItem.propTypes = {
  faqId: PropTypes.number,
  text: PropTypes.string,
  sections: PropTypes.arrayOf(PropTypes.object),
};

export default FaqItem;
