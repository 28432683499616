import React, { useEffect } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Link } from '../../Link';
import { Button, Input } from 'jpi-cloud-web-ui-components';
import { Formik } from 'formik';
import * as yup from 'yup';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { sendRecoveryLink } from './actions';
import { formatErrorMessage } from '../../../localization/message-formatting';
import { emailAddress } from '../../constants/constants';

const schema = yup.object().shape({
  email: yup
    .string()
    .email('email.email')
    .trim()
    .required('email.required')
    .max(emailAddress.maxLength, 'email.maxlength'),
});

const errorMessages = {
  'email.email': {
    id: 'email.error.validation.email',
    defaultMessage: 'E-Mail is not valid',
  },
  'email.required': {
    id: 'email.error.validation.required',
    defaultMessage: 'E-Mail is mandatory field',
  },
  'email.maxlength': {
    id: 'email.error.validation.maxlength',
    defaultMessage: 'Email address cannot be longer than 255 characters',
  },
  NO_SUCH_EMAIL: {
    id: 'reset-password.generic-message',
    defaultMessage:
      'If a matching account is found, you will receive an email with instructions to reset your password.',
  },
  UNAVAILABLE: {
    id: 'generic.error.request.unknown',
    defaultMessage: 'An error has occurred. Try again later.',
  },
};

const EnterEmailFormInner = ({ onSubmit, emailSendStatus, intl }) => {
  useEffect(() => {
    document.title = intl.formatMessage({
      id: 'forgot-password.page-title',
      defaultMessage: 'Forgot your password - myUplink',
    });
  });

  return (
    <Formik initialValues={{ email: '' }} validationSchema={schema} onSubmit={onSubmit}>
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        /* and other goodies */
      }) => (
        <form onSubmit={handleSubmit} className="form">
          <h1 className="form__heading">
            <FormattedMessage id="forgot-password.heading" defaultMessage="Reset password" />
          </h1>
          <FormattedMessage id="login.input-name" defaultMessage="E-mail">
            {placeholder => (
              <Input
                placeholder={placeholder}
                id="forgot-password"
                type="text"
                name="email"
                aria-required="true"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                error={errors.email && touched.email && formatErrorMessage(intl, errorMessages, errors.email)}
              />
            )}
          </FormattedMessage>
          {emailSendStatus !== 'INITIAL' && (
            <p className="text-danger">{formatErrorMessage(intl, errorMessages, emailSendStatus)}</p>
          )}
          <div className="button-wrapper--large">
            <Button className="button--secondary" type="submit" disabled={isSubmitting}>
              <FormattedMessage id="forgot-password.heading" defaultMessage="Reset password" />
            </Button>
            <div className="form__paragraph">
              <FormattedMessage id="register.or" defaultMessage="or" />
            </div>
            <Link className="button button--primary" to="/login">
              <FormattedMessage id="login.login" defaultMessage="Login" />
            </Link>
          </div>
        </form>
      )}
    </Formik>
  );
};

EnterEmailFormInner.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  requestError: PropTypes.string,
  emailSendStatus: PropTypes.string,
  intl: PropTypes.object,
};

const EnterEmailForm = injectIntl(EnterEmailFormInner);

const EmailSentConfirmation = () => (
  <div className="form">
    <h3 className="form__heading">
      <FormattedMessage id="forgot-password.heading" defaultMessage="Reset password" />
    </h3>
    <div className="form__text">
      <FormattedMessage
        id="reset-password.generic-message"
        defaultMessage="If a matching account is found, you will receive an email with instructions to reset your password."
      />
    </div>
    <div className="button-wrapper--large">
      <Link className="button button--primary" to="/login">
        <FormattedMessage id="login.login" defaultMessage="Login" />
      </Link>
    </div>
  </div>
);

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      emailSent: false,
    };
  }

  static propTypes = {
    sendRecoveryLink: PropTypes.func.isRequired,
    error: PropTypes.string,
    emailSendStatus: PropTypes.string,
  };

  onSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);
    const response = await this.props.sendRecoveryLink(values.email);
    if (response) {
      this.setState({ emailSent: true });
    }
    setSubmitting(false);
  };

  render() {
    return (
      <>
        {this.state.emailSent ? (
          <EmailSentConfirmation />
        ) : (
          <EnterEmailForm
            onSubmit={this.onSubmit}
            requestError={this.props.error}
            emailSendStatus={this.props.emailSendStatus}
          />
        )}
      </>
    );
  }
}

export default connect(
  ({ forgotPassword }) => ({
    ...forgotPassword,
  }),
  {
    sendRecoveryLink,
  },
)(ForgotPassword);
