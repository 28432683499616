import { GET_TILE_DATA_RESPONSE } from './actions';
import { TILE_CONFIGURATION_END } from '../TileConfigurationPopup/actions';

const initialState = {};

const parametersToDictionary = parameters => {
  const res = {};
  if (parameters) parameters.forEach(p => (res[p.parameterId] = p));
  return res;
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TILE_CONFIGURATION_END:
      if (action.tileId) return { ...state, [action.tileId]: null };
      return state;
    case GET_TILE_DATA_RESPONSE:
      return { ...state, [action.tileId]: parametersToDictionary(action.values) };
    default:
      return state;
  }
};
