import * as api from '../../../api';

export const CONFIRM_EMAIL_REQUEST = 'confirmation/CONFIRM_EMAIL_REQUEST';
export const CONFIRM_EMAIL_RESPONSE_SUCCEEDED = 'confirmation/CONFIRM_EMAIL_RESPONSE_SUCCEEDED';
export const CONFIRM_EMAIL_RESPONSE_FAILED = 'confirmation/CONFIRM_EMAIL_RESPONSE_FAILED';
export const confirmEmail = token => async dispatch => {
  dispatch({ type: CONFIRM_EMAIL_REQUEST });
  try {
    await api.confirmEmail(token);
    dispatch({ type: CONFIRM_EMAIL_RESPONSE_SUCCEEDED });
    return true;
  } catch (e) {
    dispatch({ type: CONFIRM_EMAIL_RESPONSE_FAILED, error: e.response.data.description });
    return false;
  }
};
