import * as api from '../../../../../api';
import { USER_SECURITY_LEVELS } from '../SecurityTab/costants';

export const GET_YOUR_TECHNICIAN_REQUEST = 'service-partner/GET_YOUR_TECHNICIAN_REQUEST ';
export const GET_YOUR_TECHNICIAN_SUCCEEDED = 'service-partner/GET_YOUR_TECHNICIAN_SUCCEEDED';
export const GET_YOUR_TECHNICIAN_FAILED = 'service-partner/GET_YOUR_TECHNICIAN_FAILED';

export const getYourTechnician = () => async dispatch => {
  dispatch({ type: GET_YOUR_TECHNICIAN_REQUEST });
  const response = await api.getYourTechnicianInfo();

  if (response.status === 200) {
    dispatch({ type: GET_YOUR_TECHNICIAN_SUCCEEDED, yourTechnician: response.data });
  }
};

export const getYourTechnicianInfo = groupId => async dispatch => {
  dispatch({ type: GET_YOUR_TECHNICIAN_REQUEST });
  const users = await api.getSystemUsers(groupId);

  if (users.status === 200 && users.data.length > 0) {
    const yourTechnician = users.data.find(u => u.role === USER_SECURITY_LEVELS.servicePartner);
    if (!yourTechnician) return dispatch({ type: GET_YOUR_TECHNICIAN_FAILED });

    const response = await api.getYourTechnician(yourTechnician.id);
    if (response.status !== 200) return dispatch({ type: GET_YOUR_TECHNICIAN_FAILED });

    dispatch({ type: GET_YOUR_TECHNICIAN_SUCCEEDED, yourTechnician: response.data });
  }
};
